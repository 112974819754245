import { default as moment } from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Divider, Grid, Header, Icon, Image, Popup, Segment, Table } from 'semantic-ui-react';
import { addArrayItemOrRemoveIfExists, getMoment, getPrettyDate, useIsMobileDevice, useIsMobileDevicePortrait } from '../../../../base/utils'; // FIXME: getPrettyDate should use api
import { ReportAttachments } from '../../../attachments/containers';
import { TextItem } from '../../../globals/components';
import { } from '../../../header/components/Header';
import { getSupplierNameAndNumber } from '../../../suppliers/util';
import { Inspection, ReportState } from '../../types';
import LocationMap from '../LocationMap';
import { InspectionTypeMarker } from '../inspectionTypes/InspectionTypes';
import SplitShipmentsMarker from '../shared/SplitShipmentsMarker';
import AQLInfo from './AQLInfo';
import SampleQty from './SampleQty';
import SampleQtyInfo from './SampleQtyInfo';
import TotalQty from './TotalQty';

type InfoProps = {
    report: ReportState;
    inspectionId: string;
    downloadButton: React.ReactElement;
    showImage?: boolean;
};

const Info = (props: InfoProps): React.ReactElement => {

    const { showImage = false, report, downloadButton } = props;
    const intl = useIntl();
    const [openSubInspections, setOpenSubInspections] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const isMobile = useIsMobileDevice();
    const isMobilePortrait = useIsMobileDevicePortrait();
    const formatMessage = intl.formatMessage;
    const lang = intl.locale;
    const inspection: Inspection = report.inspection;
    const isCombinedInspection = inspection.is_master;
    let infoImage = null;
    let infoImageLarge = null;
    if (inspection.item_image) {
        infoImage = inspection.item_image + '?h=50';
        infoImageLarge = inspection.item_image + '?h=700';
    }
    const getDuration = (start: string, end: string): string => {
        if (start === null || end === null) {
            return 'N/A';
        }
        const s = getMoment(start);
        const e = getMoment(end);
        const dur = moment.duration(e.diff(s));
        const years = dur.get('years');
        const months = dur.get('months');
        const days = dur.get('days');
        const hours = dur.get('hours');
        const minutes = dur.get('minutes');
        let ret = '';
        if (years > 0) {
            ret += years + 'y ';
        }
        if (months > 0) {
            ret += months + 'mos ';
        }
        if (days > 0) {
            ret += days + 'd ';
        }
        if (hours > 0) {
            ret += hours + 'h ';
        }
        if (minutes > 0) {
            ret += minutes + 'm ';
        }
        if (dur.asMinutes() < 1) {
            ret = intl.formatMessage({ id: 'inspections.report.info.duration.less_then_a_minute' })
        }
        return ret;
    };

    const showMoreAction = (): void => {
        setShowMore(true);
    }
    const showLess = (): void => {
        setShowMore(false);
    };

    const getInspector = (inspection: Inspection): string | React.ReactElement => {
        if (inspection.assigned_user) {
            return <Fragment>{inspection.assigned_user.firstname} {inspection.assigned_user.lastname}</Fragment>;
        }
        return '-';
    }

    const getSupplierName = (name: string, number: string) => {
        return getSupplierNameAndNumber(name, number);
    }

    const getCustomFields = (inspection: Inspection, isSubInspection = false): React.ReactElement[] => {
        if (inspection.custom_field_values) {
            const cfv = inspection.custom_field_values;
            const ret = [];
            const w = isSubInspection ? 5 : 8;
            const offset = isSubInspection ? 3 : 2;
            let i = 0;
            while (i < cfv.length) {
                if (cfv[i]) {
                    ret.push(<Grid.Row divided={!isSubInspection} className={isSubInspection ? 'sub-item' : 'report-details'}>
                        <Grid.Column width={w}>
                            <TextItem
                                column={false}
                                label={cfv[i].name}
                                text={cfv[i].value || '-'}
                                showAsUrl={true}
                            />
                        </Grid.Column>
                        <Grid.Column width={w}>
                            {cfv[i + 1] && <TextItem
                                column={false}
                                label={cfv[i + 1].name}
                                text={cfv[i + 1].value || '-'}
                                showAsUrl={true}
                            />}
                        </Grid.Column>
                        {isSubInspection && <Grid.Column width={w}>
                            {cfv[i + 2] && <TextItem
                                column={false}
                                label={cfv[i + 2].name}
                                text={cfv[i + 2].value || '-'}
                                showAsUrl={true}
                            />}
                        </Grid.Column>}
                    </Grid.Row>);
                    i += offset;
                }
            }
            return ret;
        }
        return (null);
    };

    const toggleSubInspection = (inspectionId: string): void => {
        let openItems = [...openSubInspections];
        openItems = addArrayItemOrRemoveIfExists(openItems, inspectionId);
        setOpenSubInspections(openItems);
    };

    const getChecklistNameAndRevisionText = (inspection: Inspection): React.ReactElement => {
        let content;
        if (inspection.checklist_revision) {
            const text = inspection.checklist_name;
            content = <Popup position='top center' flowing trigger={<span>{text}</span>}>
                <Popup.Header>{inspection.checklist_name}</Popup.Header>
                <Popup.Content>
                    <TextItem label={intl.formatMessage({ id: 'inspections.report.info.checklist_revision_label' })} text={inspection.checklist_revision} />
                    <TextItem label={intl.formatMessage({ id: 'inspections.report.info.checklist_revision_time_label' })} text={getPrettyDate(inspection.checklist_revision_timestamp)} />
                </Popup.Content>
            </Popup>
        } else {
            content = inspection.checklist_name || '-';
        }
        return <TextItem className='cursor-default' column={isMobilePortrait} label={formatMessage({ id: 'inspections.checklist' })} text={content} />;
    }
    useEffect(() => {
        return (): void => {
            setShowMore(false);
        }
    }, []);
    return (
        <Segment.Group>
            {!isMobile && <Segment secondary>
                <Grid>
                    <Grid.Column width={15} textAlign='left' verticalAlign='middle'>
                        <div className='infoTop'>
                            {showImage && inspection.item_image &&
                                <div className='infoImage'>
                                    <Popup
                                        trigger={<Image floated='left' rounded src={infoImage} />}
                                        position='bottom center'
                                        className='infoImageLarge'
                                    >
                                        <Popup.Content>
                                            <Image rounded src={infoImageLarge} alt='Loading...' />
                                        </Popup.Content>
                                    </Popup></div>}
                            <div className={['infoHeader', inspection.item_image ? 'hasImage' : null].join(' ')}><Header as='h3'><FormattedMessage id='inspections.order_details' /></Header></div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={1} textAlign='right'>
                        {downloadButton}
                    </Grid.Column>
                </Grid>
            </Segment>}

            <Segment>
                <Grid className='report-info'>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Grid columns={16}>
                                {isCombinedInspection &&
                                    <Fragment>
                                        <Grid.Row divided className='report-details'>
                                            <Grid.Column width={8}>
                                                <TotalQty inspection={inspection} />
                                                <TextItem column={isMobilePortrait}
                                                    label={inspection.inspected_quantity !== null && inspection.inspected_quantity > 0 ? formatMessage({ id: 'inspections.sample_qty_inspected' }) : formatMessage({ id: 'inspections.sample_qty_no_inspected' })}
                                                    text={<Fragment>
                                                        <SampleQty inspection={inspection} />
                                                        <SampleQtyInfo inspection={inspection} hover={true} />
                                                        <AQLInfo inspection={inspection} hover={true} />
                                                    </Fragment>} />
                                                {getChecklistNameAndRevisionText(inspection)}
                                                <TextItem column={isMobilePortrait} label={formatMessage({ id: 'inspections.location' })} text={<Fragment>{inspection.geographic_location}{inspection.last_tracked_location && <LocationMap location={inspection.last_tracked_location} locationCountry={inspection.location_country} />}</Fragment>} />
                                                <TextItem column={isMobilePortrait} label={formatMessage({ id: 'inspections.production_unit' })} text={inspection.production_unit && inspection.production_unit.name || '-'} />
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                {!isMobile && <TextItem column={isMobilePortrait} label={formatMessage({ id: 'inspections.inspector' })} text={getInspector(inspection)} />}
                                                <TextItem column={isMobilePortrait} label={formatMessage({ id: 'inspections.inspection_date' })} text={getPrettyDate(inspection.inspection_end, '') || '-'} />
                                                <TextItem column={isMobilePortrait} label={formatMessage({ id: 'inspections.duration' })} text={getDuration(inspection.inspection_start, inspection.inspection_end)} />
                                                <TextItem column={isMobilePortrait} label={formatMessage({ id: 'inspections.supplier' })} text={getSupplierName(inspection.supplier_entity && inspection.supplier_entity.name, inspection.supplier_entity && inspection.supplier_entity.number)}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row className='combinedInspections'>
                                            {inspection.sub_inspections.map((subInspection: Inspection, i: number) => {
                                                const isOpen = openSubInspections.includes(subInspection.inspection_id);
                                                const puAddress = subInspection.production_unit && subInspection.production_unit.address.join('\n') || '-';
                                                return (
                                                    <Fragment key={i}>
                                                        <Table basic='very' className='infoCI'>
                                                            {i === 0 && !isMobile && <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell className='orderNumber w-2/12'>
                                                                        <FormattedMessage id='report.info.ci_list.order_number' />
                                                                    </Table.HeaderCell>
                                                                    <Table.HeaderCell className='itemNumber w-2/12'>
                                                                        <FormattedMessage id='report.info.ci_list.item_number' />
                                                                    </Table.HeaderCell>
                                                                    <Table.HeaderCell className='itemName w-5/12'>
                                                                        <FormattedMessage id='report.info.ci_list.item_name' />
                                                                    </Table.HeaderCell>
                                                                    <Table.HeaderCell className='itemQty  w-2/12'>
                                                                        <FormattedMessage id='report.info.ci_list.total_qty' />
                                                                    </Table.HeaderCell>
                                                                    <Table.HeaderCell className='itemEnd  w-1/12'>

                                                                    </Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>}
                                                            <Table.Row onClick={(): void => toggleSubInspection(subInspection.inspection_id)} unstackable>
                                                                <Table.Cell className='orderNumber w-2/12' data-test-id={'info-order-number'}>
                                                                    <div className='content space-x-1'>
                                                                        <InspectionTypeMarker className='px-2 py-1' inspections={[subInspection]} type={'report'} />
                                                                        <SplitShipmentsMarker inspection={subInspection} />
                                                                        <div>{subInspection.order_number || ''}</div>
                                                                        {isMobile && <div className='cnt'>
                                                                            {subInspection.total_quantity || '-'}
                                                                        </div>}
                                                                    </div>
                                                                </Table.Cell>
                                                                <Table.Cell className='itemNumber w-2/12' data-test-id={'info-item-number'}>{subInspection.item_number || ''}</Table.Cell>
                                                                <Table.Cell className='itemNumber w-5/12' data-test-id={'info-item-name'}>{subInspection.item_name || ''}</Table.Cell>
                                                                <Table.Cell className='itemQty w-2/12' data-test-id={'info-item-qty'}>
                                                                    {!isMobile && <TotalQty inspection={subInspection} isSubinspection={true} />}
                                                                </Table.Cell>
                                                                <Table.Cell className='w-1/12'>
                                                                    {isOpen ? <Icon color='grey' name='chevron up' /> : <Icon color='grey' name='chevron down' />}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </Table>
                                                        {isOpen &&
                                                            <Grid className='sub-items' stackable>
                                                                <Grid.Row className='sub-item'>
                                                                    <Grid.Column width={5}>

                                                                        <TextItem column={false} label={formatMessage({ id: 'inspections.address' })} text={puAddress} />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={5}>
                                                                        <TextItem column={false} label={formatMessage({ id: 'inspections.supplier' })} text={getSupplierName(subInspection.supplier_entity && subInspection.supplier_entity.name, subInspection.supplier_entity && subInspection.supplier_entity.number)}/>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row className='sub-item'>
                                                                    <Grid.Column width={5}>
                                                                        <TextItem column={isMobile} label={formatMessage({ id: 'inspections.sampling_method' })} text={
                                                                            <Fragment>
                                                                                {inspection.bypass_sampling_protocol ? '-' : (inspection.sampling_protocol && inspection.sampling_protocol.name || '-')}
                                                                                <AQLInfo inspection={inspection} inline={true} />
                                                                            </Fragment>} />
                                                                    </Grid.Column>
                                                                    <Grid.Column width={5}>
                                                                        <TextItem column={false} label={formatMessage({ id: 'inspections.etd' })} text={subInspection.etd && subInspection.etd.toString() || '-'} />
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                {getCustomFields(subInspection, true)}
                                                                <ReportAttachments
                                                                    attachToType='inspections'
                                                                    attachToItemId={subInspection.inspection_id}
                                                                />
                                                                <Divider className='d' />
                                                            </Grid>
                                                        }
                                                    </Fragment>);
                                            })}
                                        </Grid.Row>
                                    </Fragment>
                                }
                                {!isCombinedInspection &&
                                    <Grid.Row divided className='report-details'>
                                        <Grid.Column width={8}>
                                            <TextItem dataTestId='info-order-number' column={isMobile} label={formatMessage({ id: 'inspections.order_no' })} text={report.order.order_number} />
                                            <TextItem column={isMobile} label={formatMessage({ id: 'inspections.item_no' })} text={inspection.item_number || ''} />
                                            <TextItem column={isMobile} label={formatMessage({ id: 'inspections.item_name' })} text={inspection.item_name || ''} />
                                            {!isMobile && <TextItem column={isMobile} label={formatMessage({ id: 'inspections.supplier' })} text={getSupplierName(inspection.supplier_entity && inspection.supplier_entity.name, inspection.supplier_entity && inspection.supplier_entity.number)}/>}
                                            {!isMobile && <TextItem column={isMobile} label={formatMessage({ id: 'inspections.location' })} text={<Fragment>{inspection.geographic_location}{inspection.last_tracked_location &&
                                                <LocationMap location={inspection.last_tracked_location} locationCountry={inspection.location_country} />}</Fragment>} />}
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <TotalQty inspection={inspection} />
                                            <TextItem column={isMobile}
                                                label={inspection.inspected_quantity !== null && inspection.inspected_quantity > 0 ? formatMessage({ id: 'inspections.sample_qty_inspected' }) : formatMessage({ id: 'inspections.sample_qty_no_inspected' })}
                                                text={<Fragment>
                                                    <SampleQty inspection={inspection} />
                                                    <SampleQtyInfo inspection={inspection} hover={true} />
                                                    <AQLInfo inspection={inspection} hover={true} />
                                                </Fragment>} />
                                            {!isMobile && <TextItem column={isMobile} label={formatMessage({ id: 'inspections.inspector' })} text={getInspector(inspection)} />}
                                            <TextItem column={isMobile} label={formatMessage({ id: 'inspections.inspection_date' })} text={getPrettyDate(inspection.inspection_end, '') || '-'} />
                                            {!isMobile && <TextItem column={isMobile} label={formatMessage({ id: 'inspections.duration' })} text={getDuration(inspection.inspection_start, inspection.inspection_end)} />}
                                        </Grid.Column>
                                    </Grid.Row>}
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                    {showMore &&
                        <Fragment>
                            <Grid.Row divided className='report-details'>
                                <Grid.Column width={8}>
                                    {isMobile && <TextItem column={isMobile} label={formatMessage({ id: 'inspections.supplier' })} text={getSupplierName(inspection.supplier_entity && inspection.supplier_entity.name, inspection.supplier_entity && inspection.supplier_entity.number)}/>}
                                    {isMobile && <TextItem column={isMobile} label={formatMessage({ id: 'inspections.duration' })} text={getDuration(inspection.inspection_start, inspection.inspection_end)} />}
                                    {inspection.production_unit &&
                                        <TextItem column={isMobile} label={formatMessage({ id: 'inspections.production_unit' })} text={
                                            <Fragment>
                                                {inspection.production_unit && inspection.production_unit.name || '-'}
                                                {inspection.production_unit.address && inspection.production_unit.address.map((address: string) => {
                                                    return <div key={address}>{address}</div>;
                                                })}
                                            </Fragment>} />}
                                    <TextItem column={isMobile} label={formatMessage({ id: 'inspections.sampling_method' })} text={
                                        <Fragment>
                                            {inspection.bypass_sampling_protocol ? '-' : (inspection.sampling_protocol && inspection.sampling_protocol.name || '-')}
                                            <AQLInfo inspection={inspection} inline={true} />
                                        </Fragment>} />
                                    {getChecklistNameAndRevisionText(inspection)}
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    {isMobile && <TextItem column={isMobile} label={formatMessage({ id: 'inspections.location' })} text={<Fragment>{inspection.geographic_location}{inspection.last_tracked_location &&
                                        <LocationMap location={inspection.last_tracked_location} locationCountry={inspection.location_country} />}</Fragment>} />}

                                    <TextItem column={isMobile} label={formatMessage({ id: 'inspections.etd' })} text={getPrettyDate(inspection.etd, lang) || '-'} />
                                    <TextItem column={isMobile} label={formatMessage({ id: 'inspections.planned_date' })} text={getPrettyDate(inspection.scheduled_inspection_date, lang)} />
                                </Grid.Column>
                            </Grid.Row>
                            {getCustomFields(inspection)}
                        </Fragment>
                    }
                    {showMore &&
                        <Fragment>
                            <ReportAttachments
                                attachToType='inspections'
                                attachToItemId={inspection.inspection_id}
                            />
                        </Fragment>}
                    {!isCombinedInspection && (<Grid.Row className='actions'>
                        <Grid.Column width={16}>
                            <Divider fitted />
                            {!showMore && <Button className='green-icon' data-test-id='btn-info-show-more' onClick={showMoreAction}><FormattedMessage id='inspections.show_more' /><Icon name='chevron down' /></Button>}
                            {showMore && <Button className='green-icon' data-test-id='btn-info-show-more' onClick={showLess}><FormattedMessage id='inspections.hide' /><Icon name='chevron up' /></Button>}
                        </Grid.Column>
                    </Grid.Row>)}
                    {isCombinedInspection && <Grid.Row className='actions'></Grid.Row>}
                </Grid>
            </Segment>
        </Segment.Group>
    );
}

export default Info;
