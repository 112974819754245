import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, Icon, Message, Modal, Popup, Segment } from 'semantic-ui-react';
import { AdminCtxUser, GetProfileResponse, ListUsersUser } from '../../../backend_api/models';
import { DEFAULT_PASSWORD_LENGTH } from '../../../base/config';
import { AppState, RequestError } from '../../../base/types';
import { getLocalTranslationsIntl } from '../../../containers/ConnectedIntlProvider';
import { Locale } from '../../globals';
import { getCurrentLocale } from '../../globals/selectors';
import { sortGroupsSelector } from '../../groups/selectors';
import { GroupData } from '../../groups/types';
import HasFeature, { checkHasFeature } from '../../profile/containers/HasFeature';
import { createUser } from '../actions/actions';
import { getAdminUsersIsFetching, getAllAdminUsersSelector } from '../selectors';
import InputGroup from '../UI/InputGroup';


type OwnProps = {
    roles: Array<{ key: string; value: string; text: string }>;
    showCreateUser: boolean;
    locales: Locale[];
    hideGroups?: boolean;
    groups: GroupData[];
    cancel(): void;
    done?(): void;
    users: ListUsersUser[];
};

type StateProps = {
    error: RequestError;
    profile: GetProfileResponse;
    currentLocale: Locale;
    users: AdminCtxUser[];
    isFetching: boolean;
};

type DispatchProps = {
    actions: {
        createUser: typeof createUser;
    };
};

type CreateUsersProps = OwnProps & StateProps & DispatchProps & WrappedComponentProps;
type State = {
    layout: any;
    createMethods: any;
    disabledFields: any;
    fields: any;
    formIsValid: boolean;
    userAlreadyExists: { exists: boolean, isDisabled: boolean };
}

class CreateUserModalInner extends Component<CreateUsersProps, State> {
    public createMethods = [];

    public optionsTypes = {
        createMethods: [],
        roles: [],
        locales: [],
        groups: [],
    };

    public constructor(props) {
        super(props);
        const formatMessage = props.intl.formatMessage;
        this.state = {
            createMethods: [
                { key: 'email', value: 'email', text: formatMessage({ id: 'users.create_user.send_welcome_email' }), disableField: 'password' },
                { key: 'password', value: 'password', text: formatMessage({ id: 'users.create_user.set_a_temp_password' }, { minLen: DEFAULT_PASSWORD_LENGTH }), disableField: 'message' },
            ],
            userAlreadyExists: { exists: false, isDisabled: false },
            disabledFields: ['password'],
            fields: {
                firstname: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: formatMessage({ id: 'users.create_user.firstname' }),
                        width: 8,
                    },
                    value: '',
                    validation: {
                        required: true,
                    },
                    valid: false,
                    touched: false,
                },
                lastname: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        placeholder: formatMessage({ id: 'users.create_user.lastname' }),
                        width: 8,
                    },
                    value: '',
                    validation: {
                        required: true,
                    },
                    valid: false,
                    touched: false,
                },
                email: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'email',
                        placeholder: formatMessage({ id: 'users.create_user.email' }),
                        width: 8,
                    },
                    value: '',
                    validation: {
                        required: true,
                        isEmail: true,
                    },
                    valid: false,
                    touched: false,
                },
                createmethod: {
                    elementType: 'radio',
                    elementConfig: {
                        width: 8,
                    },
                    options: 'createMethods',
                    value: 'email',
                    validation: {},
                    valid: true,
                },

                locale: {
                    elementType: 'select',
                    elementConfig: {
                        width: 8,
                        placeholder: formatMessage({ id: 'users.create_user.language' }),
                    },
                    options: 'locales',
                    value: '',
                    validation: {
                        required: true,
                    },
                    valid: true,
                },

                message: {
                    elementType: 'textarea',
                    elementConfig: {
                        width: 16,
                        placeholder: formatMessage({ id: 'users.create_user.welcome_text_here' }),
                        rows: 6,
                    },
                    value: '',
                    validation: {
                        required: true,
                    },
                    valid: false,
                    touched: false,
                },
                password: {
                    elementType: 'input',
                    elementConfig: {
                        placeholder: formatMessage({ id: 'users.create_user.type_password' }),
                        type: 'password',
                        width: 16,
                    },
                    value: '',
                    validation: {
                        required: true,
                        minLength: DEFAULT_PASSWORD_LENGTH,
                    },
                    valid: false,
                    touched: false,
                },
                role: {
                    elementType: 'select',
                    elementConfig: {
                        width: 8,
                        className: '',
                        placeholder: formatMessage({ id: 'users.create_user.choose_role' }),
                    },
                    options: 'roles',
                    value: props.roles && props.roles[0] && props.roles[0].value,
                    validation: ((): string => !props.hideGroups ? 'minLength : 1' : 'required: false')(),
                    valid: props.roles && props.roles[0] && true,
                },
                groups: {
                    elementType: 'select',
                    elementConfig: {
                        placeholder: formatMessage({ id: 'users.create_user.choose_groups' }),
                        width: 8,
                        multiple: true,
                        search: true,
                        className: ((): string => props.hideGroups ? 'hideGroups' : '')(),
                    },
                    options: 'groups',
                    feature: 'groups',
                    value: ((): string[] => {
                        if (props.hideGroups) {
                            const g = props.groups[0];
                            return [g.id];
                        }
                        return [];
                    })(),
                    validation: ((): string => !props.hideGroups ? 'minLength : 1' : 'required: false')(),
                    valid: props.hideGroups,
                },
            },
            layout: [
                ['firstname', 'lastname'],
                ['email', 'role'],
                ['createmethod', 'locale'],
                ['message', 'password'],
                ['groups'],
            ],
            formIsValid: false,
        };
    }

    public getLocales = () => {
        if (!this.props.locales) {
            return [];
        }
        if (!this.props.locales.length) {
            return [];
        }
        return this.props.locales.map((element) => {
            const locale = element.language + '-' + element.country;
            const countryLabel = this.props.intl.formatMessage({ id: 'globals.locale.' + locale });
            return { key: element.language, value: element.id, text: countryLabel };
        });
    }

    public getGroups = () => {
        if (!this.props.groups) {
            return [];
        }
        if (!this.props.groups.length) {
            return [];
        }
        const g = this.props.groups.map((group) => {
            const content = <div className='flex justify-between'><span>{group.name}</span>{group.is_supplier_group && <Popup position={'top right'} trigger={<Icon className='text-default-widget-color' name='factory' />}>
                <FormattedMessage id='users.user_is_supplier_group' />
            </Popup>}</div>;
            return { text: group.name, key: group.id, value: group.id, is_supplier_group: group.is_supplier_group, content };

        });
        return sortGroupsSelector(g);
    }
    public getRoles = () => {
        if (!this.props.roles) {
            return [];
        }
        if (!this.props.roles.length) {
            return [];
        }
        return this.props.roles;
    }

    public checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.isEmail) {
            const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;
            isValid = pattern.test(value) && isValid;
        }
        if (rules.minLength) {
            isValid = value.length >= rules.minLength;
        }

        if (rules.notValue) {
            isValid = rules.notValue !== value && isValid;
        }

        return isValid;
    }

    public createUserSubmitHandler = (event) => {
        event.preventDefault();
        const formData = {};
        for (const formElementIdentifier in this.state.fields) {
            if (this.state.disabledFields.indexOf(formElementIdentifier) == -1) {
                formData[formElementIdentifier] = this.state.fields[formElementIdentifier].value;
            }
        }
        if (formData['message'] && formData['message'].length != 0) {
            formData['send_invitation'] = true;
        }
        formData['active'] = true;
        if (formData['locale']) {
            formData['locale'] = { id: formData['locale'] };
        }
        if (formData['role'] && formData['role'].length) {
            formData['user_role_id'] = formData['role'];
            delete formData['role'];
        }
        if (formData['groups'] && formData['groups'].length) {
            const groups = formData['groups'].map((group) => ({ id: group }));
            formData['groups'] = groups;
        }
        this.props.actions.createUser(formData);
    }

    public inputChangedHandler = (event, inputIdentifier, value) => {
        const updatedOrderForm = {
            ...this.state.fields,
        };
        const updatedFormElement = {
            ...updatedOrderForm[inputIdentifier],
        };

        updatedFormElement.value = this.convertValue(value, updatedFormElement.validation);
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedOrderForm[inputIdentifier] = updatedFormElement;

        const disabledFields = [];
        let selectedElement = {};
        let options = [];
        for (const inputIdentifier in updatedOrderForm) {
            if (updatedOrderForm[inputIdentifier].feature && !checkHasFeature(this.props.profile.features, updatedOrderForm[inputIdentifier].feature)) {
                disabledFields.push(inputIdentifier);
            }
            if (updatedOrderForm[inputIdentifier].options) {
                options = this.getOptions(updatedOrderForm[inputIdentifier].options);
                selectedElement = options.find((option) => {
                    return (option.value === updatedOrderForm[inputIdentifier].value);
                });
                if (selectedElement && selectedElement['disableField']) {
                    disabledFields.push(selectedElement['disableField'])
                }
            }
        }
        this.afterChangeValue(inputIdentifier, value, updatedOrderForm);

        let formIsValid = true;
        for (const inputIdentifier in updatedOrderForm) {
            if (disabledFields.indexOf(inputIdentifier) == -1) {
                formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
            }
        }
        const emailField = updatedOrderForm['email']
        const userAlreadyExists = { exists: false, isDisabled: false };
        if (emailField.valid) {
            const usersWithEmail = this.props.users && this.props.users.filter((user) => user.email == emailField.value)
            if (usersWithEmail && usersWithEmail.length > 0) {
                formIsValid = false;
                userAlreadyExists.exists = true;
                userAlreadyExists.isDisabled = usersWithEmail[0] && usersWithEmail[0].disabled;
                emailField.valid = false;
            }
        }

        this.setState({
            disabledFields,
            fields: updatedOrderForm,
            formIsValid,
            userAlreadyExists
        });
    }

    private convertValue(value, rules) {
        if (rules.isEmail) {
            return (value as string).toLowerCase();
        }
        else {
            return value;
        }
    }

    public afterChangeValue(identifier, value, newState): void {
        if (identifier === 'locale' || identifier === 'firstname') {
            this.updateMessage(identifier, newState);
        }
    }

    public getOptions = (optionName: string): [] => {
        return this.optionsTypes[optionName];
    }
    public componentDidMount(): void {
        this.optionsTypes.createMethods = this.state.createMethods;
        this.optionsTypes.roles = this.getRoles();
        this.optionsTypes.locales = this.getLocales();
        this.optionsTypes.groups = this.getGroups();
        this.inputChangedHandler(null, 'locale', this.props.profile.default_locale.id);
    }

    public render() {
        let content;
        let formElement;
        let warning;
        if (this.props.error &&
            this.props.error.errorDetail &&
            this.props.error.errorDetail.detail &&
            this.props.error.errorDetail.detail.length) { //This checks that the type is Array<string>
            const errors = (this.props.error.errorDetail.detail as Array<string>).map((errorMessage, index) => {
                if (errorMessage.includes('email')) {
                    return (<p key={index}><FormattedMessage id="users.user_was_not_created.email_error" /></p>);
                }
                else {
                    return (<p key={index}><FormattedMessage id="users.user_was_not_created.unknown_error" /></p>);
                }
            });
            warning = (
                <Segment>
                    <Message negative>
                        <Message.Header><FormattedMessage id='users.user_was_not_created' /></Message.Header>
                        {errors}
                    </Message>
                </Segment>
            );
        }

        const userAlreadyExistsMessage = (
            <Message negative>
                <Message.Header>{this.state.userAlreadyExists.isDisabled ? <FormattedMessage id='users.user_already_exists_and_is_disabled' /> : <FormattedMessage id='users.user_already_exists' />}</Message.Header>
            </Message>
        );

        return (
            <Modal
                open={this.props.showCreateUser}
                onClose={this.props.cancel}
                closeIcon={true}
                size='small'
                className='createUserModal'
                closeOnDimmerClick={false}
            >
                <Modal.Header><FormattedMessage id='users.add_user' />{': ' + this.props.profile.organization.name}</Modal.Header>
                <Modal.Content>
                    {warning}
                    {this.state.userAlreadyExists.exists && userAlreadyExistsMessage}
                    <Form onSubmit={this.createUserSubmitHandler}>
                        {
                            this.state.layout.map((layoutObjects, index) => {
                                content = layoutObjects.map((id) => {
                                    if (this.state.disabledFields.indexOf(id) === -1) {
                                        formElement = this.state.fields[id];
                                        if (formElement) {
                                            const elementConfig = { ...formElement.elementConfig }

                                            elementConfig.name = id + '-create-user';
                                            return (
                                                <HasFeature feature={formElement.feature}>
                                                    <InputGroup
                                                        keyId={id + 'sub'}
                                                        elementType={formElement.elementType}
                                                        elementConfig={elementConfig}
                                                        value={formElement.value}
                                                        invalid={!formElement.valid}
                                                        options={this.getOptions(formElement.options)}
                                                        shouldValidate={formElement.validation}
                                                        touched={formElement.touched}
                                                        changed={(event, { value }) => this.inputChangedHandler(event, id, value)} />
                                                </HasFeature>
                                            );
                                        }
                                    }
                                });
                                return (
                                    <Form.Group key={'FG' + index}>{content}</Form.Group>
                                );
                            })
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary disabled={!this.state.formIsValid || this.props.isFetching} onClick={(evt) => {
                        this.createUserSubmitHandler(evt);
                        if (this.props.done) {
                            this.props.done();
                        }
                    }} data-test-id='add-user-submit'><FormattedMessage id='users.add_user' /></Button>
                    <Button onClick={this.props.cancel}><FormattedMessage id='users.cancel' /></Button>
                </Modal.Actions>
            </Modal>
        );
    }

    private updateMessage(identifier, newState) {
        const messageIdentifier = 'message';
        const firstnameIdentifier = 'firstname';
        if (!newState[messageIdentifier].touched) {
            const locales = this.getLocales();
            const selectedLocale: any = locales.find((locale) => {
                return locale.value === newState.locale.value;
            });
            const localIntl = getLocalTranslationsIntl(selectedLocale.key);
            const newEmailText = localIntl.formatMessage({ id: 'users.invitation_email_message' }, { reciver: newState[firstnameIdentifier].value, sender: this.props.profile.firstname, companyname: this.props.profile.organization.name, senderCompanyName: this.props.profile.organization.name });
            const updatedFormElement = {
                ...newState[messageIdentifier],
            };
            updatedFormElement.value = newEmailText;
            updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation)
            newState[messageIdentifier] = updatedFormElement;
        }
    }
}

const
    mapDispatchToProps = (dispatch): DispatchProps => {
        return {
            actions: bindActionCreators({
                createUser,
            }, dispatch),
        };
    };

const
    mapStateToProps = (state: AppState): StateProps => {
        return {
            error: state.app.users.error,
            profile: state.app.profile.profile,
            currentLocale: getCurrentLocale(state),
            users: getAllAdminUsersSelector(state),
            isFetching: getAdminUsersIsFetching(state),
        };
    };

const CreateUserModal = injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateUserModalInner) as React.ComponentType<any>)

export default CreateUserModal;
