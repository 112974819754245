import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { AuditLink } from "../../../backend_api/models";
import { getPrettyDate, isDateNull } from "../../../base/utils";
import AuditTypeTag from "../../audit/components/AuditTypeTag";

type Props = {
    auditLinks: AuditLink[];
};

const AuditLinkList = (props: Props) => {
    const history = useHistory();
    const intl = useIntl();
    return (
        <Fragment>
            {props.auditLinks.map((link) => {
                const supplierName = link.supplier_name
                    ? link.supplier_name
                    : "-";
                const supplierNumber = link.supplier_number
                    ? " : " + link.supplier_number
                    : "";
                const supplier =
                    link.supplier_name || link.supplier_number
                        ? supplierName + supplierNumber
                        : "-";
                const productionName = link.production_unit_name
                    ? link.production_unit_name
                    : "-";
                const productionNumber = link.production_unit_number
                    ? " : " + link.production_unit_number
                    : "";
                const productionUnit =
                    link.production_unit_name || link.production_unit_number
                        ? productionName + productionNumber
                        : "-";
                return (
                    <Link
                        className="cursor-pointer space-x-1"
                        to={"/audits/report/" + link.audit_id}
                        key={link.audit_id}
                    >
                        <AuditTypeTag
                            auditType={link.audit_type}
                            auditStatus={link.status}
                        />
                        {<span className="link">{supplier}</span>}
                        {<span className="link">{productionUnit}</span>}
                        <span className="link">
                            {!isDateNull(link.date)
                                ? getPrettyDate(link.date, intl.locale)
                                : null}
                        </span>
                    </Link>
                );
            })}
        </Fragment>
    );
};

export default AuditLinkList;
