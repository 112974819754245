import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import ListSubHeading from '../../../base/components/basic/items/ListSubHeading';
import ListToggle from '../../../base/components/basic/items/ListToggle';
import Label from '../../../base/ui/components/labels/Label';
import { ListItem, ListItems } from '../../../base/ui/components/listitems/ListItem';
import { getPrettyDate } from '../../../base/utils';
import { OrganizedProductDetailedDeadline, OrganizedProductRequirements } from '../../../compliance_api/models';
import ComplianceDocumentStatusPie from './ComplianceDocumentStatusPie';
import ComplianceProductRequirementDocument from './ComplianceProductRequirementDocument';

type Props = {
    deadline: OrganizedProductDetailedDeadline;
    productId: string;
    orderId?: string;
    productName: string;
    className?: string;
    showSupplier: boolean;
    isLast?: boolean;
    isFirst?: boolean;
};

const ComplianceProductDetailedDeadline = (props: Props): React.ReactElement => {
    const { deadline, productId, orderId, productName, showSupplier, isLast, isFirst } = props;
    const intl = useIntl();
    const nameCn = 'font-semibold text-lg flex space-x-1';
    const rqCn = 'flex cursor-pointer text-sm font-bold border-t-0 border-b pt-4 pb-2 px-2';
    const [open, setIsOpen] = useState(true);


    return <div className={twMerge(open && 'mb-4')}>
        <ListToggle

            onToggle={setIsOpen}
            isExpanded={open}
            header={<>
                <ListItems>
                    <ListItem
                        slim
                        className='border-none'
                        graphicItemClass='p-0 border-r-0'
                        icon={<ComplianceDocumentStatusPie
                            productName={productName}
                            statistics={deadline.statistics}
                        />}
                        meta={showSupplier && { metaBelow: { icon: 'user_supplier', firstItem: deadline.supplier?.supplier_name } }}
                    >
                        <div className={twMerge('flex w-full')}>
                            <div className='flex items-center w-full'>
                                <div className={nameCn}>
                                    <div className='flex gap-x-1'>{deadline.name}<span className='font-normal'>{getPrettyDate(deadline.date)}</span></div>
                                </div>
                            </div>
                        </div>
                    </ListItem>
                </ListItems>
            </>}
            className={twMerge('rounded-none', open && 'rounded-bl-md', (open && isFirst) && 'rounded-t-md', (!open && isFirst) && 'rounded-t-md', !open && (!isFirst || !isLast) && 'border-b-0', (isLast && !open) && 'rounded-b-md border-b')}>
            {open && <div
                key={'dl_' + deadline.name}
                className='border-x ml-4 bg-white'
            >
                <ListSubHeading className='p-2 border-b' items={[{ w: 6, text: 'Required documents' }, { w: 4, text: 'Responsible' }, { w: 2, text: 'Todo' }]} />
            </div>}
        </ListToggle>

        {open && deadline.requirements.map((_req, i): any => {
            const req: OrganizedProductRequirements = _req;
            return <div key={'req_' + req.name.text} className={twMerge('border-x ml-4 bg-white', i === (deadline.requirements.length - 1) && 'rounded-b-md')}>
                <Popup className='w-72 space-y-2' trigger={<div className={rqCn}>{req.name.text}</div>}>
                    <Label text={intl.formatMessage({ id: 'compliance.deadline.requirement.requirement_description' })} />
                    <div className='whitespace-pre'>{req.description.text || '-'}</div> {/* TODO: Requirement description should use translateTextWithTranslation */}
                </Popup>
                {req.required_documents.map((doc, index) => {
                    const userCanSeeDocument = doc.permissions?.includes('documents_show');
                    return (userCanSeeDocument && <ComplianceProductRequirementDocument
                        key={_req.requirement_id + doc.document_template_id + index}
                        requiredDocument={doc}
                        requirementId={req.requirement_id}
                        requirementName={req.name.text}
                        productId={productId}
                        orderId={orderId}
                        supplierId={deadline.supplier?.supplier_id}
                        index={index} />)
                }
                )}
            </div>
        })}
    </div>
}
export default ComplianceProductDetailedDeadline;
