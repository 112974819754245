import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Button from '../../../base/components/basic/Button';
import Input from '../../../base/components/basic/Input';
import { byId } from '../../../base/types';
import { DetailedTag, DetailedTag as Tag, TagAsList } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { checkHasFeature } from '../../profile/containers/HasFeature';
import { getProfile } from '../../profile/selectors';
import { getComplianceCategories } from '../complianceSlice';
import { getComplianceCategoriesSelector } from '../selectors/ComplianceSelectors';
import ComplianceCategorySelector from './ComplianceCategorySelector';
import ComplianceTag from './ComplianceTag';

type Props = {
    className?: string;
    tags?: TagAsList[];
    isModal?: boolean;
    onSave?: (tags: TagAsList[]) => void;
    onSelected?: (tag: TagAsList, tagD?: DetailedTag) => void;
    editable: boolean,
    onlyShowLast?: boolean,
    downwardPropagation?: boolean
    showSelector?: boolean;
    disabled?: boolean;
};

const ComplianceTagsBrowser = (props: Props): React.ReactElement => {
    const { className, tags, isModal, onSelected, downwardPropagation, showSelector = false, disabled = false } = props;
    const dispatch = useAppDispatch();
    const features = useSelector(getProfile).features;
    const userCanEditTags = checkHasFeature(features, 'u_compliance_edit_tags');
    const _categories = useSelector(getComplianceCategoriesSelector);
    const [categories, setCategories] = useState(_categories);
    const [selected, setSelected] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const getSelectedTags = () => {
        const obj = {};
        tags && tags.forEach((tag) => {
            if (obj[tag.category_id]) {
                obj[tag.category_id].push(tag);
            } else {
                obj[tag.category_id] = [tag];
            }
        });
        return obj;
    };
    const [selectedData, setSelectedData] = useState<byId<DetailedTag[]>>(getSelectedTags());

    const onSave = () => {
        const selectedTags: TagAsList[] = selected.map(s => {
            return {
                category_id: s.category_id,
                tag_id: s.tag_id
            }
        });
        props.onSave(selectedTags);
        setIsOpen(false);
    }

    const removeTag = (index: number, t: Tag) => {
        const _data = { ...selectedData };
        const remaining = _data[t.category_id].filter((tag) => tag.path.toString() !== t.path.toString())
        const _selected = selected.filter((s, i) => i != index);
        _data[t.category_id] = remaining;
        // setSelected(_selected);
        // setSelectedData(_data);
        onSelected(t);
    };

    useEffect(() => {
        dispatch(getComplianceCategories());
    }, []);
    useEffect(() => {
        setCategories(_categories);
    }, [_categories]);
    useEffect(() => {
        setSelectedData(getSelectedTags());
    }, [tags]);

    let content;
    if (props.editable) {
        content = categories.map((category, index) => {
            return <ComplianceCategorySelector
                disabled={false}
                showSelector={showSelector}
                key={index}
                index={index}
                isLast={index === categories.length - 1}
                category={category}
                tags={tags && tags.filter(t => t.category_id == category.id)}
                onSelected={props.onSelected}
                onlyShowLast={props.onlyShowLast}
                downwardPropagation={downwardPropagation} />
        })
    } else {
        content = <div className='flex flex-row flex-wrap gap-1'>
            {selectedData && Object.keys(selectedData).map((tag) => {
                return (selectedData[tag] && selectedData[tag].map((t, i) => {
                    return <ComplianceTag key={'tag_' + i} tag={t} removeTag={() => removeTag(i, t)} index={i} isEditing={props.editable} onlyShowLast={props.onlyShowLast} disabled={false} />
                }))
            })}
        </div>
    }

    const m = <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeIcon
        closeOnDimmerClick
    >
        <Modal.Header>
            <div className='flex items-center space-x-4'>
                <div><h2><FormattedMessage id='compliance.tags_browser.select_categories_and_tags' /></h2></div>
                <div className='w-1/3 text-sm'>
                    <Input type='text' />
                </div>
            </div>
        </Modal.Header>
        <Modal.Content className='p-4'>
            {content}
        </Modal.Content>
        <Modal.Actions className='flex justify-end'>
            <Button primary onClick={onSave}><FormattedMessage id='globals.ok' /></Button>
            <Button onClick={() => setIsOpen(false)}><FormattedMessage id='globals.cancel' /></Button>
        </Modal.Actions>
    </Modal>
    return <div className={twMerge('w-full', className)}>
        {isModal && <div onClick={() => setIsOpen(true)}><Button><FormattedMessage id='compliance.tags_browser.add_tags' /></Button></div>}
        {isModal ? m : content}
    </div>

}

export const addOrRemoveTag = (arr: (DetailedTag | TagAsList)[], tag: TagAsList | DetailedTag): (DetailedTag | TagAsList)[] => {
    let ret = [];
    if (tag) {
        if (arr.findIndex(i => i.tag_id == tag.tag_id) != -1) {
            const a = arr.filter(i => i.tag_id != tag.tag_id);
            console.log('delete arr ', a)
            ret = a;
        } else {
            arr.push(tag);
            ret = arr;
        }
    }
    return ret;
}

export default ComplianceTagsBrowser;



