import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "semantic-ui-react";
import Icon from "../../../../base/components/basic/Icon";
import Button from "../../../../base/ui/components/buttons/Button";
import IconCircle from "../../../../base/ui/components/icons/IconCircle";
import { Sizes } from "../../../../base/ui/components/types";
import { useAppDispatch } from "../../../../store";
import { forceUnlockAudit } from "../../slices/auditSlice";

export default function AuditLockedModal(props: {
    auditId: string
}) {
    const intl = useIntl();
    const [showAppGuide, setShowAppGuide] = React.useState(false);
    const [showForceUnlockModal, setShowForceUnlockModal] = React.useState(false);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const timeout = setTimeout(() => setShowAppGuide(true), 20000);
        return () => {
            clearTimeout(timeout);
        }
    }, []);

    const forceUnlock = () => {
        dispatch(forceUnlockAudit(props.auditId));
    }
    return (
        <div
            className="fixed flex flex-col items-center w-full h-full opacity-100 text-white top-0 left-0 justify-center z-50 overflow-y-scroll pt-48 sm:pt-0"
            style={{ backgroundColor: "rgba(0,0,0,.85)" }}
        >
            {showAppGuide ? (
                <div className="flex flex-col justify-center items-center gap-[50px] mx-4 sm:mx-0">
                    <div className="flex flex-row justify-center">
                        <IconCircle 
                            name="cloud_locked"
                            className="bg-white"
                            iconClassName="text-primary text-2xl"
                            size={Sizes.XXLarge}
                        />

                        <div className="flex flex-col px-4">
                            <div className="flex flex-row justify-start font-bold">
                                <FormattedMessage id="web_based_audit.audit_locked_by_app.title" />
                            </div>
                            <div className="flex flex-row justify-start text-base">
                                <FormattedMessage id="web_based_audit.audit_locked_by_app.description" />
                            </div>
                        </div>
                    </div>
                    <div className="flex sm:flex-row flex-col gap-7">
                        <div className="flex flex-col gap-[22px]">
                            <UnlockInstructionStep1 />
                            <div className="text-center  text-wrap max-w-[307px]">
                                <FormattedMessage id="web_based_audit.unlock_audit.guide_1"/>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[22px]">
                            <UnlockInstructionStep2 />
                            <div className="text-center text-wrap px-6 max-w-[307px]">
                                {
                                    intl.formatMessage({id: "web_based_audit.unlock_audit.guide_2"}, {translation: intl.formatMessage({id: "onboarding.continue_in_browser"})})
                                }
                            </div>
                        </div>
                    </div>
                    <div className="sm:absolute bottom-0 flex flex-col justify-center items-center mb-8 text-sm text-white gap-1">
                        <button className="cursor-pointer underline mb-4" onClick={() => setShowForceUnlockModal(true)}>
                            <FormattedMessage id="web_based_audit.unlock_audit.not_having_access"/>
                        </button>
                    </div>
                </div>
            ) : (
                <>
                    <div className="flex items-center justify-between gap-x-4 mx-4 sm:mx-0">
                        <IconCircle
                            name="status_checked-ok"
                            className="bg-status-approved"
                            iconClassName="text-on-status"
                            size={Sizes.XXLarge}
                        />
                        <div className="flex text-on-surface-brand text-3xl font-bold">
                            <FormattedMessage id="web_based_audit.unlock_audit.audit_locked_title" />
                        </div>
                    </div>
                    <div className="absolute bottom-0 flex flex-col justify-center items-center mb-8 text-sm text-white gap-1">
                        <FormattedMessage id="web_based_audit.unlock_audit.audit_locked_explanation_1"/>
                        <button
                            className="cursor-pointer underline mb-4"
                            onClick={() => setShowAppGuide(true)}
                        >
                            <FormattedMessage id="web_based_audit.unlock_audit.audit_locked_explanation_2"/>
                        </button>
                    </div>
                </>
            )}
            <Modal
                open={showForceUnlockModal}
                closeOnDimmerClick
                closeOnEscape
                size='tiny'>
                <Modal.Header><FormattedMessage id="web_based_audit.unlock_audit"/></Modal.Header>
                <Modal.Content>
                    <div className='text-wrap mb-4' dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: "web_based_audit.unlock_audit.title"})}}></div>
                    <div className="border border-default px-2 py-4 rounded-md">
                        <div className="flex flex-row text-danger font-bold mb-4"><Icon name='error' className='mr-1'/><FormattedMessage id="web_based_audit.unlock_audit.warning"/></div>
                        <div className="mb-4"><FormattedMessage id="web_based_audit.unlock_audit.warning_explanation"/></div>
                        <div className="font-bold"><FormattedMessage id="web_based_audit.unlock_audit.warning_explanation_2"/></div>
                        <button className="text-brand" onClick={() => setShowForceUnlockModal(false)}><FormattedMessage id="web_based_audit.unlock_audit.show_guide"/></button>
                    </div>
                </Modal.Content>
                <Modal.Actions className='flex flex-row justify-end my-2 gap-4'>
                    <Button className="grow text-wrap sm:grow-0 sm:text-nowrap" onPress={forceUnlock} primary><FormattedMessage id='web_based_audit.unlock_audit.button_unlock'/></Button>
                    <Button onPress={() => setShowForceUnlockModal(false)}><FormattedMessage id='globals.confirmation.cancel'/></Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

function UnlockInstructionStep1() {
    return (
        <svg
            width="307"
            height="162"
            viewBox="0 0 307 162"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_31_4574)">
                <rect
                    x="25"
                    y="-424"
                    width="278"
                    height="560"
                    rx="38"
                    fill="white"
                />
                <rect
                    x="25"
                    y="-424"
                    width="278"
                    height="560"
                    rx="38"
                    stroke="#CDCDCD"
                    stroke-width="8"
                />
                <rect
                    x="52"
                    y="-25.5"
                    width="50"
                    height="50"
                    rx="12"
                    fill="#D9D9D9"
                />
                <rect
                    x="110"
                    y="-25.5"
                    width="50"
                    height="50"
                    rx="12"
                    fill="#D9D9D9"
                />
                <rect
                    x="168"
                    y="-25.5"
                    width="50"
                    height="50"
                    rx="12"
                    fill="#D9D9D9"
                />
                <rect
                    x="226"
                    y="-25.5"
                    width="50"
                    height="50"
                    rx="12"
                    fill="#D9D9D9"
                />
                <rect
                    x="52"
                    y="57.5"
                    width="50"
                    height="50"
                    rx="12"
                    fill="#D9D9D9"
                />
                <rect
                    x="110"
                    y="57.5"
                    width="50"
                    height="50"
                    rx="12"
                    fill="#D9D9D9"
                />
                <rect
                    x="168"
                    y="57.5"
                    width="50"
                    height="50"
                    rx="12"
                    fill="#D9D9D9"
                />
                <rect
                    x="226"
                    y="57.5"
                    width="50"
                    height="50"
                    rx="12"
                    fill="#00AD61"
                />
                <path
                    d="M264.361 92.1098L267.796 95.5947L263.166 100.225L259.532 96.4908C257.092 97.7852 254.205 98.4324 250.919 98.4324C241.361 98.4324 234.789 92.6574 234.789 82.8002C234.789 72.9429 241.361 67.168 250.919 67.168C260.478 67.168 266.95 72.9429 266.95 82.8002C266.95 86.534 266.004 89.6704 264.361 92.1098ZM250.919 73.2416C245.244 73.2416 241.311 77.2741 241.311 82.85C241.361 88.5751 245.244 92.4085 250.919 92.4085C256.694 92.4085 260.627 88.1271 260.428 82.8002C260.428 77.2741 256.744 73.2416 250.919 73.2416ZM250.421 74.7849C248.231 74.7849 246.339 75.4819 244.895 76.6767C244.895 76.6767 250.82 75.93 253.956 79.1162C257.491 82.6508 257.441 88.1769 257.441 88.1769C258.387 86.7829 258.835 85.0902 258.785 83.2482C258.785 78.3196 255.499 74.7849 250.421 74.7849Z"
                    fill="white"
                />
                <path
                    d="M274.991 119.533C277.447 119.334 279.261 120.248 280.433 122.278L280.784 122.887L288.28 141.496C288.882 142.772 288.96 144.08 288.516 145.419C288.012 146.657 287.152 147.627 285.934 148.33L263.861 161.074C262.541 161.836 261.161 162.024 259.72 161.638L234.268 154.818L235.361 150.736C235.662 149.615 236.319 148.762 237.334 148.176C237.436 148.118 237.566 148.11 237.726 148.153C237.929 148.035 238.082 147.947 238.183 147.889L250.614 143.756L230.487 108.895C229.667 107.475 229.45 106.044 229.836 104.603C230.323 103.104 231.227 101.973 232.546 101.212C233.866 100.45 235.246 100.262 236.686 100.648C238.229 100.975 239.41 101.85 240.23 103.27L251.48 122.756L254.068 121.262C254.373 121.086 254.989 120.865 255.918 120.6L274.991 119.533ZM234.322 122.92C228.317 122.597 223.791 119.798 220.745 114.52C218.401 110.461 217.829 106.19 219.03 101.708C220.232 97.2255 222.862 93.8125 226.921 91.4688C230.981 89.125 235.252 88.5536 239.734 89.7547C244.216 90.9557 247.629 93.5859 249.973 97.6454C253.078 103.024 253.291 108.314 250.61 113.515L243.579 101.337C242.29 99.1041 240.394 97.6953 237.892 97.1105C235.33 96.4242 232.933 96.7256 230.701 98.0147C228.468 99.3037 227.008 101.229 226.322 103.79C225.679 106.191 226.001 108.508 227.291 110.741L234.322 122.92Z"
                    fill="#FDC35A"
                />
                <rect
                    y="10"
                    width="40"
                    height="40"
                    rx="20"
                    fill="#00AD61"
                />
                <path
                    d="M17.5327 22.6894V20.5106H22.4677V39.4894H20.0527V22.6894H17.5327Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_31_4574">
                    <rect
                        width="307"
                        height="162"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

function UnlockInstructionStep2() {
    return (
        <svg
            width="307"
            height="162"
            viewBox="0 0 307 162"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_31_4413)">
                <g clip-path="url(#clip1_31_4413)">
                    <mask
                        id="path-3-inside-1_31_4413"
                        fill="white"
                    >
                        <path d="M29 -58H299V34H29V-58Z" />
                    </mask>
                    <path
                        d="M29 -58H299V34H29V-58Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M29 -57H299V-59H29V-57Z"
                        fill="white"
                        mask="url(#path-3-inside-1_31_4413)"
                    />
                    <path
                        d="M53.5 -32C53.5 -34.2091 55.2909 -36 57.5 -36H270.5C272.709 -36 274.5 -34.2091 274.5 -32V9C274.5 11.2091 272.709 13 270.5 13H57.5C55.2909 13 53.5 11.2091 53.5 9V-32Z"
                        fill="#004869"
                    />
                    <rect
                        width="270"
                        height="98"
                        transform="translate(29 34)"
                        fill="white"
                    />
                    <path
                        d="M57.5 59H270.5C272.433 59 274 60.567 274 62.5V103.5C274 105.433 272.433 107 270.5 107H57.5C55.567 107 54 105.433 54 103.5V62.5C54 60.567 55.567 59 57.5 59Z"
                        fill="white"
                    />
                    <path
                        d="M57.5 59H270.5C272.433 59 274 60.567 274 62.5V103.5C274 105.433 272.433 107 270.5 107H57.5C55.567 107 54 105.433 54 103.5V62.5C54 60.567 55.567 59 57.5 59Z"
                        stroke="#E5E7EB"
                    />
                </g>
                <rect
                    x="25"
                    y="-424"
                    width="278"
                    height="560"
                    rx="38"
                    stroke="#CDCDCD"
                    stroke-width="8"
                />
                <path
                    d="M274.991 119.533C277.447 119.334 279.261 120.248 280.433 122.278L280.784 122.887L288.28 141.496C288.882 142.772 288.96 144.08 288.516 145.419C288.012 146.657 287.152 147.627 285.934 148.33L263.861 161.074C262.541 161.836 261.161 162.024 259.72 161.638L234.268 154.818L235.361 150.736C235.662 149.615 236.319 148.762 237.334 148.176C237.436 148.118 237.566 148.11 237.726 148.153C237.929 148.035 238.082 147.947 238.183 147.889L250.614 143.756L230.487 108.895C229.667 107.475 229.45 106.044 229.836 104.603C230.323 103.104 231.227 101.973 232.546 101.212C233.866 100.45 235.246 100.262 236.686 100.648C238.229 100.975 239.41 101.85 240.23 103.27L251.48 122.756L254.068 121.262C254.373 121.086 254.989 120.865 255.918 120.6L274.991 119.533ZM234.322 122.92C228.317 122.597 223.791 119.798 220.745 114.52C218.401 110.461 217.829 106.19 219.03 101.708C220.232 97.2255 222.862 93.8125 226.921 91.4688C230.981 89.125 235.252 88.5536 239.734 89.7547C244.216 90.9557 247.629 93.5859 249.973 97.6454C253.078 103.024 253.291 108.314 250.61 113.515L243.579 101.337C242.29 99.1041 240.394 97.6953 237.892 97.1105C235.33 96.4242 232.933 96.7256 230.701 98.0147C228.468 99.3037 227.008 101.229 226.322 103.79C225.679 106.191 226.001 108.508 227.291 110.741L234.322 122.92Z"
                    fill="#FDC35A"
                />
                <rect
                    y="10"
                    width="40"
                    height="40"
                    rx="20"
                    fill="#00AD61"
                />
                <path
                    d="M13.687 37.8488C15.9095 36.0638 17.6508 34.6025 18.9108 33.465C20.1708 32.31 21.2295 31.1113 22.087 29.8688C22.962 28.6088 23.3995 27.375 23.3995 26.1675C23.3995 25.03 23.1195 24.1375 22.5595 23.49C22.017 22.825 21.1333 22.4925 19.9083 22.4925C18.7183 22.4925 17.7908 22.8688 17.1258 23.6213C16.4783 24.3563 16.1283 25.345 16.0758 26.5875H13.7658C13.8358 24.6275 14.4308 23.1138 15.5508 22.0463C16.6708 20.9788 18.1145 20.445 19.882 20.445C21.6845 20.445 23.1108 20.9438 24.1608 21.9413C25.2283 22.9388 25.762 24.3125 25.762 26.0625C25.762 27.515 25.3245 28.9325 24.4495 30.315C23.592 31.68 22.612 32.8875 21.5095 33.9375C20.407 34.97 18.9983 36.1775 17.2833 37.56H26.3133V39.555H13.687V37.8488Z"
                    fill="white"
                />
                <text
                    fill="#34A05E"
                    xmlSpace="preserve"
                    style={{ whiteSpace: "pre" }}
                    font-family="Poppins"
                    font-size="14"
                    font-weight="600"
                    letter-spacing="0em"
                >
                    <tspan
                        x="163.5"
                        y="87.9"
                        text-anchor="middle"
                    >
                        <FormattedMessage id="onboarding.continue_in_browser" />
                    </tspan>
                </text>
            </g>
            <defs>
                <clipPath id="clip0_31_4413">
                    <rect
                        width="307"
                        height="162"
                        fill="white"
                    />
                </clipPath>
                <clipPath id="clip1_31_4413">
                    <rect
                        x="29"
                        y="-420"
                        width="270"
                        height="552"
                        rx="34"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
