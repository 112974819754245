export type SIZES =
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | '2xlarge'
    | '3xlarge'
    ;

export type SizeMap = { [SIZE in SIZES]: string; }

export const SizeMappings: SizeMap = {
    xsmall:     'text-xs',
    small:      'text-sm',
    medium:     'text-base',
    large:      'text-lg',
    xlarge:     'text-xl',
    '2xlarge':  'text-2xl',
    '3xlarge':  'text-3xl',
};

export enum Sizes {
    XSmall      =   'xsmall',
    Small       =   'small',
    Medium      =   'medium',
    Large       =   'large',
    XLarge      =   'xlarge',
    XXLarge     =   '2xlarge',
    XXXLarge    =   '3xlarge',

}

export type COLORS =
    | 'red'
    | 'green'
    | 'blue'
    | 'yellow'
    ;

type ColorMap = { [COLOR in COLORS]: string };

/* export const ColorMappings: ColorMap = {
    red: ''
} */