import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import { ListItem, ListItems, SelectableType } from '../../../base/ui/components/listitems/ListItem';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { twMerge } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { complianceSetProductState } from '../complianceSlice';

type Props = {
    className?: string;
    productId: string;
    state: string // 'draft' | 'active';
    isDisabled?: boolean;
};
const states = ['active', 'draft'];

const ComplianceProductState = (props: Props): React.ReactElement => {
    const { className, productId, isDisabled = false } = props;
    const currentState = props.state;
    const dispatch = useAppDispatch();
    const colorMap = {
        active: { bg: 'bg-status-approved', text: 'text-status-approved' },
        draft: { bg: 'bg-status-in-progress', text: 'text-status-in-progress' }
    }
    const [open, setOpen] = useState(false);
    const [state, setState] = useState(props.state);
    const intl = useIntl();

    const bg = currentState && colorMap[currentState].bg;
    const text = currentState && colorMap[currentState].text;
    useEffect(() => {
        setState(props.state);
    }, [props.state]);

    const save = () => {
        dispatch(complianceSetProductState({ product_id: productId, state }));
    }

    const cancel = () => {
        setOpen(false);
    }

    const updateState = (state: string) => {
        setState(state);
    }

    const statesList = <div>
        <ListItems className='border border-b-0'>
            {states.map((_state) => {
                return <ListItem selectable={{ selectedColorClass: colorMap[_state].bg, type: SelectableType.Radio, onPress: () => updateState(_state), id: state, selected: _state === state }}>
                    {intl.formatMessage({ id: 'compliance.product.state.' + _state })}
                </ListItem>
            })}
        </ListItems></div>

    const trigger = <div className={twMerge('w-full p-4 text-white capitalize items-center justify-between flex rounded-t-md', bg, className)}>
        <div className='text-lg font-bold'><FormattedMessage id='compliance.product.state.status'/></div>
        <div className={twMerge('bg-white rounded-md flex justify-center min-w-[100px] px-3 py-1 text-sm', text)}>{currentState}</div>
    </div>

    const modal = isDisabled ? trigger
        :
        <Modal
            isOpen={open}
            triggerContainerClass={'w-full'}
            trigger={trigger}
            width={500}
            header={intl.formatMessage({ id: 'compliance.product.state.modal_header' })}
            content={statesList}
            actionItems={[
                { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: intl.formatMessage({ id: 'compliance.product.state.modal.set_status' }), event: save, disabled: state === props.state },
                { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, text: intl.formatMessage({ id: 'globals.cancel' }), event: cancel },
            ]}
        />

    return modal;
}
export default ComplianceProductState;
