 import * as React from "react";
import { DivProps } from "react-html-props";
import { twMerge } from "tailwind-merge";
import { Sizes } from "../../../components/types";
import Icon from "./Icon";
import { Icons } from "./iconTypes";

interface Props extends DivProps {
    className?: string;
    iconClassName?: string
    size?: Sizes;
    name: Icons;
    onClick?(e): void;
}

export default function IconCircle(props: Props) {
    const {className, iconClassName, size, name, onClick} = props;


    return (
        <div className={twMerge(className, "rounded-full justify-center items-center flex size-leading-base shrink-0 grow-0")} onClick={onClick} style={{width: 48, height: 48}}>
            <Icon size={size} name={name} className={iconClassName}/>
        </div>
    )
}