import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';
import Popup from '../popup/Popup';

type Props =  {
   className?: string;
   cutAt: number;
   trigger: ComponentOrStringType;
   popupContent?: ComponentOrStringType;
};

const AndThenSome = (props: Props): React.ReactElement => {
   const { className, cutAt, trigger, popupContent } = props;
   const location = useLocation();
   const intl = useIntl();
   const [state, setState] = useState();
   useEffect(() => {
    //
}, []);
   return <div className={twMerge('', className)}>
        {popupContent ? <Popup on='hover' trigger={<span>{trigger}</span>}>{popupContent}</Popup> : trigger} <span>+ {cutAt} more</span>
   </div>
}
export default AndThenSome;
