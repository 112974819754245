import { IDocument } from '@cyntler/react-doc-viewer';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BasicUser, FileResource } from '../../../../backend_api/models';
import RemoveIcon from '../../../../base/components/basic/RemoveIcon';
import { SERVICES_PREFIX_COMPLIANCE } from '../../../../base/config';
import QModal from '../../../../base/ui/components/modals/Modal';
import { encodeURIAllChars, useIsMobileDevice } from '../../../../base/utils';
import { DetailedDocumentView, DocumentAppliesToProduct, OrganizedProduct, TextWithTranslation } from '../../../../compliance_api/models';
import { useAppDispatch } from '../../../../store';
import FileUpload from '../../../files/components/FileUpload';
import SimpleLogo from '../../../header/components/SimpleLogo';
import { complianceAddFileToDocument, complianceRemoveFilesFromDocument } from '../../complianceSlice';
import { getComplianceDetailedDocumentIdSelector, getComplianceProductSelector, isFetchingSelector } from '../../selectors/ComplianceSelectors';
import ComplianceDisplayResponsibles from '../ComplianceDisplayResponsibles';
import Approval from './ComplianceDocumentApproval';
import CoveredProducts from './ComplianceDocumentCoveredProducts';
import ComplianceDocumentValidity from './ComplianceDocumentValidity';
import ComplianceDocumentsViewerDocument from './ComplianceDocumentsViewerDocument';
import './dv.css';

type Props = {
    className?: string;
    name: TextWithTranslation;
    document: DetailedDocumentView;
    documentId: string;
    productId: string;
    deadlineId: string;
    orderId?: string;
    products?: OrganizedProduct[];
    responsibleForAddingProduct?: BasicUser;
    responsibleForApproval?: BasicUser;
    trigger?: any;
    show?: boolean;
    onClose?(): void;
};

const loadFiles = (files: string[]): IDocument[] => {
    const _files: IDocument[] = [];
    files && files.map((file) => {
        _files.push({ uri: decodeURIComponent(file), fileName: file })
    });
    return _files;
}
const ComplianceDocumentViewer = (props: Props): React.ReactElement => {
    const { className, document, products, productId, orderId, deadlineId } = props;
    const [show, setShow] = useState(props.show || false);
    const isMobile = useIsMobileDevice();
    const isFetching = useSelector(isFetchingSelector);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [files, updateFiles] = useState<IDocument[]>(loadFiles(document && document.files as any)); // TODO should be fixed by updating the FileResource type used by compliance models
    const [currentFile, setCurrentFile] = useState<IDocument>(null);
    const [addFiles, setAddFiles] = useState<boolean>(false);
    const [datesAreValid, setDatesAreValid] = useState<boolean>(false);
    const [displayDatesAreNotValid, setDisplayDatesAreNotValid] = useState<boolean>(false);
    const dvClass = 'absolute h-screen w-full bg-pink-500 top-0 z-50';
    const hasFiles = files.length > 0;
    const documentId = useSelector(getComplianceDetailedDocumentIdSelector);
    const productData = useSelector(getComplianceProductSelector);
    const orderName = productData?.detailed_orders.filter((dor) => dor.id === orderId)[0]?.name;
    const userCanSeeDocument = document?.permissions?.includes('documents_show');
    const userCanAddAndRemoveFiles = document?.permissions?.includes('documents_set_files');
    const userCanAddAndRemoveProducts = document?.permissions?.includes('documents_set_products');
    const userCanRequestAndSubmitApproval = document?.permissions?.includes('documents_request_approval');
    const userCanApproveRejectReset = document?.permissions?.includes('documents_set_approval');
    const userCanSetTimeframe = document?.permissions?.includes('documents_set_timeframe');
    const context = { deadline_id: deadlineId, product_id: productId };

    const onUploadComplate = (_files) => {
        const f = _files && _files.map((file) => ({
            uri: (() => {
                return file.id;

            })()
        }));
        updateFiles([
            ...files,
            ...f,
        ]);
        setAddFiles(false);
        const appliesTo: DocumentAppliesToProduct = {
            type: 'document_applies_to_product',
            product_id: ''
        }
        dispatch(complianceAddFileToDocument({
            appliesTo,
            requirementId: document.requirement_specification_id,
            documentTemplateId: document.document_specification_id,
            productId,
            documentId,
            fileIds: [f[0].uri],
            supplierId: '',
            orderId: '',
            supplierType: 'supplier',
            context,
        }));
    }


    const upload = <FileUpload
        asFileResource={true}
        fileSelectorLabelText='Choose files to upload'
        fileSelectorId='fileToolbarSelector'
        fileSelectorCustomIcon='operation_add'
        fileSelectorCustomIconClass='text-on-surface-brand'
        fileSelectorLabelClass='bg-branding-brand text-on-surface-brand px-8 py-3 rounded'
        hasDrop={!isMobile}
        dropClass={'px-48 pt-8 pb-72 sm:block'}
        dropPlacement='bottom'
        uploadComplete={(file: FileResource, _files: FileResource[]) => {
            onUploadComplate(_files);
        }}
        endpoint={SERVICES_PREFIX_COMPLIANCE + '/upload_media'}
        uploadStart={
            () => {
                setLoading(true);
            }}
    >
        <div className='hidden sm:block'>- or drop files here</div>
    </FileUpload>

    const closeDocumentViewer = () => {
        setShow(false);
        setDisplayDatesAreNotValid(false);
        props.onClose();
        setCurrentFile(null);
        updateFiles([]);
    }
    const setDatesValidity = (valid: boolean) => {
        setDatesAreValid(valid);
    }

    const deleteFile = (fileId: string) => {
        dispatch(complianceRemoveFilesFromDocument({ context: { product_id: productId, deadline_id: deadlineId }, productId, document_id: documentId, file_ids: [encodeURIAllChars(fileId)] }))
    }
    const deleteAllFiles = () => {
        updateFiles([]);

        /* files.forEach((file) => {
            deleteFile(file.uri);
        }) */
    }
    const getDocumentTitle = (document: DetailedDocumentView) => {
        let name = '';
        let req = null;
        if (document && document.document_specification_name) {
            name = document.document_specification_name;
        }
        if (document && document.requirement_specification_name) {
            req = document.requirement_specification_name;
        }
        return <div className='flex'><div className='font-bold'>{name}</div>{req && <div className='font-normal flex'><div>・</div><div>{req}</div></div>}</div>;

    }
    useEffect(() => {
        setAddFiles(files.length === 0);
        if (hasFiles && !currentFile) {
            setCurrentFile(files[0]);
        }
    }, [files]);

    useEffect(() => {
        updateFiles(loadFiles(document && document.files as string[]));
    }, [document && document.files]);

    useEffect(() => {
        setShow(props.show)
    }, [props.show]);

    useEffect(() => {
        setShow(props.show);
        return () => {
            setCurrentFile(null);
            updateFiles([]);
        }
    }, []);

    const documentViewer = <ComplianceDocumentsViewerDocument
        documents={files}
        key={'doc_1'}
        onAddFiles={(_files) => {
            onUploadComplate(_files);
        }}
        onDeleteFile={deleteFile}
        userCanAddAndRemoveFiles={userCanAddAndRemoveFiles}
    />

    const header = <div className='flex flex-1  max-w-[115rem] pl-8 text-2xl text-on-surface-brand items-center space-x-2 justify-between'>
        <div className='flex items-center gap-x-6 py-4'>
            <SimpleLogo className='h-6' />
            <div className='flex gap-x-6'><div className='flex items-center font-thin text-base opacity-75'>|</div>{getDocumentTitle(document)}</div>
        </div>
        <div className='px-2 cursor-pointer' onClick={() => {
            props.onClose();
            setShow(false);
        }}><RemoveIcon iconClassName='text-white' /></div>
    </div>

    const content = <QModal
        className='mt-[90px] border-none'
        animate={{ type: 'slide-up' }}
        header={header}
        headerClassName={'py-0 px-0  bg-branding-brand justify-center'}
        overlayClass='bg-white'
        widthFull
        trigger={props.trigger && props.trigger}
        isOpen={show}
        onOpenChange={(open) => {
            !open && props.onClose();
        }}
        showCloseButton={false}>
        <div className='bg-page flex justify-center pl-8'>
            <div className='flex flex-1 overflow-auto flex-col sm:flex-row max-w-[115rem]'>
                <div className='w-14/24 sm:w-16/24 lg:w-17/24 bg-white h-screen'>
                    {!addFiles && currentFile &&
                        currentFile && documentViewer
                    }
                    {addFiles && userCanAddAndRemoveFiles && <div className='flex items-center justify-center pt-16 sm:h-screen flex-col'>
                        <div className='text-3xl font-thin text-secondary'><FormattedMessage id='compliance.document.no_files_added_yet' /></div>
                        <div className='p-8'>{upload}</div>
                        {/* <div className='link flex self-center' onClick={() => setAddFiles(false)}>Close</div> */}
                    </div>}
                </div>
                <div className='w-10/24 sm:w-9/24 lg:w-7/24 px-4 py-6 ml-4 sticky top-0 space-y-8'>
                    {/* dates are valid: {JSON.stringify(datesAreValid)} */}
                    {(document && hasFiles) && <Approval
                        onClose={closeDocumentViewer}
                        productId={productId}
                        documentId={documentId}
                        deadlineId={deadlineId}
                        numberOfFiles={files.length}
                        state={document.approval}
                        datesAreValid={datesAreValid}
                        onDeleteAllFiles={deleteAllFiles}
                        onDisplayDatesAreNotValid={() => setDisplayDatesAreNotValid(true)}
                        userCanApproveRejectReset={userCanApproveRejectReset}
                        userCanRequestAndSubmitApproval={userCanRequestAndSubmitApproval}
                        rejectReason={document.rejection_reason}
                    />}

                    {document && <ComplianceDocumentValidity
                        timeframe={document.timeframe}
                        documentId={documentId}
                        onValidityUpdated={setDatesValidity}
                        state={document.approval}
                        onClose={closeDocumentViewer}
                        displayDatesAreNotValid={displayDatesAreNotValid}
                        orderName={orderName}
                        userCanSetTimeframe={userCanSetTimeframe}
                        productId={productId}
                        deadlineId={deadlineId}

                    />}
                    {document &&
                        <CoveredProducts
                            products={document.applies_to['products']}
                            suppliers={document.applies_to['suppliers']}
                            appliesTo={document.applies_to}
                            userCanAddAndRemoveProducts={userCanAddAndRemoveProducts}
                            documentId={documentId}
                            context={context}
                        />}
                    <div className='flex flex-col absolute bottom-24 gap-4'>
                        <div>
                            <div className='text-secondary'><FormattedMessage id='compliance.document_viewer.upload_responsibles' /></div>
                            <div className='flex gap-1'>
                                <ComplianceDisplayResponsibles
                                    showAll={true}
                                    supplier={document?.upload_responsible?.supplier}
                                    groups={document?.upload_responsible?.groups}
                                    users={document?.upload_responsible?.users} />
                            </div>
                        </div>
                        <div>
                            <div className='text-secondary'><FormattedMessage id='compliance.document_viewer.approval_responsibles' /></div>
                            <div className='flex gap-1'>
                                <ComplianceDisplayResponsibles
                                    showAll={true}
                                    supplier={document?.approve_responsible?.supplier}
                                    groups={document?.approve_responsible?.groups}
                                    users={document?.approve_responsible?.users} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </QModal >

    return userCanSeeDocument && show && content;

}
export default ComplianceDocumentViewer;
