import React, { Fragment, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { Audit, AuditFeaturesEnum, AuditHistoryEvent } from '../../../backend_api/models';
import LightBox from '../../../base/components/LightBox';
import Loader from '../../../base/components/Loader';
import ToTop from '../../../base/components/ToTop';
import Button from '../../../base/components/basic/Button';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import Icon from '../../../base/components/basic/Icon';
import { getLayoutSelector } from '../../../base/selectors';
import { convertFileResourcesToLightBoxMedias, useIsMobileDevice } from '../../../base/utils';
import { useAppDispatch } from '../../../store';
import { Score } from '../../checklists/types';
import PageContainer from '../../globals/components/PageContainer';
import AuditCorrectiveActionList from '../../inspections/components/report/AuditCorrectiveActionList';
import AuditReportApproval from '../../inspections/components/report/AuditReportApproval';
import CheckpointsSummary from '../../inspections/components/report/checkpoints/CheckpointsSummary';
import DownloadReport from '../../inspections/components/report/report2/DownloadReport';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { HasFeature } from '../../profile/containers';
import { getAuditHistorySelector, getAuditReportImagesSelector, getAuditReportSelector, getAuditsLoadingSelector } from '../selectors/auditSelectors';
import { deleteAudit, getAuditComments, getAuditReport } from '../slices/auditSlice';
import AuditCheckpoints from './AuditCheckpoints';
import AuditInfo from './AuditInfo';
import AuditTypeTag from './AuditTypeTag';
import AuditWatchers from './AuditWatchers';
import AuditsCommentsAndHistory from './AuditsCommentsAndHistory';
import { FileResource } from '../../../backend_api_2';

type AuditReportProps = {
    auditId: string;
};

const AuditReport = (props: AuditReportProps): React.ReactElement => {
    const dispatch = useAppDispatch();
    const auditReport = useSelector(getAuditReportSelector);
    const auditReportLoading = useSelector(getAuditsLoadingSelector);
    const auditReportImages = useSelector(getAuditReportImagesSelector) as any;
    const historyList = useSelector(getAuditHistorySelector);
    const historyAttachments = getAttachmentsFromAuditHistoryEvent(historyList);
    const auditAttachments = [].concat(historyAttachments).concat(auditReport?.audit?.attachments || []);
    const attachmentLightboxMedias = convertFileResourcesToLightBoxMedias(auditAttachments);
    const lightboxMedias = attachmentLightboxMedias?.concat(auditReportImages?.images || []);
    const scrollToTop = !useSelector(getLayoutSelector).dontScrollToTopAfterAction;
    const isMobile = useIsMobileDevice();
    const { auditId } = props;
    const location = useLocation();
    const intl = useIntl();

    useEffect(() => {
        dispatch(getAuditReport(auditId));
        dispatch(getAuditComments({ auditId }));
    }, []);

    useEffect(() => {
        location.hash ? document.location.href = location.hash : scrollToTop && window.scrollTo(0, 0);
    }, [auditReport]);

    useEffect(() => {
        if (location.hash) {
            document.location.href = location.hash;
        }
    }, [location.hash]);

    if (auditReport) {
        const checkpointsSummary = <ContentSection
            headerText={<FormattedMessage id='inspections.summary' />}
            content={<CheckpointsSummary isAudit={true} summaryScore={auditReport.audit_report_data.summary_score as Score} id={auditId} />}
        />;
        const checkpoints = <ContentSection
            headerText={<FormattedMessage id='audit_report.checkpoints_header' />}
            content={<AuditCheckpoints auditId={auditId} checkpointHeaders={auditReport.checkpoint_headers} />}
        />;

        const userHasDeleteFeature = auditReport.audit.features.includes(AuditFeaturesEnum.Delete) || false;
        const reportApproval = <AuditReportApproval audit={auditReport.audit} auditorComment={auditReport.audit_report_data.auditor_comment} />;
        const reportCorrectiveActions = <HasFeature feature='u_corrective_actions'><AuditCorrectiveActionList auditName={(auditReport.audit.target_supplier && auditReport.audit.target_supplier.name) || '-'} auditId={auditId} /></HasFeature>
        const info = <AuditInfo audit={auditReport.audit} />;
        const deleteButton = userHasDeleteFeature && <div className={twMerge(isMobile && 'pt-2')}><DeleteConfirm deleteFunction={() => dispatch(deleteAudit({ auditId, isReport: true }))} alert trigger={<Button alert><FormattedMessage id='audits.audit_report.delete_audit' /></Button>} /></div>;
        const lb = <LightBox images={lightboxMedias} metaItems={[]} />

        return <PageContainer headerClassName='' header={<div className='flex space-x-2 justify-between'>
            <DocumentTitle title={intl.formatMessage({ id: 'page_title.audit_report' }, { name: chooseHeading(auditReport.audit) })} />
            <div className='flex items-center leading-none space-x-1 auditReport'>
                <AuditTypeTag className='py-2' auditStatus={auditReport.audit.status} auditType={auditReport.audit.audit_type} auditConclusion={auditReport.audit.final_conclusion} />
                <Icon name='domain' /><div>{chooseHeading(auditReport.audit)}</div>
            </div>
            {isMobile && <DownloadReport inspectionId={auditReport.audit.id} context='audit' showLabel={false} />}
        </div>}>
            <Loader active={auditReportLoading}>
                {lb}
                <div className=''>
                    {isMobile && <Fragment>
                        {reportApproval}
                        {info}
                        {reportCorrectiveActions}
                        {checkpointsSummary}
                        {checkpoints}
                        {deleteButton}
                    </Fragment>}
                    {!isMobile && <div className='flex justify-between space-x-4'>
                        <div className='w-2/3'>
                            {info}
                            {checkpointsSummary}
                            {checkpoints}
                            {deleteButton}
                        </div>
                        <div className='w-1/3'>
                            {reportApproval}
                            {reportCorrectiveActions}
                            <AuditWatchers auditId={auditId} watchers={auditReport.audit.watchers} isReport={true} />
                            <AuditsCommentsAndHistory auditId={auditId} loadComments={false} />
                        </div>
                        <ToTop />
                    </div>}
                </div>
            </Loader>
        </PageContainer>
    }
    return null;
};

function getAttachmentsFromAuditHistoryEvent(history: AuditHistoryEvent[]): FileResource[] {
    let attachments = [];
    history?.forEach(event => {
        event?.comment_event?.attachments?.forEach(attachment => {
            attachments.push(attachment);
        });
    });
    return attachments;
}

function chooseHeading(audit: Audit) {

    if (!audit) {
        return ' - ';
    }

    const hasSupplierName = !!(audit.target_supplier && audit.target_supplier.name)
    const supplierName = audit.target_supplier && audit.target_supplier.name

    const hasSupplierNumber = !!(audit.target_supplier && audit.target_supplier.number)
    const supplierNumber = audit.target_supplier && audit.target_supplier.number

    const hasProductionUnitName = !!(audit.target_production_unit && audit.target_production_unit.name)
    const productionUnitName = audit.target_production_unit && audit.target_production_unit.name

    const hasProductionUnitNumber = !!(audit.target_production_unit && audit.target_production_unit.number)
    const productionUnitNumber = audit.target_production_unit && audit.target_production_unit.number


    if (hasProductionUnitName && hasProductionUnitNumber) {
        return `${productionUnitNumber} - ${productionUnitName}`;
    }

    if (hasProductionUnitNumber) {
        return productionUnitNumber;
    }

    if (hasProductionUnitName) {
        return productionUnitName;
    }

    if (hasSupplierName && hasSupplierNumber) {
        return `${supplierNumber} - ${supplierName}`;
    }

    if (hasSupplierNumber) {
        return supplierNumber;
    }

    if (hasSupplierName) {
        return supplierName;
    }

    return ' - ';
}

export default AuditReport;
