import React, { Fragment, useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Popup, Sticky, Table, TextArea } from 'semantic-ui-react';
import { DetailedSupplier } from '../../../backend_api/models';
import DeleteConfirm from '../../../base/components/basic/DeleteConfirm';
import Icon from '../../../base/components/basic/Icon';
import Input from '../../../base/components/basic/Input';
import Form from '../../../base/components/basic/form/Form';
import FormField from '../../../base/components/basic/form/FormField';
import { Sizes } from '../../../base/components/types';
import { Breakpoints, SERVICES_PREFIX_COMPLIANCE } from '../../../base/config';
import Button from '../../../base/ui/components/buttons/Button';
import Header from '../../../base/ui/components/headers/Header';
import KeyValuePair from '../../../base/ui/components/keyValuePair/KeyValuePair';
import Label from '../../../base/ui/components/labels/Label';
import { showSnackbar } from '../../../base/ui/uiSlice';
import { objectsAreIdentical, twMerge, useMinWidthBreak } from '../../../base/utils';
import { CreateProduct, DetailedRequirement, DetailedTag, EditProduct } from '../../../compliance_api/models';
import { OrganizedProduct } from '../../../compliance_api/models/OrganizedProduct';
import { useAppDispatch } from '../../../store';
import FileUpload from '../../files/components/FileUpload';
import PageContainer from '../../globals/components/PageContainer';
import ContentSection from '../../pageLayouts/components/ContentSection';
import { addProductAttachment, complianceDeleteProduct, complianceSetProductState, createProduct as createProductAction, editProduct, getComplianceProduct, getPreviewRequirementsForProduct, getSuppliers, removeProductAttachment, updateProductImage } from '../complianceSlice';
import { getCompliancePreviewRequirementsSelector, getComplianceProductSelector, getComplianceSuppliersSelector, isFetchingSelector } from '../selectors/ComplianceSelectors';
import ComplianceAttachmentItemList from './ComplianceAttachmentItemList';
import ComplianceCreateProductModal from './ComplianceCreateProductModal';
import ComplianceDetailedProductImage from './ComplianceDetailedProductImage';
import ComplianceProductDetailedDeadline from './ComplianceProductDetailedDeadline';
import ComplianceProductState from './ComplianceProductState';
import ComplianceProductSuppliers from './ComplianceProductSuppliers';
import CompliaceProductResponsibles from './ComplianceResponsiblesSelector';
import ComplianceTag from './ComplianceTag';
import ComplianceTagsBrowser, { addOrRemoveTag } from './ComplianceTagsBrowser';

type ComplianceProductProps = {
    className?: string;
    id?: string;
    create?: boolean;
};

const ComplianceProductDetail = (props: ComplianceProductProps): React.ReactElement => {
    const { id, create = false } = props;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const intl = useIntl();
    const isMinMD = useMinWidthBreak(Breakpoints.MD);
    const isFetching = useSelector(isFetchingSelector);
    const productData = useSelector(getComplianceProductSelector);
    const requirements: DetailedRequirement[] = useSelector(getCompliancePreviewRequirementsSelector);
    const suppliers: DetailedSupplier[] = useSelector(getComplianceSuppliersSelector);
    const [showMore, setShowMore] = useState(false);
    const [comment, setComment] = useState('');
    const [product, setProduct] = useState<OrganizedProduct>(null);
    const [okToSave, setSave] = useState(false);
    const state = create ? null : product && product.product_state; // TODO: product draft value should come from types/enum 
    const userCanEditProduct = product?.permissions?.includes('product_edit');
    const userCanDeleteProduct = product?.permissions?.includes('product_delete');
    const userCanViewProduct = product?.permissions?.includes('products_show');
    const userCanCreateProduct = product?.permissions?.includes('products_create');

    const isDraft = state === 'draft';
    const [edit, setEdit] = useState(() => {
        if (create) {
            return userCanCreateProduct
        }
        if (userCanEditProduct) {
            return isDraft ? true : false
        }
        return false;
    });

    const title = create ? 'create' : product?.product_name;
    const reference = create ? '' : product?.product_number;
    const image = create ? null : product && (product.product_image ? product.product_image.preview_url : null);
    const hasProducts = !create && product?.detailed_product_deadlines.length > 0;
    const hasOrders = !create && product?.detailed_orders.length > 0;
    const orderSuppliers = [...new Map(product?.detailed_orders?.map(dor => [dor.supplier.supplier_id, dor.supplier])).values()];
    const showSupplierOnOrderOrDeadline = orderSuppliers.length > 1;

    const handleProductUpdate = (name: string, value: string | Record<string, unknown>) => {
        const productDataCopy: OrganizedProduct = { ...product };
        productDataCopy[name] = value;
        setProduct(productDataCopy);
    }
    const saveAndActivate = () => {
        dispatch(complianceSetProductState({ product_id: id, state: 'active' }))
        setEdit(!edit);
    }
    const save = () => {
        setSave(true);
    }
    useEffect(() => {
        if (okToSave) {
            dosave();
            setSave(false);
        }
    })
    const dosave = () => {
        if (create) {
            const p: CreateProduct = { name: product.product_name, number: product.product_number, tags: [], image_id: null };
            dispatch(createProductAction(p));
            setProduct(null);
        } else {
            dispatch(showSnackbar({ hideAfter: 3000, content: <div className='flex gap-1'><FormattedMessage id={isDraft ? 'compliance.product.edit_product.saved_as_draft' : 'compliance.product.edit_product.saved'} values={{ productName: <span className='font-bold'>{product.product_name}</span> }} /></div> }))
            isDraft ? history.push('/compliance/products') : setEdit(!edit);
        }
    }

    const cancelCreate = () => {
        history.replace('/compliance/products')
    }
    const updateProductName = () => {
        if (!create) {
            const _product: EditProduct = { product_name: product.product_name, };
            dispatch(editProduct({ product_id: product.product_id, product: _product as EditProduct }));
        }
    }
    const updateProductNumber = () => {
        if (!create) {
            const _product: EditProduct = { product_number: product.product_number, };
            dispatch(editProduct({ product_id: product.product_id, product: _product as EditProduct }));
        }
    }
    const updateProductTags = (tag) => {
        const tags = product && product.product_tags || [];
        const t = addOrRemoveTag([...tags], tag) as DetailedTag[]
        !create && setProduct({
            ...product,
            product_tags: t
        });
        const _product: EditProduct = { product_tags: t, };
        dispatch(editProduct({ product_id: product.product_id, product: _product as EditProduct }));
    }
    const addAttachment = (fileId) => {
        dispatch(addProductAttachment({
            product_id: product.product_id,
            file_id: fileId
        }))
    }
    const removeAttachment = (file: any) => {
        dispatch(removeProductAttachment({
            product_id: product.product_id,
            file_id: encodeURI(file.id)
        }))
    }
    const deleteSelf = () => {
        dispatch(complianceDeleteProduct({ product_id: product.product_id }));
        setTimeout(() => history.push('/compliance/products'), 1000);
    }
    const updateImage = (image) => {
        !create && dispatch(updateProductImage({
            product_id: product.product_id,
            image_id: image.id
        }))
    }

    const translate = (key: string) => {
        return intl.formatMessage({ id: key });
    }
    const stateSection = <ComplianceProductState
        isDisabled={!userCanEditProduct}
        productId={product && product.product_id}
        state={product && (product.product_state as string)}
    />;

    const historySection = <ContentSection
        headerText={intl.formatMessage({ id: 'compliance.history_and_comments' })}
        content={
            <div>
                <Form className='w-full'>
                    <FormField>
                        <TextArea
                            value={comment}
                            // onChange={(evt) => setComment(evt.currentTarget.value)}
                            /* onBlur={updateResponsibleComment}
                            disabled={disabled} */
                            placeholder={intl.formatMessage({ id: 'comments.write_a_comment' })}
                        />
                    </FormField>
                    <Button primary><FormattedMessage id='compliance.history_and_comments.send' /></Button>
                </Form>
            </div>
        }
    />;

    const requirementsSection = <Sticky className='w-full'>
        {/* requirements.length === 0 && <EmptyState headerClassName='bg-secondary text-secondary' type={EmptyStateType.Standalone} title='No requirements yet' guidance='Add some tags'/> */}
        <Table className='w-full'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='3'><FormattedMessage id='compliance.create_product.expected_requirements_cnt' values={{ cnt: requirements.length > 0 && <span>({requirements.length})</span> }} /></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body className=''>
                {
                    requirements.length > 0 ?
                        <div>
                            {
                                requirements.map((req, i) => {
                                    return (
                                        <Table.Row key={'row_' + i}>
                                            <Table.Cell>
                                                <Popup trigger={<div>{req.requirement_name.text}</div>}>
                                                    <div>{req.requirement_description && req.requirement_description.text}</div>
                                                    {
                                                        req.document_templates && req.document_templates.length > 0 ?
                                                            <div><FormattedMessage id='compliance.create_product.documents_needed_cnt' values={{ cnt: req.document_templates.length }} /></div> : null
                                                    }
                                                </Popup>
                                            </Table.Cell>
                                            <Table.Cell className='flex flex-row flex-wrap gap-1'>
                                                {req.requirement_tags.map((tag, index) => <ComplianceTag size={Sizes.XSmall} onlyShowLast className='py-0 text-xs' key={'tag_' + tag.category_id + tag.path} tag={tag} index={index} isEditing={false} />)}
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })
                            }
                        </div> :
                        <Table.Footer>
                            <Table.Row>
                                <Table.Cell><FormattedMessage id='compliance.create_product.no_requirements_needed' /></Table.Cell>
                            </Table.Row>
                        </Table.Footer>
                }

            </Table.Body>

        </Table>
    </Sticky>

    useEffect(() => {
        !create && dispatch(getComplianceProduct(id));
        dispatch(getSuppliers());
    }, [id]);
    useEffect(() => {
        if (!create) {
            setEdit(userCanEditProduct && isDraft);
        }
    }, [isDraft]);

    useEffect(() => {
        if (productData !== null) {
            setProduct(productData);
        }
    }, [productData]);
    useEffect(() => {
        if (!objectsAreIdentical(JSON.stringify(productData && productData.product_tags), JSON.stringify(product && product.product_tags))) {
            product && dispatch((getPreviewRequirementsForProduct(product.product_tags)));
        }
    }, [product && product.product_tags]);


    const requirements2 = <div className={''}>
        {hasOrders && <div className='py-2 text-2xl font-bold'><FormattedMessage id='compliance.deadline.orders_headline' /></div>}
        {hasProducts && product.detailed_product_deadlines.map((dlp, i) => {
            return <ComplianceProductDetailedDeadline isFirst={i === 0} isLast={i === (product.detailed_product_deadlines.length - 1)} showSupplier={showSupplierOnOrderOrDeadline} productName={product.product_name} deadline={dlp} productId={product.product_id} key={'dl_' + dlp.name + i} />
        })}
        {hasOrders && product.detailed_orders?.map((dlp: any, i) => { // TODO Should use DetailedDeadline, but issue with OrderDeadlineReference getting properly selected
            return <ComplianceProductDetailedDeadline isFirst={i === 0} isLast={i === (product.detailed_orders.length - 1)} showSupplier={showSupplierOnOrderOrDeadline} productName={product.product_name} deadline={dlp} productId={product.product_id} orderId={dlp.id} key={'dl_' + dlp.deadline_name + i} />
        })}
    </div>
    const status = <div>
        <Fragment>
            {/* <ComplianceProductBundleStatusComponent /> */}
        </Fragment>
    </div>;

    const tagsBrowser = <ComplianceTagsBrowser
        disabled={isFetching}
        showSelector={false}
        tags={product && product.product_tags}
        editable={edit}
        downwardPropagation={false}
        onlyShowLast={false}
        onSelected={(tag, detailedTag): void => {
            /* const t = addOrRemoveTag([...product.product_tags], tag) as DetailedTag[]
            console.log('detailedTag ', tag)
            console.log('tag ', tag, t) */
            updateProductTags(tag);
            // console.log('get preview 1 ', t)
        }}
    />
    const tags = <ContentSection
        subHeaderText={'Tags and requirements'}
        content={<div className=''>
            <div className='flex gap-4'>
                {tagsBrowser}
                {edit && requirementsSection}
            </div>
        </div>
        } />

    const createModal = <ComplianceCreateProductModal
        save={save}
        cancelCreate={cancelCreate}
        handleProductUpdate={handleProductUpdate}
    />
    const attachments = <ContentSection
        content={<div>
            <div className='font-bold text-lg'>Attachments</div>
            <div className='py-4'>
                <FileUpload
                    asFileResource={true}
                    allowMultiUpload={true}
                    fileSelectorLabelText={intl.formatMessage({ id: 'compliance.product_detail.attachments.add' })}
                    fileSelectorLabelClass={'link noUnderline text-base'}
                    endpoint={SERVICES_PREFIX_COMPLIANCE + '/upload_media'}
                    uploadComplete={(file: any) => {
                        addAttachment(file.id);
                    }}
                    autoClearAfterUpload={true}
                />
            </div>
            {product && <ComplianceAttachmentItemList attachments={product.attachments} deleteItem={removeAttachment} />}
        </div>
        } />
    const info = <ContentSection
        subHeaderText={edit && 'Product information and image'}
        content={<div className='flex flex-col w-full'>
            <div className={twMerge('w-full flex', edit && 'flex-row-reverse', (image || edit) && 'gap-x-6')}>
                <div className={twMerge('flex flex-col', edit && ' w-4/12')}>

                    {edit && <Label text={intl.formatMessage({ id: 'compliance.product_detail.product_image' })} />}
                    <ComplianceDetailedProductImage image={image} edit={edit} updateImage={updateImage} />

                </div>
                <div className={twMerge('flex flex-col gap-4 flex-1', edit && 'w-8/12')}>
                    {edit && <div className='flex flex-col'>
                        <FormField>
                            <Label text={intl.formatMessage({ id: 'compliance.product_detail.product_name' })} />
                            <Input value={product && product.product_name} type='text' name='product_name' onBlur={updateProductName} onChange={(item) => handleProductUpdate('product_name', item.currentTarget.value)} />
                        </FormField>
                        <FormField>
                            <Label text={intl.formatMessage({ id: 'compliance.product_detail.product_number' })} />
                            <Input value={product && product.product_number} type='text' name='product_number' onBlur={updateProductNumber} onChange={(item) => handleProductUpdate('product_number', item.currentTarget.value)} />
                        </FormField>
                    </div>}

                    <div className='flex flex-1'>
                        <div className='w-full'>
                            {!edit && userCanEditProduct &&
                                <Button className='float-right py-0' onPress={() => setEdit(!edit)} icon='operation_edit' size={Sizes.XSmall} context>{translate('compliance.product_detail.edit')}</Button>
                            }
                            <div className='gap-y-4 flex flex-col'>
                                {!edit && <ComplianceProductSuppliers suppliers={orderSuppliers} />}
                                <CompliaceProductResponsibles
                                    productId={product && product.product_id}
                                    responsibleUsers={product && product.responsible_users || []}
                                    responsibleGroups={product && product.responsible_groups || []}
                                    edit={edit} />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-0'>

                        {!edit && <KeyValuePair keyItem={{ name: 'Tags' }} value={{
                            value: product?.product_tags?.length > 0 ? tagsBrowser : <span>No tags</span>,
                        }} />}
                    </div>
                    {!edit && <div className=''>
                        {product && product.attachments?.length > 0 &&
                            <div className='pt-4 mt-4 -mx-4 px-4 cursor-pointer flex flex-col gap-2 border-t'>
                                <div className='font-bold'>Attachments</div>
                                <ComplianceAttachmentItemList attachments={product.attachments} disabled={!userCanEditProduct} deleteItem={removeAttachment} />
                            </div>
                        }
                    </div>}
                </div>
            </div>
        </div>}
    />
    return create ?
        createModal
        :
        <PageContainer

        >
            <DocumentTitle title={intl.formatMessage({ id: 'page_title.compliance.product' }, { name: title })} />
            <Header
                pageTitle={title}
                reference={reference}
                entity={translate('page_title.entity.product')} />

            <div className='flex flex-col'>
                {/* userCanEditProduct: {JSON.stringify(userCanEditProduct)}<br />
                userCanViewProduct: {JSON.stringify(userCanViewProduct)}<br />
                edit: {JSON.stringify(edit)}<br />
                create: {JSON.stringify(create)}<br />
                draft: {JSON.stringify(isDraft)}<br /> */}

                {userCanViewProduct && <div className='flex-col md:flex-row w-full flex md:space-x-4'>
                    <div className='w-full md:w-8/12 order-last md:order-first'>
                        {info}
                        {edit && tags}
                        {edit && attachments}
                        {edit && <div className='pb-8 justify-between flex'>
                            <div className='flex gap-x-4'>
                                <Button primary onPress={isDraft ? saveAndActivate : save}>{translate(isDraft ? 'compliance.product.edit_product.save_and_activate' : 'compliance.product.edit_product.done')}</Button>
                                {isDraft && <Button onPress={save}>{translate('compliance.product.edit_product.keep_draft')}</Button>}
                            </div>

                            {userCanDeleteProduct && <DeleteConfirm
                                type='alert'
                                alertHeader={intl.formatMessage({ id: 'compliance.product.delete_product' })}
                                alertText={intl.formatMessage({ id: 'compliance.product.delete_product_text' }, { productName: <span className='font-bold'>{product && product.product_name}</span> }) as string}
                                alertButtonText={intl.formatMessage({ id: 'compliance.product.delete_product_button_ok' })}

                                deleteFunction={deleteSelf}
                                trigger={<span><Button stopPropagation icon='operation_delete'>{translate('compliance.product.edit_product.delete')}</Button></span>} />}
                        </div>}
                        {!edit && requirements2}
                        {!isMinMD && stateSection}
                        {!isMinMD && historySection}
                    </div>
                    <div className='w-full md:w-4/12 order-first md:order-last pt-0 pb-2'>
                        {status}
                        {isMinMD && stateSection}
                        {isMinMD && historySection}

                    </div>
                </div>}
            </div>
            <Link className='link noUnderline items-center flex' to={'/compliance/products'}><Icon name='chevron_left' className='text-xl' /><FormattedMessage id='compliance.product_detail.back' /></Link>
        </PageContainer >
}

export default ComplianceProductDetail;
