import React, { useEffect, useMemo, useState } from 'react';
import { usePress } from 'react-aria';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import uuid from 'uuid/v4';
import { BasicUser } from '../../../backend_api_2';
import { getShowModalSelector, showModal } from '../../../base/baseSlice';
import Button from '../../../base/ui/components/buttons/Button';
import ContextMenu from '../../../base/ui/components/contextMenu/ContextMenu';
import Icon from '../../../base/ui/components/icons/Icon';
import { ListItem, ListItems, SecondaryActionEnum } from '../../../base/ui/components/listitems/ListItem';
import { Sizes } from '../../../base/ui/components/types';
import ComponentsSeparatedBy from '../../../base/ui/components/util/ComponentsSeparatedBy';
import { getLocationEntries, preventDefaultAndStopPropagation, twMerge } from '../../../base/utils';
import { GetDetailedDocument, OrganizedProductRequiredDocument, OrganizedProductRequiredDocumentStateEnum } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import UserPopup from '../../users/components/UserPopup';
import { CreateNewDocumentType, complianceClearDocument, complianceCreateDocument, getComplianceDetailedDocument } from '../complianceSlice';
import { getComplianceDetailedDocumentSelector } from '../selectors/ComplianceSelectors';
import ComplianceIgnoreRequirement from './ComplianceIgnoreRequirement';
import ComplianceProductRequirementDocumentState from './ComplianceProductRequirementDocumentState';
import ComplianceDocumentViewer from './document-viewer/ComplianceDocumentViewer';

type Props = {
    className?: string;
    requiredDocument: OrganizedProductRequiredDocument;
    requirementId: string;
    requirementName: string;
    productId: string;
    supplierId: string;
    orderId: string;
    index: number;
};

const ComplianceProductRequirementDocument = (props: Props): React.ReactElement => {
    const { className, requiredDocument, productId, supplierId, requirementId, requirementName, index } = props;
    const location = useLocation();
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const _d = useSelector(getComplianceDetailedDocumentSelector);
    const [document, setDocument] = useState(_d);
    const [modalId, setModalId] = useState(requiredDocument.deadline_id + index);
    const [load, setLoad] = useState(false);
    const [showDocument, setShowDocument] = useState(false);
    const [ignoreId] = useState(uuid());
    const modalData = useSelector(getShowModalSelector);
    const modal = modalData && modalData['compliance_document_viewer_' + modalId]
    const modalOpen = modal && modal.show || false;
    const isIgnored = requiredDocument.document_type === 'ignored';
    const userCanAddAndRemoveFiles = requiredDocument?.permissions?.includes('documents_set_files');
    const userCanRequestAndSubmitApproval = requiredDocument?.permissions?.includes('documents_request_approval');
    const userCanApproveRejectReset = requiredDocument?.permissions?.includes('documents_set_approval');
    const userCanIgnoreRequirement = requiredDocument?.permissions?.includes('documents_ignorations');


    const documentIdParam = getLocationEntries(location).docId && getLocationEntries(location).docId[0] || null;
    const andLabel = intl.formatMessage({ id: 'globals.and_lower_case' });
    useEffect(() => {
        setDocument(_d);
    }, [_d]);

    useEffect(() => {
        // console.log('index ', index, documentIdParam, requiredDocument.document_id)
        /* if (documentIdParam && (modal && modal.data && modal.data !== requiredDocument.deadline_id) && documentIdParam === requiredDocument.document_id) {
            getDocument(documentIdParam);
        } */
    }, [index])
    const getDocument = (id: string) => {
        if (!modalOpen) {
            dispatch(showModal({ id: 'compliance_document_viewer_' + modalId, show: true, data: requiredDocument.deadline_id }))
        }

        let _id;
        if (id) {
            _id = id;
            const req: GetDetailedDocument = { context: { product_id: productId, deadline_id: requiredDocument.deadline_id }, document_id: id }
            dispatch(getComplianceDetailedDocument(req));
        } else {
            const newId = uuid();
            _id = newId;
            let timeframe;
            let applies_to;
            let order_id;
            if (requiredDocument.required_of.type === 'required_of_supplier') {
                applies_to = {
                    supplier_id: requiredDocument.required_of.supplier_id,
                    type: 'document_applies_to_suppliers',
                }
            }
            if (requiredDocument.required_of.type === 'required_of_product') {
                applies_to = {
                    product_id: requiredDocument.required_of.product_id,
                    type: 'document_applies_to_product',
                }
            }
            if (requiredDocument.required_at.type === 'order') {
                order_id = requiredDocument.required_at.order_id;
                timeframe = {
                    type: 'time_frame_order',
                    order_id,
                }
            }
            if (requiredDocument.required_at.type === "no_expiration") {
                timeframe = {
                    type: 'time_frame_no_expiration',
                    from: null
                }
            }
            if (requiredDocument.required_at.type === 'expiration_from_file') {
                timeframe = {
                    type: 'time_frame_interval',
                    from: null,
                    until: null,
                }
            }
            const req: CreateNewDocumentType = {
                applies_to,
                document_id: newId,
                document_specification_id: requiredDocument.document_template_id,
                requirement_specification_id: requiredDocument.requirement_id,
                supplier: {
                    supplier_id: supplierId,
                    type: 'supplier',
                },
                productId,
                timeframe,
                context: { product_id: productId, deadline_id: requiredDocument.deadline_id }
            }
            dispatch(complianceCreateDocument(req));
            // return newId;
        }
        return _id;
    }

    const closeDocument = () => {
        dispatch(complianceClearDocument());
        dispatch(showModal({ id: 'compliance_document_viewer_' + modalId, show: false }))
        setDocument(null);
        setShowDocument(false);
        // history.push(location.pathname);
    }

    const displayIgnoreRequirement = () => {
        dispatch(showModal({ id: 'complianceIgnoreRequirement', show: true, data: { id: ignoreId } }));
    }
    const dv = useMemo(() => {
        return <ComplianceDocumentViewer
            documentId={requiredDocument.document_id}
            productId={productId}
            products={null}
            document={document}
            name={requiredDocument.name}
            show={(modalOpen)}
            onClose={closeDocument}
            orderId={props.orderId}
            deadlineId={requiredDocument.deadline_id}
        />
    }, [document, modalData]);
    const { pressProps, isPressed } = usePress({
        onPress: (e) => {
            if (isIgnored) {
                displayIgnoreRequirement();
                return false;
            }
            e.continuePropagation();
            const id = getDocument(requiredDocument.document_id);
            history.push('?docId=' + id);

        }
    })
    const actionClass = 'w-[105px] items-center justify-center';
    const isApproved = requiredDocument.state === OrganizedProductRequiredDocumentStateEnum.Approved;
    const getState = (state) => {
        switch (state) {
            /* case OrganizedProductRequiredDocumentStateEnum.Approved: {
                return <span className={twMerge(actionClass, 'flex gap-1 text-secondary')}><Icon name='status_approved-circle' size={Sizes.XSmall} /><FormattedMessage id='compliance.requirement_document.state.done' /></span>
            } */
            case OrganizedProductRequiredDocumentStateEnum.NotReadyForApproval: {
                if (!requiredDocument.document_has_files) {
                    return userCanAddAndRemoveFiles ? 
                    <Button size={Sizes.Small} context className={actionClass}><FormattedMessage id='compliance.requirement_document.state.add_files' /></Button>
                        :
                        <span className='flex justify-center text-secondary w-full'>Awaiting files</span>
                }
                if (requiredDocument.document_has_files) {
                    return userCanRequestAndSubmitApproval ? <Button size={Sizes.Small} context className={actionClass}><FormattedMessage id='compliance.requirement_document.state.submit' /></Button>
                        :
                        <span className='flex justify-center text-secondary w-full'>Awaiting submit</span>
                }
            }
            case OrganizedProductRequiredDocumentStateEnum.ReadyForApproval: {
                return userCanRequestAndSubmitApproval ? <Button onPress={(e) => {
                    // displayIgnoreRequirement();
                    e.continuePropagation();
                }} context size={Sizes.Small} className={actionClass}><FormattedMessage id='compliance.requirement_document.state.review' /></Button>
                    :
                    <span className='flex justify-center text-secondary w-full'>Awaiting review</span>
            }
            case OrganizedProductRequiredDocumentStateEnum.Approved: {
                return <span className={twMerge(actionClass, 'flex gap-1 text-secondary')}><Icon name='status_approved-circle' size={Sizes.XSmall} /><FormattedMessage id='compliance.requirement_document.state.done' /></span>

            }

            case OrganizedProductRequiredDocumentStateEnum.Rejected: {
                return userCanApproveRejectReset ?
                    <Button onPress={(e) => e.continuePropagation()} size={Sizes.Small} context className={actionClass}><FormattedMessage id='compliance.requirement_document.state.replace_files' /></Button>
                    :
                    <span className='flex justify-center text-secondary w-full'>Awaiting approval</span>
            }

        }

    }
    return <div className={twMerge('', className)} >
        {dv}
        <ListItems>
            <ListItem className={twMerge('flex cursor-pointer', (isIgnored && !userCanIgnoreRequirement) && 'cursor-default', isApproved && 'pr-[40px]')}
                secondaryAction={!isIgnored && !isApproved && userCanIgnoreRequirement && {
                    type: SecondaryActionEnum.Custom, customContent: !isIgnored && <div className='border-l-1'
                        onClick={(e) => preventDefaultAndStopPropagation(e)}
                    >
                        <ContextMenu
                            trigger={<Button stopPropagation ghost className='p-1 outline-none border'><Icon name='microinteraction_context-menu' /></Button>}
                            overflowItems={[{
                                label: 'Ignore requirement',
                                icon: 'toggle_visibility-off',
                                onPress: displayIgnoreRequirement,
                            }]}
                        />
                    </div>
                }}

                onPress={(e) => {
                    if (isIgnored) {
                        displayIgnoreRequirement();
                        return false;
                    }
                    const id = getDocument(requiredDocument.document_id);
                    history.push('?docId=' + id);
                    e.continuePropagation();
                }}
                icon={<ComplianceProductRequirementDocumentState state={requiredDocument.state} hasFiles={requiredDocument.document_has_files} isIgnored={isIgnored} />}>
                <div className='flex w-full'>
                    <div className='w-5/12 flex items-center'>
                        {requiredDocument.name?.text}
                    </div>
                    <div className='w-5/12  text-left items-center'>
                        {requiredDocument.responsible.groups?.map((group) => <div>{group.name}</div>)}
                        {requiredDocument.responsible.supplier?.supplier_name}
                        <ComponentsSeparatedBy components={requiredDocument.responsible.users.map((user) => <UserPopup user={user as BasicUser} />)} separateBy=',' endWithLabel={andLabel} />
                    </div>
                    <div className='w-2/12 h-f flex text-sm justify-end items-center'>
                        {!isIgnored && <>{getState(requiredDocument.state)}</>}
                    </div>
                    {userCanIgnoreRequirement && <ComplianceIgnoreRequirement
                        products={[]}
                        supplierId={supplierId}
                        orderId={props.orderId}
                        documentSpecificationId={requiredDocument && requiredDocument.document_template_id}
                        documentName={requiredDocument.name.text}
                        documentId={requiredDocument.document_id}
                        requirementName={requirementName}
                        isIgnored={isIgnored}
                        id={ignoreId}
                    />}
                </div>
            </ListItem>
        </ListItems>
    </div >;
}
export default ComplianceProductRequirementDocument;
