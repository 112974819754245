import { UploadRequest } from '@navjobs/upload';
import { FileResource } from '../../backend_api/models';

export type AttachmentResponse = {
    mimetype: string;
    name: string;
    size: number;
    type: string;
    url: string;
}

export const uploadImage = (
    file: File,
    setProgress,
    endpoint,
    doneCallback?: (error: any, response: AttachmentResponse, file: File) => void) => {

    return (): void => {
        const token = sessionStorage.getItem('id_token') || sessionStorage.getItem('url_token');
        UploadRequest(
            {
                request: {
                    url: endpoint,
                    headers: {
                        'Content-Type': file.type,
                        'Authorization': 'Bearer ' + token,
                    },
                },
                files: [file],
                progress: (progress) => {
                    setProgress(file, progress);
                },
            },
        ).then((data: { response: AttachmentResponse; error: any }) => {
            const response = data.response;
            const attachment: AttachmentResponse | FileResource = response;
            doneCallback(data.error, attachment, file);
        });
    };
};

export const uploadMedia = (
    file: File,
    setProgress,
    endpoint,
    doneCallback?: (error: any, response: AttachmentResponse, file: File) => void) => {

    return (): void => {
        const type = file?.type.toLocaleLowerCase().includes('image') ? 'image' : 'file';
        const token = sessionStorage.getItem("id_token") || sessionStorage.getItem("url_token");
        
        UploadRequest(
            {
                request: {
                    url: endpoint,
                    method: "post",
                    headers: {
                        "Authorization": "Bearer " + token,
                    },
                    fields: {
                        type,
                        data: file,
                    }

                },
                files: [file],
                progress: (progress) => {
                    setProgress(file, progress);
                },
            },
        ).then((data: { response: AttachmentResponse; error: any }) => {
            const response = data.response;
            const attachment: AttachmentResponse | FileResource = response;
            doneCallback(data.error, attachment, file);
        });
    };
};
