import React from 'react';
import { PressEvent } from 'react-aria-components';
import { Sizes } from '../../../components/types';
import { twMerge } from '../../../utils';
import Button from '../buttons/Button';
import Icon from '../icons/Icon';
import { Icons } from '../icons/iconTypes';

type Props = {
    className?: string;
    icon?: Icons;
    title: string;
    contextButton?: {
        label: string, event(e: PressEvent): void;
    };
};

const ListItemsHeader = (props: Props): React.ReactElement => {
    const { className, icon, title, contextButton } = props;
    
    return <div className={twMerge('flex gap-1 pb-2 items-center justify-between w-full', className)}>
        {icon && <Icon name={icon} />}
        <div className='text-lg font-bold'>{title}</div>
        {contextButton && <Button className='px-3' size={Sizes.Small} onPress={contextButton.event}>{contextButton.label}</Button>}
    </div>
}
export default ListItemsHeader;
