import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import CheckboxRadio from '../../../base/components/basic/CheckboxRadio';
import Button, { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import QModal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { getStringsSeparatedBy, isUndefinedOrNull, twMerge } from '../../../base/utils';
import { DetailedDocumentTemplateResponsible, SetApproveResponsible } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { getUserNameText } from '../../users/components/Widgets';
import { getUsersById } from '../../users/selectors';
import { complianceRequirementSetApproveResponsible } from '../complianceSlice';
import ComplianceResponsiblesSelector from './ComplianceResponsiblesSelector';
// import { complianceRequirementSetApproveResponsible } from '../complianceSlice';

type Props = {
    className?: string;
    requirementId: string;
    documentTemplateId: string;
    approvalResponsibleData: DetailedDocumentTemplateResponsible;
};

const ComplianceRequirementDocumentApprovalResponsible = (props: Props): React.ReactElement => {
    const { className, requirementId, documentTemplateId, approvalResponsibleData } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const usersById = useSelector(getUsersById);
    const intl = useIntl();
    const supplierLabel = intl.formatMessage({ id: 'compliance.responsibles.add_responsible.supplier' });
    const prLabel = intl.formatMessage({ id: 'compliance.responsibles.add_responsible.product_responsible' });

    // const [approvalResonsible, setApprovalResonsible] = useState<SetApproveResponsible>({ document_template_id: documentTemplateId, ... });
    const [approvalResonsible, setApprovalResonsible] = useState<SetApproveResponsible>({
        document_template_id: documentTemplateId,
        group_ids: approvalResponsibleData.groups.map((g) => g.group_id),
        user_ids: approvalResponsibleData.users.map((u) => u.id),
        product_responsibles: approvalResponsibleData.product_responsibles,
        suppliers: approvalResponsibleData.supplier,
    });
    const prev = usePrevious(approvalResonsible)
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    }

    const handleChange = () => {
        setApprovalResonsible
    }
    const trigger = () => {
        const ret = [];
        if (approvalResonsible.product_responsibles) {
            ret.push(<div>{prLabel}</div>)
        }
        if (approvalResonsible.suppliers) {
            ret.push(<div>{supplierLabel}</div>)
        }
        if (approvalResonsible.user_ids.length > 0) {
            ret.push(<div className='text-left'>{getStringsSeparatedBy(approvalResponsibleData.users.map((u) => getUserNameText(u)), ',', 'and')}</div>);
        }
        if (approvalResonsible.group_ids.length > 0) {
            ret.push(<div className='text-left'>{getStringsSeparatedBy(approvalResponsibleData.groups.map((g) => g.name), ',', 'and')}</div>);
        }
        return ret.length === 0 ? [<Button ghost className='p-0'><FormattedMessage id='compliance.responsibles.add_responsible' /></Button>] : ret;
    }
    const save = () => {
        dispatch(complianceRequirementSetApproveResponsible({ ...approvalResonsible, requirement_id: requirementId }))
    }
    useEffect(() => {
        if (!isUndefinedOrNull(prev)) {
            save();
        }
    }, [approvalResonsible]);
    const modal = <QModal
        actionItems={[{ buttonType: ButtonTypes.Primary, type: ActionTypes.Action, event: null, text: 'Done' }]}
        width={500}
        trigger={<div className='flex flex-col items-start gap-y-2'>{trigger()}</div>}
        header={intl.formatMessage({ id: 'compliance.responsibles.add_approve_responsible.header' })}
        content={<div className={twMerge('flex flex-col gap-y-4', className)}>
            <CheckboxRadio.Checkbox checked={approvalResonsible.suppliers} label={supplierLabel} onChange={() => {
                setApprovalResonsible({
                    ...approvalResonsible,
                    suppliers: !approvalResonsible.suppliers,
                })
            }} />
            <CheckboxRadio.Checkbox checked={approvalResonsible.product_responsibles} label={prLabel} onChange={() => {
                setApprovalResonsible({
                    ...approvalResonsible,
                    product_responsibles: !approvalResonsible.product_responsibles,
                })
            }} />

            <ComplianceResponsiblesSelector name={intl.formatMessage({ id: 'compliance.responsibles.add_approve_responsible.users_and_groups' })} productId='' edit={true} responsibleGroups={approvalResonsible.group_ids} responsibleUsers={approvalResonsible.user_ids}
                onGroupsUpdated={(groupIds) => {
                    setApprovalResonsible({
                        ...approvalResonsible,
                        group_ids: groupIds,
                    })
                }}
                onUsersUpdated={(userIds) => {
                    setApprovalResonsible({
                        ...approvalResonsible,
                        user_ids: userIds,
                    })
                }}
            />
            {/* <Button onPress={save}>Save</Button> */}

        </div>}>
    </QModal>
    return modal;
}
export default ComplianceRequirementDocumentApprovalResponsible;
