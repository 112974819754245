/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 43.7.2 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Attachment
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    mimetype?: string;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Attachment
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    type?: AttachmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    url?: string;
}

/**
* @export
* @enum {string}
*/
export enum AttachmentTypeEnum {
    File = 'file',
    Image = 'image'
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mimetype': !exists(json, 'mimetype') ? undefined : json['mimetype'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mimetype': value.mimetype,
        'name': value.name,
        'size': value.size,
        'type': value.type,
        'url': value.url,
    };
}


