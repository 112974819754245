import React from 'react';
import { PressEvent } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { FileResource } from '../../../../backend_api/models';
import FileUpload from '../../../../modules/files/components/FileUpload';
import { SERVICES_PREFIX_COMPLIANCE } from '../../../config';
import Icon from '../../../ui/components/icons/Icon';
import { twMerge } from '../../../utils';
import { Sizes } from '../../types';

type Props = {
    className?: string;
    onAddFiles(files: FileResource[]): void;
    onDeleteFile(): void;
    onZoomOut(): void;
    onZoomIn(): void;
    userCanAddAndRemoveFiles: boolean;
};

const FileActions = (props: Props): React.ReactElement => {
    const { className, onAddFiles, onDeleteFile, onZoomIn, onZoomOut, userCanAddAndRemoveFiles } = props;
    const intl = useIntl();
    const zoomIn = (e: PressEvent) => {
        onZoomIn();
    }
    const zoomOut = (e: PressEvent) => {
        onZoomOut();
    }
    const deleteFile = (e: PressEvent) => {
        onDeleteFile();
    }
    const fs = <FileUpload
        asFileResource={true}
        fileSelectorId='fileToolbarSelector'
        fileSelectorLabelText={intl.formatMessage({ id: 'file_toolbar.add files' })}
        fileSelectorLabelClass='bg-surface-plain text-xs text-on-surface-plain border-default border px-2 py-1 rounded'
        hasDrop={false}
        autoClearAfterUpload={true}
        hideAll={true}
        endpoint={SERVICES_PREFIX_COMPLIANCE + '/upload_media'}
        uploadComplete={(file: FileResource, _files: FileResource[]) => {
            onAddFiles(_files);
        }}
    >

    </FileUpload>
    return <div className={twMerge('flex p-1 items-center gap-x-6 whitespace-pre', className)}>
        <div className='gap-x-4 flex'>
            <div><Icon size={Sizes.XLarge} onPress={zoomIn} name='operation_zoom-in' className='hover:brightness-0 cursor-pointer' /></div>
            <div><Icon size={Sizes.XLarge} onPress={zoomOut} name='operation_zoom-out' className='hover:brightness-0 cursor-pointer' /></div>
            {userCanAddAndRemoveFiles && <div><Icon size={Sizes.XLarge} onPress={deleteFile} name='operation_delete' className='hover:brightness-0 cursor-pointer' /></div>}
            <div><Icon size={Sizes.XLarge} name='operation_download' className='hover:brightness-0 cursor-pointer' /></div>
        </div>
        {userCanAddAndRemoveFiles && <div>{fs}</div>}
        {/* <div><Button onClick={onAddFiles} className='text-sm py-0 px-3'>Add files</Button></div> */}

    </div>
}
export default FileActions;
