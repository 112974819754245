import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store';
import RemoveIcon from '../../../components/basic/RemoveIcon';
import { Sizes } from '../../../components/types';
import { ComponentOrStringType } from '../../../types';
import { twMerge } from '../../../utils';
import { getSnackDataSelector, hideSnackbar } from '../../uiSlice';
import Icon from '../icons/Icon';
import { Icons } from '../icons/iconTypes';

export type SnackbarProps = {
    show?: boolean;
    className?: string;
    hideAfter?: number;
    content: ComponentOrStringType;
    action?: ReactElement;
    icon?: Icons;
};

const Snackbar = (): React.ReactElement => {
    const dispatch = useAppDispatch();
    const snackData = useSelector(getSnackDataSelector);
    const { content, action, hideAfter, show, icon, className, } = snackData;
    // TODO was z10 ?
    const base = 'rounded-md inline-flex items-center shadow-xl z-50 w-full w-[90%] sm:w-auto';
    const position = 'fixed bottom-0 justify-between sm:justify-around';
    const padding = 'px-6 py-3';
    const margin = 'mx-[5%] sm:ml-8 mb-8';
    const backgroundColor = 'bg-surface-secondary';
    const textColor = 'text-on-surface-secondary';
    const textSize = 'text-base';
    const contentClass = twMerge('py-2', action && 'pr-4');
    const actionClass = '';
    const iconClass = 'items-baseline pr-2';
    const animateClass = show ? 'transition translate-y-0' : 'transition duration-300 translate-y-60';

    const hide = () => {
        dispatch(hideSnackbar());
    };
    useEffect(() => {
        show && hideAfter > 0 && setTimeout(() => {
            hide();
        }, hideAfter);
    }, [show]);

    return <div className={twMerge('', base, position, padding, margin, backgroundColor, textColor, textSize, className, animateClass)}>
        <span className='flex items-center'>{icon && <div className={iconClass}><Icon className='text-on-surface-secondary' size={Sizes.Small}  name={icon} /></div>}
            <div className={contentClass}>{content}</div>
            {action && <div className={actionClass}>{action}</div>}
        </span>
        <RemoveIcon onClick={hide} className='pl-5' iconClassName={iconClass} />
    </div>
}
export default Snackbar;
