import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Comment, Divider, Icon } from 'semantic-ui-react';
import CommentInput from '../../../base/components/comments/CommentInput';
import Loader from '../../../base/components/Loader';
import { useAppDispatch } from '../../../store';
import { addCommentToCorrectiveAction, getCorrectiveActionHistory, resetCorrectiveActionHistory } from '../correctiveActionsSlice';
import { getHistorySelector, isFetchingHistorySelector } from '../selectors/correctiveActionSelectors';
import CorrectiveActionEvent from './CorrectionActionEvent';

type Props = {
    correctiveActionId: string
}

const CorrectiveActionHistory = (props: Props): React.ReactElement => {
    const {correctiveActionId} = props;
    const dispatch = useAppDispatch();
    const isFetching = useSelector(isFetchingHistorySelector);
    const history = useSelector(getHistorySelector);

    useEffect(() => {
        let getCAHistory = undefined;
        if(correctiveActionId) {
            getCAHistory = dispatch(getCorrectiveActionHistory(correctiveActionId));
        }
        
        return () => {
            dispatch(resetCorrectiveActionHistory());
            getCAHistory?.abort?.();
        }
    }, [correctiveActionId]);

    const newComment = (comment: string) => {
        dispatch(addCommentToCorrectiveAction({ correctiveActionId: props.correctiveActionId, comment: comment }))
    }
    return <Fragment>
        <Fragment>
            <CommentInput handleNewComment={newComment} showUpload={false} />
            <Comment.Group>
                <div className={isFetching ? "-m-4" : ""}>
                    <Loader active={isFetching} spinner={<Icon name='circle notch' loading color='green' size='large' />}>
                        {history.history.map((event) => {
                            return (<Fragment key={event.id}>
                                <Divider />
                                <CorrectiveActionEvent event={event} />
                            </Fragment>)
                        })}
                    </Loader>
                </div>
            </Comment.Group>
        </Fragment>
    </Fragment>
}

export default CorrectiveActionHistory;