import React, { useState } from 'react';
import { PressEvent, usePress } from 'react-aria';
import { Button, FieldError, Input, Label, NumberField, NumberFieldProps, SearchField, SearchFieldProps, TextField, TextFieldProps, ValidationResult } from 'react-aria-components';
import { Sizes } from '../../../components/types';
import { isUndefinedOrNullOrEmptyString, twMerge } from '../../../utils';
import Icon from '../icons/Icon';
import { Icons } from '../icons/iconTypes';
import InvalidFieldMessage from '../labels/InvalidFieldMessage';
import { LabelProps } from '../labels/Label';
import Action, { ActionProps } from './Action';

interface CommonType {
    fieldType?: 'text' | 'numberInterval' | 'search';
    className?: string;
    value?: any;
    action?: ActionProps;
    label?: LabelProps;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    iconLeft?: Icons;
    iconLeftClass?: string;
    iconRight?: Icons;
    iconRightClass?: string;
    labelLeft?: string;
    labelRight?: string;
    inlineAction?: InlineAction;
    placeholder?: string;
    search?: boolean;
    onValueChanged?(value: string): void;
}
export interface FieldProps extends Omit<CommonType & TextFieldProps, null> {
    fieldType?: 'text';
}
interface NumberIntervalProps extends Omit<CommonType & NumberFieldProps, null> {
    fieldType?: 'numberInterval';
}
interface SearchProps extends Omit<CommonType & SearchFieldProps, null> {
    fieldType?: 'search';
    search?: boolean;
}

type InlineAction = {
    name: Icons;
    onPress?(e: PressEvent): void;
}

export const GenericField = (props: CommonType): React.ReactElement => {
    const { search = props.fieldType === 'search', className, iconLeft, iconRight, labelLeft, labelRight, placeholder, onValueChanged, label, action, inlineAction, fieldType = 'text' } = props;
    const [value, setValue] = useState(props.value);
    const base = 'flex rounded-md flex-1';
    const text = 'text-base';
    const border = twMerge('border-input border', (iconLeft || labelLeft) && 'rounded-l-none', (iconRight || labelRight || inlineAction || (!isUndefinedOrNullOrEmptyString(props.value) && search) ) && 'rounded-r-none');
    const bg = 'bg-input';
    const inputPadding = 'px-3 py-2';
    const iconClass = twMerge(bg, 'flex border-input border rounded items-center justify-center px-3');
    const iconLeftClass = twMerge('rounded-r-none  border-r-0', props.iconLeftClass);
    const iconRightClass = twMerge('rounded-l-none  border-l-0', props.iconRightClass);
    const searchButtonClass = twMerge('rounded  border rounded-l-none border-l-0 px-3 border-input bg-input');

    const labelClass = twMerge(base, 'text-input-label text-sm items-center pb-1');
    const labelInputClass = twMerge('flex text-sm pb-1 gap-3 p-2 pl-3 items-center rounded justify-center bg-white border-input border',
        labelLeft && 'rounded rounded-r-none border-r-0',
        labelRight && 'rounded rounded-l-none border-l-0',
        iconLeft && 'rounded-none',
        iconRight && 'rounded-none',
    );
    const inlineActionClass = twMerge('flex text-sm pb-1 rounded-r rounded-l-none p-2 items-center justify-center bg-white border-input border border-l-0 cursor-pointer');

    const { pressProps, isPressed } = usePress({
        onPress: (e) => {
            inlineAction && inlineAction.onPress && inlineAction.onPress(e);
        }
    });

    const getValidationErrorMessage = (validationDetails: ValidityState) => {
        let message = '';
        /* switch (true) {
            case validationDetails.valueMissing:
                message = intl.formatMessage({ id: 'field.validation.error_message.value_missing' })
                break;
        } */
        return <InvalidFieldMessage message={JSON.stringify(validationDetails)} />
    }

    const getFieldInner = (innerProps) => {
        return <div>
            <div className='flex justify-between'>
                <div>
                    {label && <Label className={twMerge('gap-1', labelClass)}>
                        {label.icon && <Icon name={label.icon} size={Sizes.XSmall} className='text-secondary' />}
                        {label.text}</Label>}
                    <FieldError>
                        {({ defaultChildren }) => {
                            return <InvalidFieldMessage message={defaultChildren as string} />
                        }}
                    </FieldError>
                </div>
                {action && <Action label='Action' destructable={action.destructable} />}
            </div>
            <div className='flex'>
                {iconLeft && <Icon size={Sizes.Small} name={iconLeft} className={twMerge(iconClass, iconLeftClass)} />}
                {labelLeft && <div className={labelInputClass}>{labelLeft}</div>}
                <Input
                    inputMode={props.fieldType === 'numberInterval' ? 'numeric' : null}
                    className={twMerge(base, border, bg, text, inputPadding)}
                    onBlur={() => {
                        innerProps?.onBlur();   
                        onValueChanged && onValueChanged(value);
                    }}
                    value={value}                    
                />
                {!isUndefinedOrNullOrEmptyString(props.value) && search && <Button className={searchButtonClass}><Icon name='operation_remove' /></Button>}
                {inlineAction && <div className={inlineActionClass} {...pressProps}><Icon name={inlineAction.name} /></div>}
                {labelRight && <div className={labelInputClass}>{labelRight}</div>}
                {iconRight && <Icon size={Sizes.Small} name={iconRight} className={twMerge(iconClass, iconRightClass)} />}
            </div>
        </div>
    }

    switch (props.fieldType) {
        case 'text': {
            return (
                <TextField {...props}>
                    {getFieldInner(props)}
                </TextField>
            );
        }
        case 'numberInterval': {
            return (
                <NumberField  {...props} >
                    {getFieldInner(props)}
                </NumberField>
            )
        }
        case 'search': {
            return (
                <SearchField  {...props} >
                    {getFieldInner(props)}
                </SearchField>
            )
        }
    }
}
export const Field = (props: FieldProps) => {

    return <GenericField fieldType='text' {...props} />
}
export const NumberInterval = (props: NumberIntervalProps) => {

    return <GenericField fieldType='numberInterval' {...props} />;

}
export const Search = (props: SearchProps) => {
    return <GenericField fieldType='search' {...props} />
}
export default { Search, Field, NumberField };


