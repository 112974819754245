/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 100.0.4 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuditStatus,
    AuditStatusFromJSON,
    AuditStatusFromJSONTyped,
    AuditStatusToJSON,
    AuditType,
    AuditTypeFromJSON,
    AuditTypeFromJSONTyped,
    AuditTypeToJSON,
} from './';

/**
 * A link between a CA and an Audit
 * @export
 * @interface AuditLink
 */
export interface AuditLink {
    /**
     * 
     * @type {string}
     * @memberof AuditLink
     */
    audit_id: string;
    /**
     * 
     * @type {AuditType}
     * @memberof AuditLink
     */
    audit_type?: AuditType | null;
    /**
     * 
     * @type {Date}
     * @memberof AuditLink
     */
    date: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLink
     */
    production_unit_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLink
     */
    production_unit_number: string | null;
    /**
     * 
     * @type {AuditStatus}
     * @memberof AuditLink
     */
    status: AuditStatus;
    /**
     * 
     * @type {string}
     * @memberof AuditLink
     */
    supplier_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLink
     */
    supplier_number: string | null;
}

export function AuditLinkFromJSON(json: any): AuditLink {
    return AuditLinkFromJSONTyped(json, false);
}

export function AuditLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'audit_id': json['audit_id'],
        'audit_type': !exists(json, 'audit_type') ? undefined : AuditTypeFromJSON(json['audit_type']),
        'date': (json['date'] === null ? null : new Date(json['date'])),
        'production_unit_name': json['production_unit_name'],
        'production_unit_number': json['production_unit_number'],
        'status': AuditStatusFromJSON(json['status']),
        'supplier_name': json['supplier_name'],
        'supplier_number': json['supplier_number'],
    };
}

export function AuditLinkToJSON(value?: AuditLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'audit_id': value.audit_id,
        'audit_type': AuditTypeToJSON(value.audit_type),
        'date': (value.date === null ? null : value.date.toISOString().substr(0,10)),
        'production_unit_name': value.production_unit_name,
        'production_unit_number': value.production_unit_number,
        'status': AuditStatusToJSON(value.status),
        'supplier_name': value.supplier_name,
        'supplier_number': value.supplier_number,
    };
}


