import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import Button from '../../../../base/ui/components/buttons/Button';
import { objectHasKeys } from '../../../../base/utils';
import history from '../../../../store/history';
import HasFeatureAccess from '../../../globals/components/access/HasFeatureAccess';
import { InspectionType } from '../../types';
import { CombineInspectionIcon } from '../combine/CombinedInspections';

interface NewInspectionProps {
    inspectionTypes?: InspectionType[];
    combinedInspectionType?: InspectionType;
    showCombine: boolean;
    hasInspectionTypes: boolean;
    toggleCombine(type: InspectionType);
}

class NewInspection extends Component<NewInspectionProps, any> {
    public state = { open: false };
    public constructor(props) {
        super(props);
        this.show = this.show.bind(this);
        this.cancel = this.cancel.bind(this);
    }
    public render(): React.ReactElement {
        const { inspectionTypes, hasInspectionTypes, showCombine } = this.props;
        const currentType = this.getCurrentType(this.props.combinedInspectionType);
        return (<div className='flex h-fit-content justify-end md:justify-start gap-1'>
            <HasFeatureAccess feature='u_combine_split_inspections'>
                {hasInspectionTypes && <Modal
                    open={this.state.open}
                    size='mini'
                    dimmer='inverted'
                    onClose={(): void => this.cancel()}
                    className='CISelectInspectionType'
                    trigger={
                        <Popup trigger={<Button data-test-id='btn-combine-inspections' secondary onPress={this.show} className={twMerge(showCombine ? 'open' : 'closed', 'h-[44px]')}>
                            <span className='transform rotate-90'><CombineInspectionIcon className='' /></span>
                            <FormattedMessage id='inspections.combine' />
                        </Button>}>
                            <Popup.Header><FormattedMessage id='inspections.combine_inspections' /></Popup.Header>
                            <Popup.Content>
                                <FormattedMessage id='inspections.combine_inspections_from_different_orders' />
                            </Popup.Content>
                        </Popup>
                    }>
                    <Modal.Header><FormattedMessage id='inspections.combined_inspection.choose_inspection_type' /></Modal.Header>
                    <Modal.Content>
                        <div className='pb-4 items-center w-full'><FormattedMessage id='inspections.combined_inspection.choose_inspection_type_for_combined_order' /></div>
                        <div className='p-2 flex items-center flex-col space-y-1'>
                            {inspectionTypes.map((inspectionType: InspectionType, index: number) => {
                                return <div className='inspectionType items-center' key={index}>
                                    <Button className='w-48 text-sm self-center' primary disabled={currentType === inspectionType.id} onPress={(): void => this.selectType(inspectionType)}>{inspectionType.name}</Button>
                                </div>;
                            })}
                            <div className='inspectionType'><Button dataTestId='collect-ci-type-none-button' className='w-48 text-sm' primary onPress={(): void => this.selectType(null)} ><FormattedMessage id='inspections.inspection_types.fake_none' /></Button></div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions><Button onPress={this.cancel}><FormattedMessage id='globals.cancel' /></Button></Modal.Actions>
                </Modal>}
                {!hasInspectionTypes && <Popup
                    trigger={<Button secondary onPress={(): void => this.selectType(null)} className={`newCombine ${showCombine ? 'open' : 'closed'}`} data-test-id='btn-combine-inspections'><CombineInspectionIcon />
                        <FormattedMessage id='inspections.combine' />
                    </Button>}
                >
                    <Popup.Header><FormattedMessage id='inspections.combine_inspections' /></Popup.Header>
                    <Popup.Content>
                        <FormattedMessage id='inspections.combine_inspections_from_different_orders' />
                    </Popup.Content>
                </Popup>}

            </HasFeatureAccess>
            <HasFeatureAccess feature='u_create_order_and_inspection'>
                <Button secondary onPress={() => {
                    history.push('/inspections/create_inspection')
                }}
                    data-test-id='btn-new-inspection'
                    icon='operation_add' iconClassName='text-on-surface-secondary'>
                    <FormattedMessage id='inspections.new' />
                </Button>
            </HasFeatureAccess>
        </div>);
    }
    private show(): void {
        this.setState({ open: true });
    }
    private cancel(): void {
        this.setState({ open: false });
    }

    private selectType(type: InspectionType): void {
        this.setState({ open: false });
        this.props.toggleCombine(type);
    }

    private getCurrentType(currentType: InspectionType): string {
        if (currentType && objectHasKeys(currentType)) {
            return currentType.id;
        }
        return '';
    }
}
export default NewInspection;
