import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { Image, List, Modal } from 'semantic-ui-react';
import { Audit, AuditChecklistWithContent, AuditCheckpoint, FileResource } from '../../../../backend_api_2';
import Button from '../../../../base/components/basic/Button';
import Icon from '../../../../base/components/basic/Icon';
import Link from '../../../../base/components/basic/Link';
import { isMobileDevice } from '../../../../base/utils';
import { TranslateText } from '../../../globals/components';
import LightBox from '../../../globals/components/Lightbox';
import { useUrlQueForDownload } from '../../../inspections/components/report/report2/UrlQueHooks';
import Video from '../../../video/components/Video';
import { getAuditContentByIdSelector } from '../../selectors/auditSelectors';
import { getAppLink } from './PerformAuditPage';

export function AuditCheckpointMedia(props: {
    audit: Audit,
    checkpoint: AuditCheckpoint,
    triedToSubmit: boolean
}) {
    const {checkpoint, audit, triedToSubmit} = props;
    const url = getAppLink({auditId: audit.id, checkpointId: checkpoint.id});
    const {checklist} = useSelector(getAuditContentByIdSelector);
    const auditImages = getAuditImages(checklist)

    function openAppLink() {
        window.location.href = url;
    }

    if(checkpoint.observed_file_resources.length > 0) {
        return <div className='photo-grid'>
            {checkpoint.observed_file_resources.map((media) => {
                if (media.type == 'video') {
                    return <CheckpointVideo
                        video={media}
                    />
                }
                else {
                    return <CheckpointImage
                        image={media}
                        images={auditImages}
                    />
                }
            })}
        </div>
    }
    if(!checkpoint.is_picture_mandatory || checkpoint.checkpoint_status == AuditCheckpoint.checkpoint_status.NOT_APPLICABLE) {
        return null;
    }

    if(isMobileDevice()) {
        return (
            <div className='flex flex-col items-start bg-gray-50 rounded-md border px-4 py-4 my-4'>
                {
                    triedToSubmit &&
                    <div className='text-sm flex flex-row text-status-rejected items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <div><FormattedMessage id='web_based_audit.mandatory_media.warning_mobile'/></div>
                    </div>
                }
                <div className='text-lg font-bold my-1'><FormattedMessage id='web_based_audit.medias_required'/></div>
                <div className='text-sm my-1'><FormattedMessage id='web_based_audit.add_media.description_on_mobile'/></div>
                <div className='flex flex-row justify-between items-center my-1'>
                <Button
                    className="justify-center text-base my-2 shrink-0 mr-4"
                    primary
                    large
                    onClick={openAppLink}
                    behaveAsLink
                >
                    <FormattedMessage id="web_based_audit.go_to_app" />
                </Button>
                <AppExplantionModal openAppLink={openAppLink}/>
                </div>
            </div>
        )
    }

    return (
        <div className='flex flex-row items-center bg-gray-50 rounded-md border mb-4'>
            <div className='flex flex-col justify-center items-center bg-white w-100 h-100 py-3 px-3 rounded-md'>
                <QRCode value={url} style={{height: "auto", maxWidth: "100%", width: "100%"}} />
                <div className='pt-2'><FormattedMessage id='web_based_audit.scan_to_add_images'/></div>
            </div>
            <div className='flex flex-col pl-6 justify-between'>
                {
                    triedToSubmit &&
                    <div className='text-sm flex flex-row text-status-rejected items-center'>
                        <Icon name='error' className='text-lg mr-1' />
                        <div><FormattedMessage id='web_based_audit.mandatory_media.warning_web'/></div>
                    </div>
                }
                <div className='text-lg font-bold'><FormattedMessage id='web_based_audit.medias_required'/></div>
                <div className='text-sm'><FormattedMessage id='web_based_audit.add_media.description'/></div>
                <AppExplantionModal openAppLink={openAppLink}/>
            </div>
        </div>
        
    )
}

function AppExplantionModal(props: {
    openAppLink: () => void
}) {
    const {audit} = useSelector(getAuditContentByIdSelector);
    const [showing, setShowing] = React.useState(false);

    React.useEffect(() => {
        if(audit.locked) {
            setShowing(false);
        }
    }, [audit])
    
    return (
        <Modal
            open={showing}
            onOpen={() => setShowing(true)}
            onClose={() => setShowing(false)}
            closeOnDimmerClick
            closeOnEscape
            trigger={<div className='text-link-color my-1 cursor-pointer'><FormattedMessage id='web_based_audit.add_media.why'/></div>}
            size='tiny'
        >
            <Modal.Header><FormattedMessage id='web_based_audit.add_media.title'/></Modal.Header>
            <Modal.Content>
                <div><FormattedMessage id='web_based_audit.add_media.explanation'/></div>
                <div className='py-2'><FormattedMessage id='web_based_audit.add_media.slogan'/></div>
            </Modal.Content>
            <Modal.Actions className='flex justify-end my-2'>
                {/* <Button onClick={openAppLink} primary large><FormattedMessage id='web_based_audit.get_the_app'/></Button> */}
                <Button primary large onClick={() => setShowing(false)}><FormattedMessage id='onboarding.modal.confirm'/></Button>
            </Modal.Actions>
        </Modal>
    )
}

export function AddMediaModal(props: {
    appLink: string,
    showing: boolean,
    setShowing: (showing: boolean) => void
}) {
    const {appLink, showing, setShowing} = props;

    return (
        <Modal
            open={showing}
            onOpen={() => {
                setShowing(true);
            }}
            onClose={() => setShowing(false)}
            closeOnDimmerClick
            closeOnEscape
            size='tiny'
        >
            <Modal.Header><FormattedMessage id='web_based_audit.add_media.title'/></Modal.Header>
            <Modal.Content>
                {
                    isMobileDevice() ?
                    <AddMediaModalContentMobile appLink={appLink} /> :
                    <AddMediaModalContentWeb appLink={appLink} />
                }
            </Modal.Content>
            <Modal.Actions className='flex justify-end my-2'>
                <Button className='' onClick={() => {
                    setShowing(false);
                }}><FormattedMessage id='global.close'/></Button>
            </Modal.Actions>
        </Modal>
    )
}

function AddMediaModalContentWeb(props: {
    appLink: string
}) {
    const {appLink} = props;
    return (
        <div>
            <div className='flex flex-col items-center bg-gray-50 rounded-md border px-6 py-6'>
                <QRCode value={appLink} size={250}/>
                <div className='px-6 pt-6'>
                    <div className='text-sm'><FormattedMessage id='web_based_audit.add_media.description'/></div>
                </div>
            </div>
            <div className='my-4'>
                <div className='font-bold'><FormattedMessage id='web_based_audit.add_media.why'/></div>
                <div className='my-2 text-sm'><FormattedMessage id='web_based_audit.add_media.explanation'/></div>
                <div className='py-2 text-sm'><FormattedMessage id='web_based_audit.add_media.slogan'/></div>
            </div>
        </div>
    )
}

function AddMediaModalContentMobile(props: {
    appLink: string
}) {
    const {appLink} = props;

    function openAppLink() {
        window.location.href = appLink;
    }
    return (
        <div>
            <div className='flex flex-row items-center justify-between bg-gray-50 rounded-md border px-4 py-4'>
                <Button
                    className="justify-center text-base my-2 shrink-0 mr-4"
                    primary
                    large
                    onClick={openAppLink}
                >
                    <FormattedMessage id="web_based_audit.go_to_app" />
                </Button>
                <div className='text-sm'><FormattedMessage id='web_based_audit.add_media.description_on_mobile'/></div>
            </div>
            <div className='my-4'>
                <div className='font-bold'><FormattedMessage id='web_based_audit.add_media.why'/></div>
                <div className='my-2 text-sm'><FormattedMessage id='web_based_audit.add_media.explanation'/></div>
                <div className='py-2 text-sm'><FormattedMessage id='web_based_audit.add_media.slogan'/></div>
            </div>
        </div>
    )
}

const CheckpointVideo = (props: {
    video: FileResource;
}): React.ReactElement => {
    const video = props.video;
    const className = video.description ? 'withComment' : '';

    return (
        <React.Fragment>
            <div className='item'>
                <div className={className}>
                    <div className='image-container'>
                        <Video
                            thumbnail={video.preview_url}
                            videoResources={video.video.video_resources}
                            usePlayIcon={true}
                            preload={true}
                        />
                        {video.description && <div className='videoComment'>
                            <TranslateText
                                showTranslatedComments={false}
                                translateObj={video.description}
                                originalLanguage={'en'}
                                originalText={video.description.text}
                                showToggleActions={true}
                            />
                        </div>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const CheckpointImage = (props: {
    image: FileResource,
    images: string[]
}): React.ReactElement => {
    const IMAGE_WIDTH = 375;
    const image = props.image;
    const croppedURL = image.preview_url + '?fit=crop&w=' + IMAGE_WIDTH;
    const imageURL = image.image.image_url;
    const [url, urlDone] = useUrlQueForDownload(croppedURL);
    const anchorId = 'image_' + imageURL;
    console.log(image);
    return (<List.Item key={'checkpointImageItem_' + imageURL}>
        <a id={anchorId}></a>
        {<LightBox photoURL={imageURL} images={props.images} type="other">
            <div className='image-container checkpointOrDefectPhoto' key={'image_container_' + imageURL}>
                <Link to={'?imageURL=' + imageURL}>
                    <Image
                        key={'image_' + imageURL}
                        rounded
                        src={url}
                        size='large'
                        className='photo'
                        onLoad={urlDone}
                    />
                </Link>
                {!!image.description.text && <div className='videoComment'>
                    <TranslateText
                        showTranslatedComments={false}
                        translateObj={image.description}
                        originalLanguage={'en'}
                        originalText={image.description.text}
                        showToggleActions={true}
                    />
                </div>
                }
            </div>
        </LightBox>}
    </List.Item>);
}

function getAuditImages(checklist: AuditChecklistWithContent): string[] {
    if(!checklist) {
        return [];
    }
    let images = [];
    checklist.headers.forEach(header => {
        header.checkpoints.forEach(c => {
            c.observed_file_resources.forEach(file => {
                if(file.image?.image_url) {
                    images.push(file.image?.image_url)
                }
            })
        });
    })
    return images;
}