import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { CreatedByMigration } from '../../../backend_api_2';

type Props = {
    event: CreatedByMigration
}

const CreatedByMigrationEvent = (props: Props) => {
    return <Fragment>
        <label className='mb-1'><FormattedMessage id={'corrective_actions.events.created_by_migration'} /></label>
    </Fragment>
}

export default CreatedByMigrationEvent;