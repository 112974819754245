import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { Sizes } from '../../../components/types';
import { removeArrayFromArray, twMerge } from '../../../utils';
import Button, { ButtonTypes } from '../buttons/Button';

type Props = {
    className?: string;
    items: any[];
    onItemSelected?(items: any[]);
    show?: boolean;
    multiple?: boolean;
    placeholderText?: string;
};

const ItemSelector = (props: Props): React.ReactElement => {
    const { className, items, onItemSelected, show, multiple = false, placeholderText } = props;
    const [selectedItems, setSelectedItem] = useState([]);
    const handleChange = (e, { value }): void => {
        if (multiple) {
            const ps = removeArrayFromArray(value, selectedItems);
            setSelectedItem(value);
        } else {
            setSelectedItem([value]);
        }
    };

    return show && <div className={twMerge('gap-2 flex items-center w-full', className)}>
        <Dropdown fluid options={items} onChange={handleChange} selection search selectOnBlur={false} multiple={multiple} placeholder={selectedItems.length === 0 ? placeholderText : null} />
        <Button size={Sizes.Small} className='self-center' buttonType={ButtonTypes.Secondary} onPress={() => onItemSelected(selectedItems)} ><FormattedMessage id='globals.done' /></Button>
    </div>
}
export default ItemSelector;
