import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AddCommentBody, AddReplyBody, ChangeSupplierInformationBody, CorrectiveAction, DetailedSupplier, Group, InputCustomFieldDefinition, InputCustomFieldGroups, InputCustomFieldValue, PaginatedManagedSuppliers, SupplierFilters, SupplierListStatusEnum, SupplierServiceDetailedSupplier, SupplierServiceHistoryEntry } from '../../../backend_api/models';
import { request2 } from '../../../base/api';
import { setContextData } from '../../../base/baseSlice';
import { AppState, Context, byId } from '../../../base/types';
import { objectHasKeys } from '../../../base/utils';
import history from '../../../store/history';
import { catchException } from '../../errorHandling/handler';
import { sendErrorMessage, sendStatusMessage } from '../../messages/actions';

const initialState: SuppliersState = {
    legacyEndpoint: {
        data: [],
        fetching: 0,
        lastRequest: undefined
    },
    suppliers: null,
    status: SupplierListStatusEnum.Complete,
    error: false,
    correctiveActions: [],
    isFetchingCorrectiveActions: false,
    detailedSupplier: null,
    detailedSupplierLoading: false,
    customFieldsList: null,
    historyEntries: [],
    loadingHistory: false
};

export type LegacySupplierListState = {
    data: DetailedSupplier[];
    fetching: number;
    /* timestamp or not set */
    lastRequest: number | undefined
}

export type SuppliersState = {
    legacyEndpoint: LegacySupplierListState;
    suppliers: PaginatedManagedSuppliers;
    status: SupplierListStatusEnum;
    error: boolean;
    correctiveActions: CorrectiveAction[];
    isFetchingCorrectiveActions: boolean;
    detailedSupplier: SupplierServiceDetailedSupplier;
    detailedSupplierLoading: boolean;
    customFieldsList: InputCustomFieldGroups,
    historyEntries: SupplierServiceHistoryEntry[];
    loadingHistory: boolean
}

/* Partially replaces the createSupplier in actions.ts. This one does not open the supplies page!*/
export const createLegacySupplier = createAsyncThunk<DetailedSupplier, DetailedSupplier>(
    'createLegacySupplier',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'suppliers2'
        const options = {
            method: 'post',
            body: JSON.stringify(params)
        }

        const rq = await (request2(url, options));

        if (!rq.ok) {
            dispatch(sendErrorMessage(['status_message.supplier_could_not_be_created']))
            catchException('createSupplier', {
                endpoint: 'suppliers2',
                request: 'suppliers2',
                status: rq.status,
            }, {
                error: rq,
                params
            });
            return rejectWithValue(rq as Response)
        }

        const detailedSupplier = await rq.json();

        // dispatch(sendStatusMessage(['status_message.the_supplier_was_succesfully_created'], 3000));
        return detailedSupplier
    }
)

export const getLegacySuppliersList = createAsyncThunk<DetailedSupplier[]>(
    'getLegacySuppliersList',
    async (params, { dispatch, getState, rejectWithValue }) => {
        const legacyState: LegacySupplierListState = (getState() as AppState).app.suppliers2.legacyEndpoint;
        /* If a request is running already, stop. */
        if (legacyState.fetching > 1) {
            return;
        }

        /* If the last request has been started less than 5 seconds ago, stop. */
        const now = Date.now();
        const seconds = 5;
        if (legacyState.lastRequest && now < legacyState.lastRequest + seconds * 1000) {
            return;
        }

        const url = 'suppliers2';
        const rq = await request2(url)

        if (!rq.ok) {
            dispatch(sendErrorMessage(['error_message.audits_getting_suppliers_list_failed'], 3000));
            catchException('getLegacySuppliersList', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }

        const detailedSuppliers = await rq.json()
        return detailedSuppliers;
    }
)

type SupplierListParams = {
    search?: string;
    offset?: number;
    limit?: number;
}
export const getSuppliersList = createAsyncThunk<PaginatedManagedSuppliers, SupplierFilters>(
    'getSuppliersList',
    async (params, { dispatch, rejectWithValue }) => {
        let queryStr;
        const url = 'suppliers2/managed_suppliers?'
        if (objectHasKeys(params)) {
            // queryStr = qs.stringify(params, { skipNull: true });
            // url += queryStr;
        }
        const rq = await request2(url, { method: 'post', body: JSON.stringify({ search: params.search || null, pagination: params.pagination, custom_fields: params.custom_fields }) });
        if (!rq.ok) {
            console.log('getting suppliers list did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audits_getting_suppliers_list_failed'], 3000));
            catchException('getSuppliersList', {
                endpoint: 'suppliers2/managed_suppliers',
                request: 'suppliers2/managed_suppliers',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        const suppliers = await rq.json() as PaginatedManagedSuppliers;
        dispatch(setContextData({ context: Context.Suppliers, metaData: { list: { total: suppliers.pagination.total } } }))
        return suppliers;
    });

export const getCorrectiveActionsOnDashboardSupplier = createAsyncThunk<CorrectiveAction[], string>(
    'getCorrectiveActionsOnDashboardSupplier',
    async (supplier_id, { dispatch, rejectWithValue }) => {
        const url = 'suppliers2/dashboard/corrective_actions_on_supplier/' + supplier_id;
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('getting corrective actions did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audits_getting_suppliers_list_failed'], 3000));
            catchException('getSuppliersList', {
                endpoint: 'suppliers2/dashboard/corrective_actions_on_supplier/[supplier_id]',
                request: 'url',
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        return await rq.json();
    });

export const getCorrectiveActionsOnDashboardProductionUnit = createAsyncThunk<CorrectiveAction[], { supplierId: string, productionUnitId: string }>(
    'getCorrectiveActionsOnDashboardProductionUnit',
    async ({ supplierId, productionUnitId }, { dispatch, rejectWithValue }) => {
        const url = 'suppliers2/dashboard/corrective_actions_on_production_unit/' + supplierId + '/' + productionUnitId;
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('getting corrective actions did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.audits_getting_suppliers_list_failed'], 3000));
            catchException('getSuppliersList', {
                endpoint: 'suppliers2/dashboard/corrective_actions_on_production_unit/[supplierId]/[productionUnitId]',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        return await rq.json();
    });
export const getDetailedSupplier = createAsyncThunk<SupplierServiceDetailedSupplier, string>(
    'getDetailedSupplier',
    async (supplierId, { dispatch, rejectWithValue }) => {
        const url = 'suppliers/detailed/' + supplierId;
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('getting detailed supplier did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.suppliers.get_detailed_supplier_failed'], 3000));
            catchException('getDetailedSupplier', {
                endpoint: 'suppliers/detailed/:supplierId',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        return await rq.json();
    });

export const getSupplierHistory = createAsyncThunk<SupplierServiceHistoryEntry[], { id: string }>(
    'getSupplierHistory',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'suppliers/' + params.id + '/get_supplier_history';
        const rq = await request2(url, { method: 'get' });
        if (!rq.ok) {
            console.log('getting the supplier history did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.getting_supplier_history_failed']));
            catchException('getSupplierHistory', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        return await rq.json();
    });

export const addCommentToSupplier = createAsyncThunk<string, AddCommentBody>(
    'addCommentToSupplier',
    async (commentBody: AddCommentBody, { dispatch, rejectWithValue }) => {
        const rq = await request2('comments/add_comment', { method: 'post', body: JSON.stringify(commentBody) });
        if (!rq.ok) {
            console.log('Adding comment to the production unit does not succeed...');
            dispatch(sendErrorMessage(['error_message.adding_pu_comment_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(getSupplierHistory({id: commentBody.entity_id}));
        
        const data = await rq.json()
        return data;
    });

export type AddReplyToSupplierCommentConfig = {
    supplierId: string,
    threadId: string,
    reply: AddReplyBody
}
export const replyToSupplierCommentThread = createAsyncThunk<string, AddReplyToSupplierCommentConfig>(
    'replyToSupplierCommentThread',
    async (replyBody: AddReplyToSupplierCommentConfig, { dispatch, rejectWithValue }) => {
        const rq = await request2(`comments/${replyBody.threadId}/add_reply`, { method: 'post', body: JSON.stringify(replyBody.reply) });
        if (!rq.ok) {
            console.log('Adding reply to the comment thread does not succeed...');
            dispatch(sendErrorMessage(['error_message.adding_reply_failed'], 3000));
            rejectWithValue(rq as Response);
        }
        dispatch(getSupplierHistory({id: replyBody.supplierId}));
        const data = await rq.json()
        return data;
    });

export const setSupplierCustomFieldValue = createAsyncThunk<SupplierServiceDetailedSupplier, { supplierId, customFieldDefinitionId, cf: InputCustomFieldValue }>(
    'setSupplierCustomFieldValue',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'suppliers/' + params.supplierId + '/set_custom_field_value/' + params.customFieldDefinitionId;
        const rq = await request2(url, { body: JSON.stringify(params.cf), method: 'post' });
        if (!rq.ok) {
            console.log('Setting the supplier custom field value did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.setting_supplier_custom_field_value_failed'], 3000));
            catchException('setSupplierCustomFieldValue', {
                endpoint: 'suppliers/:supplierId:/set_custom_field_value/:customFieldDefinitionId',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.setting_supplier_custom_field_value_was_successful'], 3000));
        dispatch(getSupplierHistory({id: params.supplierId}));
        return await rq.json();
    });

export const setSupplierUserAsContactPerson = createAsyncThunk<SupplierServiceDetailedSupplier, { supplierId: string, userId: string, isContact: boolean }>(
    'setSupplierUserAsContactPerson',
    async (params, { dispatch, rejectWithValue }) => {
        const url = params.isContact ? 'suppliers/' + params.supplierId + '/add_members_as_contacts' : 'suppliers/' + params.supplierId + '/remove_members_as_contacts';
        const rq = await request2(url, { body: JSON.stringify({ contact_ids: [params.userId] }), method: 'put' });
        if (!rq.ok) {
            console.log('Setting the supplier contact person did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.setting_supplier_user_contact_person_failed'], 3000));
            catchException('setProductionUnitCustomFieldValue', {
                endpoint: 'suppliers/',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.setting_supplier_user_contact_person_was_successful'], 3000));
        dispatch(getSupplierHistory({id: params.supplierId}));
        return await rq.json();
    });

export const setDetailedSupplierGroups = createAsyncThunk<SupplierServiceDetailedSupplier, { supplierId: string, groups: Group[] }>(
    'setDetailedSupplierGroups',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'suppliers/' + params.supplierId + '/set_groups_on_supplier';
        const rq = await request2(url, { body: JSON.stringify({ group_ids: params.groups.map(g => g.id) }), method: 'put' });
        if (!rq.ok) {
            console.log('Setting the supplier groups did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.setting_supplier_groups_failed'], 3000));
            catchException('setDetailedSupplierGroups', {
                endpoint: 'suppliers/:supplierId/set_groups_on_supplier',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.setting_supplier_groups_succeded'], 3000));
        dispatch(getSupplierHistory({id: params.supplierId}));
        return await rq.json();
    });

export const updateDetailedSupplier = createAsyncThunk<SupplierServiceDetailedSupplier, { supplierId: string, obj: ChangeSupplierInformationBody }>(
    'updateDetailedSupplier',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'suppliers/' + params.supplierId + '/change_supplier_information';
        const rq = await request2(url, { body: JSON.stringify(params.obj), method: 'put' });
        if (!rq.ok) {
            console.log('Updating the supplier did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.updating_detailed_supplier_failed'], 3000));
            catchException('updateDetailedSupplier', {
                endpoint: 'suppliers/:supplierId',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(sendStatusMessage(['status_message.updating_detailed_supplier_succeded'], 3000));
        dispatch(getSupplierHistory({id: params.supplierId}));
        return await rq.json();
    });

export const removeDetailedSupplier = createAsyncThunk<SupplierServiceDetailedSupplier, { supplierId: string }>(
    'removeDetailedSupplier',
    async (params, { dispatch, rejectWithValue }) => {
        const url = 'suppliers/delete/' + params.supplierId;
        const rq = await request2(url, { method: 'delete' });
        if (!rq.ok) {
            console.log('Removing the supplier did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.removing_detailed_supplier_failed'], 3000));
            catchException('removeDetailedSupplier', {
                endpoint: 'suppliers/',
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        dispatch(getSuppliersList({ pagination: { limit: 20, offset: 0 }, custom_fields: null, search: null }));
        setTimeout(() => history.push('/suppliers'), 500);
        dispatch(sendStatusMessage(['status_message.removing_detailed_supplier_succeded'], 3000));
        return await rq.json();
    });

export const getSupplierCustomFieldsList = createAsyncThunk<InputCustomFieldGroups>(
    'getSupplierCustomFieldsList',
    async (_, { dispatch, rejectWithValue }) => {
        const url = 'supplier_custom_fields';
        const rq = await request2(url, {});
        if (!rq.ok) {
            console.log('Getting the supplier custom fields list did not go ok...', rq.statusText);
            dispatch(sendErrorMessage(['error_message.getting_supplier_custom_field_list_failed'], 3000));
            catchException('getSupplierCustomFieldsList', {
                endpoint: url,
                request: url,
                status: rq.status,
            }, { error: rq });
            return rejectWithValue(rq as Response)
        }
        // return scf_data;
        return await rq.json();
    });

export const suppliersSlice = createSlice({
    name: 'suppliers',
    initialState,
    reducers: {
        addGroupToDetailedSupplier: (
            state: SuppliersState,
            action: PayloadAction<{ supplierId: string, groupId: string }>) => {
            if (state.detailedSupplier && state.detailedSupplier.id == action.payload.supplierId) {
                // state.detailedSupplier.primary_group.unshift(action.payload.groupId)
            }

            /* removeGroupFromDetailedProductionUnit: (
                state: ProductionUnitsState,
                action: PayloadAction<{ productionUnitId: string, groupId: string }>) => {
                if (state.detailedProductionUnit && state.detailedProductionUnit.id == action.payload.productionUnitId) {
                    state.detailedProductionUnit.group_ids = state.detailedProductionUnit.group_ids.filter(k => k !== action.payload.groupId)
                }
            }, */
        },
        resetSupplierDetail: (state): void => { 
            state.detailedSupplier = undefined;
            state.historyEntries = undefined;
        }
    },
    extraReducers: builder => {
        builder.addCase(createLegacySupplier.pending, () => undefined),
            builder.addCase(createLegacySupplier.rejected, () => undefined),
            builder.addCase(createLegacySupplier.fulfilled, (state, action) => {
                if (action.payload && state.legacyEndpoint.data) {
                    state.legacyEndpoint.data.push(action.payload);
                }
            })

        builder.addCase(getLegacySuppliersList.pending, (state) => {
            state.legacyEndpoint.fetching += 1;
        })
        builder.addCase(getLegacySuppliersList.rejected, (state) => {
            state.legacyEndpoint.fetching -= 1;
        })
        builder.addCase(getLegacySuppliersList.fulfilled, (state, action) => {
            state.legacyEndpoint.fetching -= 1;
            /* Some requests will return nothing, because they will be debounced */
            if (action.payload) {
                state.legacyEndpoint.data = action.payload;
                state.legacyEndpoint.lastRequest = Date.now();
            }
        })

        builder.addCase(getSuppliersList.fulfilled, (state, action) => {
            if (action.meta.arg.pagination.offset > 0) {
                state.suppliers.suppliers = state.suppliers.suppliers.concat(action.payload.suppliers);
                state.suppliers.pagination = action.payload.pagination;
            } else {
                state.suppliers = action.payload;
            }
            state.status = SupplierListStatusEnum.Complete;
        });
        builder.addCase(getSuppliersList.pending, (state) => { state.status = SupplierListStatusEnum.Fetching; });
        builder.addCase(getSuppliersList.rejected, (state) => { state.error = true; });
        builder.addCase(getCorrectiveActionsOnDashboardSupplier.fulfilled, (state, action) => {
            state.correctiveActions = action.payload;
            state.isFetchingCorrectiveActions = false
        });
        builder.addCase(getCorrectiveActionsOnDashboardSupplier.pending, (state) => {
            state.correctiveActions = []
            state.isFetchingCorrectiveActions = true
        });
        builder.addCase(getCorrectiveActionsOnDashboardSupplier.rejected, (state) => {
            state.isFetchingCorrectiveActions = false
        });
        builder.addCase(getCorrectiveActionsOnDashboardProductionUnit.fulfilled, (state, action) => {
            state.correctiveActions = action.payload;
            state.isFetchingCorrectiveActions = false
        });
        builder.addCase(getCorrectiveActionsOnDashboardProductionUnit.pending, (state) => {
            state.correctiveActions = []
            state.isFetchingCorrectiveActions = true
        });
        builder.addCase(getCorrectiveActionsOnDashboardProductionUnit.rejected, (state) => {
            state.isFetchingCorrectiveActions = false
        });
        builder.addCase(getDetailedSupplier.rejected, (state) => {
            state.detailedSupplierLoading = false;
        });
        builder.addCase(getDetailedSupplier.fulfilled, (state, action) => {
            state.detailedSupplier = action.payload;
            state.detailedSupplierLoading = false;
        });
        builder.addCase(getDetailedSupplier.pending, (state) => {
            state.detailedSupplierLoading = true;
        });

        builder.addCase(setSupplierCustomFieldValue.fulfilled, (state, action) => {
            state.detailedSupplier = action.payload;
        });

        builder.addCase(getSupplierHistory.pending, (state) => {
            state.loadingHistory = true;
        });
        builder.addCase(getSupplierHistory.rejected, (state) => {
            state.loadingHistory = false;
        });
        builder.addCase(getSupplierHistory.fulfilled, (state, action) => {
            state.historyEntries = action.payload;
            state.loadingHistory = false;
        });
        builder.addCase(setSupplierUserAsContactPerson.fulfilled, (state, action) => {
            state.detailedSupplier = action.payload;
        });
        builder.addCase(updateDetailedSupplier.fulfilled, (state, action) => {
            state.detailedSupplier = action.payload;
        });
        builder.addCase(getSupplierCustomFieldsList.fulfilled, (state, action) => {
            state.customFieldsList = action.payload;
        });
        builder.addCase(setDetailedSupplierGroups.fulfilled, (state, action) => {
            state.detailedSupplier = action.payload;
        });

        builder.addCase(setDetailedSupplierGroups.pending, (state, action) => {
            /* Quick bandaid. I backup the groups, incase the request fails. */
            (state as any)['groups_backup'] = state.detailedSupplier.groups
            state.detailedSupplier.groups = action.meta.arg.groups || []
        })

        builder.addCase(setDetailedSupplierGroups.rejected, (state) => {
            /* If the request fails, restore from backup, if there is any */
            if ((state as any)['groups_backup']) {
                const backup = (state as any)['groups_backup']
                state.detailedSupplier.groups = backup as Group[]
            }
        })

    },
});

export const getSuppliersListSelector = createSelector(
    [(state: AppState): { data: PaginatedManagedSuppliers, status: SupplierListStatusEnum, error: boolean } => {
        return {
            data: state.app.suppliers2 && state.app.suppliers2.suppliers,
            status: state.app.suppliers2.status,
            error: state.app.suppliers2.error,
        };
    }],
    (data) => data,
);

export const getCorrectiveActionsSelector = createSelector(
    [(state: AppState): CorrectiveAction[] => state.app.suppliers2.correctiveActions],
    (data) => data,
);

export const getIsFetchingCorrectiveActionsSelector = createSelector(
    [(state: AppState): boolean => state.app.suppliers2.isFetchingCorrectiveActions],
    (data) => data,
);

export const getLegacySuppliersListLoadingSelector = createSelector(
    [(state: AppState): LegacySupplierListState => state.app.suppliers2.legacyEndpoint],
    data => data.fetching > 0
)

export const getLegacySuppliersListSelector = createSelector(
    [(state: AppState): DetailedSupplier[] => state.app.suppliers2.legacyEndpoint.data],
    data => data
)
export const getDetailedSupplierSelector = createSelector(
    [(state: AppState): SupplierServiceDetailedSupplier => state.app.suppliers2.detailedSupplier],
    (data) => data,
);
export const getDetailedSupplierLoadingSelector = createSelector(
    [(state: AppState): boolean => state.app.suppliers2.detailedSupplierLoading],
    (loading) => loading,
);

export const getSupplierHistoryLoadingSelector = createSelector(
    [(state: AppState): boolean => state.app.suppliers2.loadingHistory],
    (loading) => loading,
);

export const getSupplierCustomFieldsListSelector = createSelector(
    [(state: AppState): InputCustomFieldGroups => state.app.suppliers2.customFieldsList],
    (customFieldsList) => customFieldsList,
);
export const hasSupplierCustomFieldsSelector = createSelector(
    [(state: AppState): boolean => state.app.suppliers2.customFieldsList && state.app.suppliers2.customFieldsList.groups.length > 0 || false],
    (hasCustomFieldsList) => hasCustomFieldsList,
);
export const getSupplierCustomFieldsListGroupEntriesByIdSelector = createSelector(
    [(state: AppState): byId<InputCustomFieldDefinition> => {
        const ret = {};
        const customFieldsList = state.app.suppliers2.customFieldsList;
        customFieldsList && customFieldsList.groups && customFieldsList.groups.map((g) => Object.fromEntries((g.custom_field_definitions).map((gh) => {
            ret[gh.id] = gh;
            return [gh.id, gh];
        })))
        return ret;
    }],
    (customFieldsList) => customFieldsList,
);

export const getSupplierHistorySelector = createSelector(
    [(state: AppState): SupplierServiceHistoryEntry[] => state.app.suppliers2.historyEntries],
    (history) => history
)

export const { resetSupplierDetail } = suppliersSlice.actions;

export default suppliersSlice.reducer;