import React from 'react';
import { useIntl } from 'react-intl';
import KeyValuePair from '../../../base/ui/components/keyValuePair/KeyValuePair';
import { getStringsSeparatedBy, twMerge } from '../../../base/utils';
import { OrganizedProductResponsibilitySupplier } from '../../../compliance_api/models/OrganizedProductResponsibilitySupplier';

type Props = {
    className?: string;
    suppliers?: OrganizedProductResponsibilitySupplier[];
};

const ComplianceProductSuppliers = (props: Props): React.ReactElement => {
    const { className, suppliers } = props;
    const intl = useIntl();
    const andLabel = intl.formatMessage({ id: 'globals.and_lower_case' });
    const suppliersText = getStringsSeparatedBy(suppliers.map((sup => sup.supplier_name)), ',', andLabel);
    
    return <div className={twMerge('', className)}>
        <KeyValuePair
            keyItem={{
                name: intl.formatMessage({ id: 'compliance.product_detail.suppliers' }),
                icon: 'user_supplier'
            }}
            value={{ value: suppliersText || '-' }} />
    </div>
}
export default ComplianceProductSuppliers;
