import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { ButtonTypes } from '../../../base/ui/components/buttons/Button';
import { Field } from '../../../base/ui/components/fields/Field';
import Modal, { ActionTypes } from '../../../base/ui/components/modals/Modal';
import { isUndefinedOrNullOrEmptyString } from '../../../base/utils';
import { useAppDispatch } from '../../../store';

type Props = {
    className?: string;
    productName?: string;
    productNumber?: string;
    handleProductUpdate(string, any): void;
    save(): void;
    cancelCreate(): void;
};

const ComplianceCreateProductModal = (props: Props): React.ReactElement => {
    const { className, handleProductUpdate, save, cancelCreate } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [productNumber, setProductNumber] = useState(props.productNumber);
    const [productName, setProductName] = useState(props.productName);
    useEffect(() => {
        //
    }, []);
    const createModal = <Modal
    onOpenChange={(c) => !c && cancelCreate()}
    actionItems={[
            { type: ActionTypes.Action, buttonType: ButtonTypes.Primary, text: 'Create product', event: save, disabled: isUndefinedOrNullOrEmptyString(productName) || isUndefinedOrNullOrEmptyString(productNumber) },
            { type: ActionTypes.CancelAction, buttonType: ButtonTypes.Plain, text: 'Cancel', event: cancelCreate },
        ]}
        isOpen={true}
        width={500}
        header='New product'
    >
        <div className='flex flex-col p-6 gap-y-4'>
            <Field
                isRequired={true}
                value={productName}
                label={{ text: 'Product name' }}
                onChange={setProductName}
                onBlur={() => handleProductUpdate('product_name', productName)}
            />
            <Field
                isRequired={true}
                label={{ text: 'Product number' }}
                value={productNumber}
                onChange={setProductNumber}
                onBlur={() => handleProductUpdate('product_number', productNumber)}
            />
        </div>
    </Modal >

    return createModal;
}
export default ComplianceCreateProductModal;
