/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DetailedDocumentViewAppliesTo } from './DetailedDocumentViewAppliesTo';
import {
    DetailedDocumentViewAppliesToFromJSON,
    DetailedDocumentViewAppliesToFromJSONTyped,
    DetailedDocumentViewAppliesToToJSON,
} from './DetailedDocumentViewAppliesTo';
import type { DetailedDocumentViewTimeframe } from './DetailedDocumentViewTimeframe';
import {
    DetailedDocumentViewTimeframeFromJSON,
    DetailedDocumentViewTimeframeFromJSONTyped,
    DetailedDocumentViewTimeframeToJSON,
} from './DetailedDocumentViewTimeframe';
import type { Responsible } from './Responsible';
import {
    ResponsibleFromJSON,
    ResponsibleFromJSONTyped,
    ResponsibleToJSON,
} from './Responsible';

/**
 * Details for a document. Intended to be shown in the document details view.
 * @export
 * @interface DetailedDocumentView
 */
export interface DetailedDocumentView {
    /**
     * 
     * @type {DetailedDocumentViewAppliesTo}
     * @memberof DetailedDocumentView
     */
    applies_to: DetailedDocumentViewAppliesTo;
    /**
     * 
     * @type {string}
     * @memberof DetailedDocumentView
     */
    approval: DetailedDocumentViewApprovalEnum;
    /**
     * 
     * @type {Responsible}
     * @memberof DetailedDocumentView
     */
    approve_responsible: Responsible;
    /**
     * 
     * @type {string}
     * @memberof DetailedDocumentView
     */
    document_specification_id: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDocumentView
     */
    document_specification_name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedDocumentView
     */
    files: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DetailedDocumentView
     */
    permissions: Array<string>;
    /**
     * If the document is 'rejected', this field can contains the reason that the rejecting user provided. Is 'null' when the document is not rejected.
     * @type {string}
     * @memberof DetailedDocumentView
     */
    rejection_reason: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedDocumentView
     */
    requirement_specification_id: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedDocumentView
     */
    requirement_specification_name: string;
    /**
     * 
     * @type {DetailedDocumentViewTimeframe}
     * @memberof DetailedDocumentView
     */
    timeframe: DetailedDocumentViewTimeframe;
    /**
     * 
     * @type {Responsible}
     * @memberof DetailedDocumentView
     */
    upload_responsible: Responsible;
}


/**
 * @export
 */
export const DetailedDocumentViewApprovalEnum = {
    NotReadyForApproval: 'not_ready_for_approval',
    ReadyForApproval: 'ready_for_approval',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;
export type DetailedDocumentViewApprovalEnum = typeof DetailedDocumentViewApprovalEnum[keyof typeof DetailedDocumentViewApprovalEnum];


/**
 * Check if a given object implements the DetailedDocumentView interface.
 */
export function instanceOfDetailedDocumentView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "applies_to" in value;
    isInstance = isInstance && "approval" in value;
    isInstance = isInstance && "approve_responsible" in value;
    isInstance = isInstance && "document_specification_id" in value;
    isInstance = isInstance && "document_specification_name" in value;
    isInstance = isInstance && "files" in value;
    isInstance = isInstance && "permissions" in value;
    isInstance = isInstance && "rejection_reason" in value;
    isInstance = isInstance && "requirement_specification_id" in value;
    isInstance = isInstance && "requirement_specification_name" in value;
    isInstance = isInstance && "timeframe" in value;
    isInstance = isInstance && "upload_responsible" in value;

    return isInstance;
}

export function DetailedDocumentViewFromJSON(json: any): DetailedDocumentView {
    return DetailedDocumentViewFromJSONTyped(json, false);
}

export function DetailedDocumentViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedDocumentView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applies_to': DetailedDocumentViewAppliesToFromJSON(json['applies_to']),
        'approval': json['approval'],
        'approve_responsible': ResponsibleFromJSON(json['approve_responsible']),
        'document_specification_id': json['document_specification_id'],
        'document_specification_name': json['document_specification_name'],
        'files': json['files'],
        'permissions': json['permissions'],
        'rejection_reason': json['rejection_reason'],
        'requirement_specification_id': json['requirement_specification_id'],
        'requirement_specification_name': json['requirement_specification_name'],
        'timeframe': DetailedDocumentViewTimeframeFromJSON(json['timeframe']),
        'upload_responsible': ResponsibleFromJSON(json['upload_responsible']),
    };
}

export function DetailedDocumentViewToJSON(value?: DetailedDocumentView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applies_to': DetailedDocumentViewAppliesToToJSON(value.applies_to),
        'approval': value.approval,
        'approve_responsible': ResponsibleToJSON(value.approve_responsible),
        'document_specification_id': value.document_specification_id,
        'document_specification_name': value.document_specification_name,
        'files': value.files,
        'permissions': value.permissions,
        'rejection_reason': value.rejection_reason,
        'requirement_specification_id': value.requirement_specification_id,
        'requirement_specification_name': value.requirement_specification_name,
        'timeframe': DetailedDocumentViewTimeframeToJSON(value.timeframe),
        'upload_responsible': ResponsibleToJSON(value.upload_responsible),
    };
}

