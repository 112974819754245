/* tslint:disable */
/* eslint-disable */
/**
 * Qarma Inspect Mobile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrganizedProductRequiredDocumentRequiredAt } from './OrganizedProductRequiredDocumentRequiredAt';
import {
    OrganizedProductRequiredDocumentRequiredAtFromJSON,
    OrganizedProductRequiredDocumentRequiredAtFromJSONTyped,
    OrganizedProductRequiredDocumentRequiredAtToJSON,
} from './OrganizedProductRequiredDocumentRequiredAt';
import type { OrganizedProductRequiredDocumentRequiredOf } from './OrganizedProductRequiredDocumentRequiredOf';
import {
    OrganizedProductRequiredDocumentRequiredOfFromJSON,
    OrganizedProductRequiredDocumentRequiredOfFromJSONTyped,
    OrganizedProductRequiredDocumentRequiredOfToJSON,
} from './OrganizedProductRequiredDocumentRequiredOf';
import type { OrganizedProductRequiredDocumentResponsible } from './OrganizedProductRequiredDocumentResponsible';
import {
    OrganizedProductRequiredDocumentResponsibleFromJSON,
    OrganizedProductRequiredDocumentResponsibleFromJSONTyped,
    OrganizedProductRequiredDocumentResponsibleToJSON,
} from './OrganizedProductRequiredDocumentResponsible';
import type { TextWithTranslation } from './TextWithTranslation';
import {
    TextWithTranslationFromJSON,
    TextWithTranslationFromJSONTyped,
    TextWithTranslationToJSON,
} from './TextWithTranslation';

/**
 * Schema for a required document on a requirement.
 * @export
 * @interface OrganizedProductRequiredDocument
 */
export interface OrganizedProductRequiredDocument {
    /**
     * 
     * @type {string}
     * @memberof OrganizedProductRequiredDocument
     */
    deadline_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizedProductRequiredDocument
     */
    document_has_files: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizedProductRequiredDocument
     */
    document_id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizedProductRequiredDocument
     */
    document_template_id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizedProductRequiredDocument
     */
    document_type: OrganizedProductRequiredDocumentDocumentTypeEnum;
    /**
     * 
     * @type {TextWithTranslation}
     * @memberof OrganizedProductRequiredDocument
     */
    name: TextWithTranslation;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganizedProductRequiredDocument
     */
    permissions: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrganizedProductRequiredDocument
     */
    product_id: string;
    /**
     * 
     * @type {OrganizedProductRequiredDocumentRequiredAt}
     * @memberof OrganizedProductRequiredDocument
     */
    required_at: OrganizedProductRequiredDocumentRequiredAt;
    /**
     * 
     * @type {OrganizedProductRequiredDocumentRequiredOf}
     * @memberof OrganizedProductRequiredDocument
     */
    required_of: OrganizedProductRequiredDocumentRequiredOf;
    /**
     * 
     * @type {string}
     * @memberof OrganizedProductRequiredDocument
     */
    requirement_id: string;
    /**
     * 
     * @type {OrganizedProductRequiredDocumentResponsible}
     * @memberof OrganizedProductRequiredDocument
     */
    responsible: OrganizedProductRequiredDocumentResponsible;
    /**
     * 
     * @type {string}
     * @memberof OrganizedProductRequiredDocument
     */
    state: OrganizedProductRequiredDocumentStateEnum;
}


/**
 * @export
 */
export const OrganizedProductRequiredDocumentDocumentTypeEnum = {
    Ignored: 'ignored',
    Document: 'document'
} as const;
export type OrganizedProductRequiredDocumentDocumentTypeEnum = typeof OrganizedProductRequiredDocumentDocumentTypeEnum[keyof typeof OrganizedProductRequiredDocumentDocumentTypeEnum];

/**
 * @export
 */
export const OrganizedProductRequiredDocumentStateEnum = {
    Approved: 'approved',
    Rejected: 'rejected',
    ReadyForApproval: 'ready_for_approval',
    NotReadyForApproval: 'not_ready_for_approval'
} as const;
export type OrganizedProductRequiredDocumentStateEnum = typeof OrganizedProductRequiredDocumentStateEnum[keyof typeof OrganizedProductRequiredDocumentStateEnum];


/**
 * Check if a given object implements the OrganizedProductRequiredDocument interface.
 */
export function instanceOfOrganizedProductRequiredDocument(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deadline_id" in value;
    isInstance = isInstance && "document_has_files" in value;
    isInstance = isInstance && "document_id" in value;
    isInstance = isInstance && "document_template_id" in value;
    isInstance = isInstance && "document_type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "permissions" in value;
    isInstance = isInstance && "product_id" in value;
    isInstance = isInstance && "required_at" in value;
    isInstance = isInstance && "required_of" in value;
    isInstance = isInstance && "requirement_id" in value;
    isInstance = isInstance && "responsible" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function OrganizedProductRequiredDocumentFromJSON(json: any): OrganizedProductRequiredDocument {
    return OrganizedProductRequiredDocumentFromJSONTyped(json, false);
}

export function OrganizedProductRequiredDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizedProductRequiredDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deadline_id': json['deadline_id'],
        'document_has_files': json['document_has_files'],
        'document_id': json['document_id'],
        'document_template_id': json['document_template_id'],
        'document_type': json['document_type'],
        'name': TextWithTranslationFromJSON(json['name']),
        'permissions': json['permissions'],
        'product_id': json['product_id'],
        'required_at': OrganizedProductRequiredDocumentRequiredAtFromJSON(json['required_at']),
        'required_of': OrganizedProductRequiredDocumentRequiredOfFromJSON(json['required_of']),
        'requirement_id': json['requirement_id'],
        'responsible': OrganizedProductRequiredDocumentResponsibleFromJSON(json['responsible']),
        'state': json['state'],
    };
}

export function OrganizedProductRequiredDocumentToJSON(value?: OrganizedProductRequiredDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deadline_id': value.deadline_id,
        'document_has_files': value.document_has_files,
        'document_id': value.document_id,
        'document_template_id': value.document_template_id,
        'document_type': value.document_type,
        'name': TextWithTranslationToJSON(value.name),
        'permissions': value.permissions,
        'product_id': value.product_id,
        'required_at': OrganizedProductRequiredDocumentRequiredAtToJSON(value.required_at),
        'required_of': OrganizedProductRequiredDocumentRequiredOfToJSON(value.required_of),
        'requirement_id': value.requirement_id,
        'responsible': OrganizedProductRequiredDocumentResponsibleToJSON(value.responsible),
        'state': value.state,
    };
}

