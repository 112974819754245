import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BasicUser } from '../../../backend_api/models';
import { ComponentOrStringType } from '../../../base/types';
import Popup from '../../../base/ui/components/popup/Popup';
import { getUserNameText } from './Widgets';

type Props = {
    className?: string;
    user: BasicUser;
    trigger?: ComponentOrStringType;
};

const UserPopup = (props: Props): React.ReactElement => {
    const { className, user, trigger } = props;
    const intl = useIntl();
    const [state, setState] = useState();
    useEffect(() => {
        //
    }, []);
    return <Popup
        on='hover'
        trigger={trigger || getUserNameText(user)}
    >
        <div>{getUserNameText(user)}</div>
        <div className='text-secondary text-sm'>{user?.email}</div>
    </Popup>
}
export default UserPopup;
