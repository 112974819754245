import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { CreatedByImporter } from '../../../backend_api_2';

type Props = {
    event: CreatedByImporter
}

const CreatedByImporterEvent = (props: Props) => {
    return <Fragment>
        <label className='mb-1'><FormattedMessage id={'corrective_actions.events.created_by_importer'} /></label>
    </Fragment>
}

export default CreatedByImporterEvent;