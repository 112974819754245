import qs from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Lightbox, { Slide, SlideImage, SlideVideo, useLightboxState } from 'yet-another-react-lightbox';
import { Download, Video, Zoom } from 'yet-another-react-lightbox/plugins';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/styles.css';
import { useAppDispatch } from '../../store';
import { getShowLightBoxSelector, showLightBox } from '../baseSlice';
import { Breakpoints } from '../config';
import { ComponentOrStringType } from '../types';
import Icon from '../ui/components/icons/Icon';
import { getLocationEntry, twMerge, useMaxWidthBreak } from '../utils';
import './lb.css';

type Props = {
    className?: string;
    content?: ComponentOrStringType;
    open?: boolean;
    index?: number;
    images: (SlideImage | SlideVideo)[];
    metaItems?: ComponentOrStringType[];
    currentUrl?: string;
    close?();

};

const LightBox = (props: Props): React.ReactElement => {
    const { className, content, close, metaItems, images, currentUrl } = props;
    const [isDragging, setIsDragging] = useState(false);
    const [isFull, setFull] = useState(false);
    const [index, setIndex] = useState(props.index || 0);
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const locationParsed = qs.parse(location.search as string);
    const history = useHistory();
    const openLB = useSelector(getShowLightBoxSelector);
    const dispatch = useAppDispatch();
    const isMobile = useMaxWidthBreak(Breakpoints.SM);
    const ref = React.useRef(null);

    const setIsFull = (full: boolean) => {
        setFull(full);
        const toolbar = document.getElementsByClassName('yarl__toolbar')[0] as HTMLElement;
        const navPrev = document.getElementsByClassName('yarl__navigation_prev')[0] as HTMLElement;
        const navNext = document.getElementsByClassName('yarl__navigation_next')[0] as HTMLElement;
        toolbar.style.display = full ? 'none' : '';
        navNext.style.display = full ? 'none' : '';
        navPrev.style.display = full ? 'none' : '';

    }
    const [rotationAngle, setRotationAngle] = useState(0);
    const handleRotateLeft = () => {
        setRotationAngle((prevAngle) => (prevAngle <= 0 ? 270 : prevAngle - 90));
    };
    const handleRotateRight = () => {
        setRotationAngle((prevAngle) => (prevAngle >= 270 ? 0 : prevAngle + 90));
    };

    const getMeta = () => {

        const { currentIndex, currentSlide } = useLightboxState();
        const meta = metaItems && metaItems[currentIndex];
        return meta ? <div className={twMerge(
            'bg-white absolute rounded sm:top-1/10 bottom-0 sm:h-3/4 left-0 sm:left-3/4 w-full sm:w-96 transition-all ease-out duration-200 overflow-auto rounded-b-none', isFull ? 'top-[0%]' : 'top-[90%]')} onClick={() => isMobile ? setIsFull(!isFull) : null}>
            {meta}
        </div> : <div>...</div>
    }

    const getCurrentIndex = () => {
        const { currentIndex } = useLightboxState();
        return currentIndex + 1;
    }

    const handleWheel = (e) => {
        e.stopPropagation();
    }
    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    useEffect(() => {
        if (!open) {
            setOpen(openLB.show)
            if (openLB.url) {
                let index = -1;
                images.map((image, i) => {
                    if (image.type === 'image') {
                        const imgSrc = image.src;
                        if (imgSrc === openLB.url) {
                            index = i
                        }
                    }
                    if (image.type === 'video') {
                        const imgSrc = image.sources[0].src;
                        if (imgSrc === openLB.url) {
                            index = i
                        }
                    }
                })
                setIndex(index);
            }
        }
        if (open && !openLB.show) {
            setOpen(false);
        }
    }, [openLB]);

    useEffect(() => {
        let imageURL: string;
        if (location.search.includes('imageURL')) {
            imageURL = encodeURI(getLocationEntry(location, 'imageURL')[0]);
            let index = -1;
            images.map((image, i) => {
                if (image.type === 'image') {
                    const imgSrc = image.src;
                    if (imgSrc === imageURL) {
                        index = i
                    }
                }
                if (image.type === 'video') {
                    const imgSrc = image.sources[0].src;
                    if (imgSrc === imageURL) {
                        index = i
                    }
                }
            })
            dispatch(showLightBox({ show: true, index, url: imageURL }));
        } else {
            dispatch(showLightBox({ show: false }));
        }
    }, [location.search]);
    const lb = <Lightbox
        controller={{ ref }}
        className={'rotated' + rotationAngle}
        toolbar={{
            buttons: [
                <button
                    key='rotate-left'
                    type='button'
                    className='yarl__button'
                    onClick={handleRotateLeft}
                >
                    <Icon name='microinteraction_rotate-left' className='text-white text-2xl' />
                </button>,
                <button
                    key='rotate-right'
                    type='button'
                    className='yarl__button'
                    onClick={handleRotateRight}
                >
                    <Icon name='microinteraction_rotate-right' className='text-white text-2xl' />
                </button>,
                'close',
            ]
        }}
        on={{
            view: ({ index: currentIndex }) => {
                setIndex(currentIndex);
                setRotationAngle(0);
            }
        }}
        slides={images as Slide[]}
        plugins={[Zoom, Video, Download]}
        index={index}
        open={open}
        close={() => {
            setOpen(false);
            // dispatch(showLightBox({ show: false, index: 0 }));
            history.replace(location.pathname);
        }}
        styles={{ captionsTitle: { paddingTop: '10px' }, captionsTitleContainer: { justifyContent: 'center', display: 'flex' } }}
        zoom={{ zoomInMultiplier: 2, maxZoomPixelRatio: 10, scrollToZoom: true }}
        animation={{ navigation: 0, fade: 0, swipe: 0, easing: { fade: 'none', swipe: 'none', navigation: 'none' } }}
        render={{
            slideContainer: ({ slide, children }) => (

                <div
                    className={twMerge('flex w-full z-0  top-0 h-full left-0 justify-center items-center', isDragging ? 'absolute' : 'absolute animate-none')}
                >
                    <div className='absolute top-0 h-16 opacity-50 bg-black w-full z-20'>&nbsp;b</div>
                    <div className='absolute top-0 text-white items-center h-16 opacity-100 bg-transparent w-full justify-start sm:justify-center px-4 z-20 flex space-x-1'><div className=''>{slide.title || 'Image'}</div><div>{(getCurrentIndex())}/{images.length}</div></div>
                    <div style={{backgroundColor: "hsl(0, 0%, 90%)"}}>{children}</div>
                </div>
            ),
            slideFooter: ({ slide }) => {

                const commentsRef = useRef(null);
                useEffect(() => {
                    // The wheel event is passive in React and cannot be handled with the stopPropagation function, but needs an explicit eventhandler
                    commentsRef.current.addEventListener('wheel', handleWheel, { passive: false });
                    return () => commentsRef && commentsRef.current && commentsRef.current.removeEventListener('wheel', handleWheel, { passive: false });
                });
                return <div
                    ref={commentsRef}
                    onPointerMove={stopPropagation}
                    onKeyDown={stopPropagation}
                >
                    <Draggable
                        disabled={isMobile}
                        onDrag={() => {
                            if (!isDragging) {
                                setIsDragging(true);
                            }
                        }}
                        onStop={(() => {
                            setIsDragging(false);
                        })}>
                        {getMeta()}
                    </Draggable>
                </div>
            },
        }}
    />
    return lb;
}
export default LightBox;

