import classnames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { InputProps, Popup } from 'semantic-ui-react';
import Button from '../../../../base/components/basic/Button';
import Icon from '../../../../base/components/basic/Icon';
import { twMerge } from '../../../../base/utils';
import { useAppDispatch } from '../../../../store';
import HasReadonlyAccess from '../../../globals/components/access/HasReadonlyAccess';
import { calculateSampleSize, getCurrentSamplingProtocolSelector, setSamplingProtocolOnInspections } from '../../../samplingProtocols/slices/samplingProtocolsSlice';
import { isInspectionPartOfCombinedInspection } from '../../Utils';
import { handleAction } from '../../actions/editInspectionActions';
import { Inspection } from '../../types';
import OriginalQtyInfo from '../report/OriginalQtyInfo';
import { widthsFoo } from './InfoLine';
import SamplingProtocolSelectorDropdown from './SamplingProtocolSelectorDropdown';
import QInput from './formelements/QInput';

type OwnProps = {
    inspection: Inspection;
    disabled: boolean;
    showSampleQty: boolean;
    sampleQtyDisabled?: boolean;
    hasInspectionTypes: boolean;
    isCombined: boolean;
    value?: number;
    totalQtyClassName?: string;
    sampleQtyClassName?: string;
    handleBlur(event: React.SyntheticEvent, item: InputProps, inspectionId?: string): void;
    handleFocus(event: React.SyntheticEvent, item: InputProps, inspectionId?: string): void;
    handleAction: typeof handleAction;
}

type QuantityProps = OwnProps;

const Quantity = (props: QuantityProps): React.ReactElement => {
    const ssInput = useRef<HTMLInputElement>();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const orderSamplingProtocol = useSelector(getCurrentSamplingProtocolSelector);
    const { value, inspection, disabled, isCombined, hasInspectionTypes, totalQtyClassName, sampleQtyClassName } = props;
    const sampleQtyDisabled = disabled && props.sampleQtyDisabled;
    const widths = widthsFoo(hasInspectionTypes);
    const inspectionId = props.inspection.inspection_id;
    const lockIcon = inspection.bypass_sampling_protocol ? 'lock_open' : 'lock';
    const hasOriginalQtyInfo = inspection.involved_in_split_shipment;
    const labelObj = hasOriginalQtyInfo ? <OriginalQtyInfo inspection={props.inspection} type='editOrder' /> : null;
    const labelPos = hasOriginalQtyInfo ? 'right' : null;
    const qtyLabel: string = intl.formatMessage({ id: 'inspections.qty' });
    const spDiffer = (inspection.sampling_protocol && orderSamplingProtocol) && (inspection.sampling_protocol.id !== orderSamplingProtocol.id);
    const isPOCI = isInspectionPartOfCombinedInspection(inspection)
    return (<Fragment>
        <div className={classnames(totalQtyClassName ? totalQtyClassName : widths.totalQty[isCombined ? 'c' : 't'])}>
            <HasReadonlyAccess type='inspection' actionId='total_quantity' inspection={inspection}>
                <QInput
                    type='number'
                    name='total_quantity'
                    placeholder={qtyLabel}
                    value={value || inspection.total_quantity}
                    handleBlur={(evt, data): void => {
                        disabled ? null :
                            props.handleBlur(undefined, { name: 'total_quantity', value: data.value || '' }, inspectionId);
                        if (!inspection.bypass_sampling_protocol && inspection.sampling_protocol_id !== null && (!inspection.total_quantity || (data.value !== inspection.total_quantity))) {
                            dispatch(calculateSampleSize({ inspectionId, protocolId: inspection.sampling_protocol_id, totalQty: data.value || '' }));
                        }
                    }}
                    handleFocus={(evt, data): void => disabled ? null : props.handleFocus(evt, data, inspectionId)}
                    readOnly={false}
                    className={disabled ? 'quantity readonly' : 'quantity'}
                    label={labelObj}
                    labelPosition={labelPos}
                    key={'total_quantity_' + inspectionId}
                    min={0}
                    precision={0}
                />
            </HasReadonlyAccess>
        </div>
        {props.showSampleQty &&
            <div className={classnames(sampleQtyClassName ? sampleQtyClassName : widths.sampleQty.t)}>
                {isPOCI && <Popup hoverable position='top center' trigger={<div className='p-2 text-sm items-center justify-center flex text-secondary cursor-pointer w-full'><span className='border-dashed border-b '><FormattedMessage id='inspections.no_sample_qty_on_edit_combined_page_na' /></span></div>}>
                    <FormattedMessage id='inspections.no_sample_qty_on_edit_combined_page_text' values={{ link: <Link className='link noUnderline' to={'/inspections/edit_inspection/' + inspection.master_inspection.order_id}>{intl.formatMessage({ id: 'inspections.no_sample_qty_on_edit_combined_page_link' })} </Link> }} />
                </Popup>}
                {!isPOCI && <div>
                    <HasReadonlyAccess type='inspection' actionId='bypass_sampling_protocol' inspection={inspection}>
                        <Popup
                            closeOnPortalMouseLeave
                            closeOnDocumentClick
                            closeOnTriggerMouseLeave
                            hoverable={false}
                            trigger={<span>
                                <Button style={{ lineHeight: 1.25 }} tabIndex={-1}
                                    name='bypass_sampling_protocol2'
                                    className={twMerge('rounded-r-none pl-2 pr-1 mr-0 py-3 border border-input bg-white md:px-1 lg:px-3', spDiffer ? 'bg-attention' : 'bg-white')}
                                    disabled={sampleQtyDisabled}
                                >
                                    <Icon className='text-gray-500' name={lockIcon} />
                                </Button>
                            </span>}>
                            <h5><FormattedMessage id='inspections.sampling_protocol' /></h5>
                            {spDiffer && <div className='flex text-sm items-center'><FormattedMessage id='inspections.inspection_sampling_protocol_differs_from_order_sampling_protocol' /></div>}
                            <SamplingProtocolSelectorDropdown
                                disabled={sampleQtyDisabled}
                                value={inspection.bypass_sampling_protocol ? 'bypass' : inspection.sampling_protocol_id && inspection.sampling_protocol_id || inspection.sampling_protocol && inspection.sampling_protocol.id}
                                handleChange={(e, item): void => {
                                    const sp = item.value === 'bypass' ? null : item.value;
                                    const bypass = item.value === 'bypass';
                                    const m = [{
                                        inspection_id: inspection.inspection_id,
                                        sampling_protocol_id: sp,
                                        bypass_sampling_protocol: bypass,
                                    }];
                                    dispatch(setSamplingProtocolOnInspections({ orderId: inspection.order_id, inspectionsMap: m }));
                                    if (bypass) {
                                        ssInput.current.disabled = false;
                                        ssInput.current.readOnly = false;
                                        setTimeout(() => {
                                            ssInput.current.focus();

                                        }, 50)
                                    }
                                }}
                            />
                        </Popup>
                    </HasReadonlyAccess>
                </div>}
                {!isPOCI && <div>
                    <HasReadonlyAccess type='inspection' actionId='sample_quantity' inspection={inspection}>
                        <QInput
                            type='number'
                            readOnly={sampleQtyDisabled || !inspection.bypass_sampling_protocol}
                            className={classnames('sampleqty border-l-0 rounded-l-none', disabled || !props.inspection.bypass_sampling_protocol ? 'readonly' : null)}
                            name='sample_quantity'
                            value={props.inspection.sample_quantity}
                            handleBlur={(evt, data): void => {
                                sampleQtyDisabled ? null : props.handleBlur(undefined, data, inspectionId);
                            }}
                            handleFocus={(evt, data): void => disabled ? null : props.handleFocus(evt, data, inspectionId)}
                            key={'sample_quantity_' + inspectionId}
                            abs={true}
                            precision={0}
                            min={0}
                            inputRef={ssInput}
                            disabled={isCombined}
                        />
                    </HasReadonlyAccess>
                </div>}
            </div>}
    </Fragment>);
}

export default Quantity;
