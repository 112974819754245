import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Button, Comment, Loader } from "semantic-ui-react";
import { Breakpoints } from "../../../base/config";
import { AppState, byId } from "../../../base/types";
import { useMaxWidthBreak } from "../../../base/utils";
import { useAppDispatch } from "../../../store";
import HasFeatureAccess from "../../globals/components/access/HasFeatureAccess";
import { Inspection } from "../../inspections/types";
import { getUserIdSelector } from "../../users/selectors";
import {
    getComments,
    sendNotificationForUnsentComments,
    showTranslatedCommentsAsked,
    storeShowTranslateOption as storeShowTranslateOptionAction,
} from "../actions";
import {
    getCommentsByTypeSelector,
    getCommentsFetchingSelector,
    getComments as getCommentsSelector,
    showTranslatedCommentsAskedSelector,
    showTranslatedCommentsSelector,
} from "../selectors";
import { Comment as CommentType, CommentTypes } from "../types";
import QComment from "./QComment";
import WriteComment from "./WriteComment";

type CommentsProps = {
    type?: CommentTypes;
    typeId?: string;
    inspectionId?: string;
    imageUrl?: string;
    showCollapsed?: boolean;
    hideZero?: boolean;
    inspection: Inspection;
};

const Comments2 = (props: CommentsProps): React.ReactElement => {
    const {
        type = "inspection",
        typeId = props.inspectionId,
        inspectionId,
        imageUrl,
        hideZero = false,
    } = props;
    const isInspection = type === "inspection";
    const intl = useIntl();
    const isMobile = useMaxWidthBreak(Breakpoints.SM);
    const dispatch = useAppDispatch();
    const isFetching = useSelector(getCommentsFetchingSelector);
    const showTranslatedCommentsIsAsked = useSelector(
        showTranslatedCommentsAskedSelector,
    );
    const showTranslatedComments = useSelector(showTranslatedCommentsSelector);
    const userId = useSelector(getUserIdSelector);
    let rootCommentsCnt = 0;
    const [showReply, toggleReply] = useState([]);
    const [showCollapsed, toggleCollapsed] = useState(() =>
        isInspection
            ? false
            : props.showCollapsed
              ? props.showCollapsed
              : false,
    ); // let showCollapsed = this.state.showCollapsed;

    let comments: byId<CommentType[]>;
    if (type !== "inspection") {
        let _type = type;
        if (type === "checkpoint_video") {
            _type = "checkpoint_image";
        }
        comments = useSelector((state: AppState) =>
            getCommentsByTypeSelector(state, _type, typeId, imageUrl),
        );
    } else {
        comments = useSelector(getCommentsSelector);
    }
    if (comments && comments["root"]) {
        rootCommentsCnt = comments["root"].length;
    }
    const loadingComments = isFetching && (
        <div className="loadingComments">
            <Loader inline active size="mini"></Loader>
            <FormattedMessage id="comments.loading" />
        </div>
    );

    useEffect(() => {
        if (inspectionId) {
            dispatch(getComments(inspectionId));
            dispatch(showTranslatedCommentsAsked());
        }
    }, [props.inspectionId]);
    const storeShowTranslateOption = (store: boolean): void => {
        dispatch(storeShowTranslateOptionAction(store));
    };

    const sendCommentNotifications = (inspectionId: string): Promise<void> => {
        return sendNotificationForUnsentComments(dispatch, inspectionId);
    };

    const getCommentsCnt = (): number => {
        if (comments) {
            const obj = comments;
            let cnt = 0;
            Object.keys(obj).forEach((arr) => {
                cnt += obj[arr].length;
            });
            return cnt;
        }
    };
    const writeComment = (
        commentId?: string,
        parentId?: string,
    ): React.ReactElement => {
        const isReply = commentId !== "root";
        return (
            <HasFeatureAccess
                type="inspection"
                feature="create_comments"
                inspection={props.inspection}
            >
                <WriteComment
                    showModal={false}
                    parentId={parentId}
                    commentId={commentId}
                    id={typeId}
                    title={intl.formatMessage({
                        id: "comments.write_a_comment",
                    })}
                    isReply={isReply}
                    type={type}
                    saving={false}
                    clearReplies={(): void => toggleReply([])}
                    imageUrl={imageUrl}
                />
            </HasFeatureAccess>
        );
    };
    const getComment = (
        comment: CommentType,
        isRoot: boolean,
        showTranslatedComments: boolean,
        showTranslatedCommentsAsked: boolean,
        _level: number,
        isLast: boolean,
    ): React.ReactElement => {
        const level = _level + 1;
        const showLabels = true;
        const chl = comment.id !== "meta" && comments[comment.id] && (
            <div>
                {comments[comment.id] &&
                    comments[comment.id].map((childComment) => {
                        return getComment(
                            childComment,
                            false,
                            showTranslatedComments,
                            showTranslatedCommentsAsked,
                            level,
                            false,
                        );
                    })}
            </div>
        );
        return (
            <QComment
                inspection={props.inspection}
                comment={comment}
                key={comment.id}
                isRoot={isRoot}
                level={level}
                showTranslatedComments={showTranslatedComments}
                showLabels={showLabels}
                showReply={showReply.includes(comment.id)}
                writeComment={writeComment}
                toggleReply={(): void => toggleReply([comment.id])}
                storeShowTranslateOption={storeShowTranslateOption}
                userId={userId}
                showTranslatedCommentsAsked={showTranslatedCommentsAsked}
                sendCommentNotifications={sendCommentNotifications}
                isLast={isLast}
            >
                {chl}
            </QComment>
        );
    };

    if (comments) {
        const cnt = getCommentsCnt();
        let commentGrp;
        if (!showCollapsed) {
            if ((cnt > 0 && hideZero) || !hideZero) {
                commentGrp = (
                    <Comment.Group>
                        {comments["root"] &&
                            comments["root"].map((comment, i) => {
                                return getComment(
                                    comment,
                                    comment.parent_id === null,
                                    showTranslatedComments,
                                    showTranslatedCommentsIsAsked,
                                    0,
                                    i === rootCommentsCnt - 1,
                                );
                            })}
                    </Comment.Group>
                );
            }
        }
        return isMobile ? (
            <Fragment>
                {showCollapsed && (
                    <Fragment>
                        <div>
                            <span className="link noUnderline">Comments:</span>{" "}
                            {cnt}
                        </div>
                    </Fragment>
                )}
                {!showCollapsed && commentGrp}
            </Fragment>
        ) : (
            <Fragment>
                {showCollapsed && cnt >= 0 && !hideZero && (
                    <Button
                        onClick={(): void => toggleCollapsed(!showCollapsed)}
                        size="small"
                    >
                        <FormattedMessage id="comments.comments" />({cnt})
                    </Button>
                )}
                {showCollapsed && cnt > 0 && hideZero && (
                    <Button
                        onClick={(): void => toggleCollapsed(!showCollapsed)}
                        size="small"
                    >
                        <FormattedMessage id="comments.comments" />({cnt})
                    </Button>
                )}
                {!showCollapsed && commentGrp}
            </Fragment>
        );
    }
    return isFetching ? loadingComments : null;
};

export default Comments2;
