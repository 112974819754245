import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import Icon from '../../../base/ui/components/icons/Icon';
import AndThenSome from '../../../base/ui/components/util/AndThenSome';
import { getStringsSeparatedBy, twMerge } from '../../../base/utils';
import { OrganizedProductResponsibilityGroup, OrganizedProductResponsibilitySupplier, ResponsibleGroup, ResponsibleSupplier, ResponsibleUser } from '../../../compliance_api/models';
import { useAppDispatch } from '../../../store';
import { getUserNameText, UserNameTextAndEmail } from '../../users/components/Widgets';

type Props = {
    className?: string;
    users?: ResponsibleUser[];
    groups?: ResponsibleGroup[] | OrganizedProductResponsibilityGroup[];
    supplier: ResponsibleSupplier | OrganizedProductResponsibilitySupplier;
    showAll?: boolean;
};

type DisplayType = {
    name: string;
    firstname?: string;
    lastname?: string;
    id?: string;
    email?: string;
    type: 'user' | 'supplier' | 'group';
}
const ComplianceDisplayResponsibles = (props: Props): React.ReactElement => {
    const { className, users, groups, supplier, showAll = false } = props;
    const location = useLocation();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const [state, setState] = useState();

    const responsibles: DisplayType[] = [];
    supplier && responsibles.push({ name: supplier.supplier_name, type: 'supplier' });
    groups && groups.map((g) => responsibles.push({ name: g.name, type: 'group' }));
    users && users.map((u) => responsibles.push({ name: getUserNameText(u), firstname: u.firstname, lastname: u.lastname, email: u.email, type: 'user' }));

    useEffect(() => {
        //
    }, []);
    const trigger = getStringsSeparatedBy(showAll ? (responsibles as []).map((r: DisplayType) => r.name) : [...responsibles as []].splice(0, 3).map((r: DisplayType) => r.name), ',', (responsibles.length > 2 && 'and'));
    const pc = <div className='gap-1 flex flex-col w-80'>
        {[...responsibles].splice(3).map((r) => {
            switch (r.type) {
                case 'user': {
                    return <UserNameTextAndEmail user={r} />
                }
                case 'supplier': {
                    return <div>
                        <div className='flex gap-1'><Icon name='user_supplier' />{r.name}</div>
                        <div className='text-secondary text-sm'><FormattedMessage id='compliance.responsibles.supplier'/></div>
                    </div>
                }
                case 'group': {
                    return <div>
                        <div className='flex gap-1'><Icon name='user_group' />{r.name}</div>
                    </div>
                }
                default: {
                    return <div>{r.name}</div>
                }
            }
        })}
    </div>
    return <div className={twMerge('', className)}>
        {!showAll && <AndThenSome trigger={trigger} cutAt={responsibles.length - 3} popupContent={pc} />}
        {showAll && trigger}
    </div>
}
export default ComplianceDisplayResponsibles;
