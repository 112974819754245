import qs from 'query-string';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom';
import AuditContainer from '../modules/audit/components/AuditContainer';
import AuditReport from '../modules/audit/components/AuditReport';
import AuditEditPage from '../modules/audit/components/edit-audit/AuditEditPage';
import AuditLinkExpiredPage from '../modules/audit/components/web-based-audit/AuditLinkExpiredPage';
import AuditorCompletedPage from '../modules/audit/components/web-based-audit/AuditorCompletedPage';
import AuditorWelcomePage from '../modules/audit/components/web-based-audit/AuditorWelcomePage';
import NoAuditAccessPage from '../modules/audit/components/web-based-audit/NoAuditAccessPage';
import PerformAuditPage from '../modules/audit/components/web-based-audit/PerformAuditPage';
import { TEMPORARY_USER_MODE, setRedirectUrl } from '../modules/authentication/authenticationSlice';
import Login from '../modules/authentication/components/Login';
import Logout from '../modules/authentication/components/Logout';
import { getAuthenticated, isFirstLogin } from '../modules/authentication/selectors';
import { ChecklistsContainer, EditChecklistContainer } from '../modules/checklists/containers';
import ComplianceContainer from '../modules/compliance/components/ComplianceContainer';
import CorrectiveActionsContainer from '../modules/correctiveActions/components/CorrectiveActionsContainer';
import CorrectiveActionsInspectionsList from '../modules/correctiveActions/components/CorrectiveActionsInspectionsList';
import Dashboard from '../modules/dashboard/components/Dashboard';
import FrontendElements from '../modules/developer/components/FrontendElements';
import Groups from '../modules/groups/containers/GroupsContainer';
import WeekView2 from '../modules/inspections/components/dashboard/views/weekView/WeekView2';
import EditInspection from '../modules/inspections/components/edit/EditInspection';
import Report2 from '../modules/inspections/components/report/report2/Report';
import InspectionsDashboard from '../modules/inspections/containers/dashboard/InspectionsDashboard';
import { getListViewDefaultDateSelector } from '../modules/inspections/slices/inspectionsListSlice';
import ProductionUnitsContainer from '../modules/productionUnits/containers/ProductionUnitsContainer';
import { checkHasFeature } from '../modules/profile/containers/HasFeature';
import Profile from '../modules/profile/containers/ProfileContainer';
import { RoleList } from '../modules/roles/components';
import RoleEditor from '../modules/roles/components/RoleEditor';
import DataExportContainer from '../modules/statistics/components/DataExportContainer';
import DataExportFilesDownload from '../modules/statistics/components/DataExportFilesDownload';
import DetailedSupplier from '../modules/suppliers/components/DetailedSupplier';
import SuppliersContainer2 from '../modules/suppliers/components/SuppliersContainer2';
import Bookings from '../modules/suppliers/components/booking/overview/Bookings';
import SupplierBooking from '../modules/suppliers/containers/SupplierBooking';
import SuppliersContainer from '../modules/suppliers/containers/SuppliersContainer';
import { getBookingDefaultDateSelector } from '../modules/suppliers/selectors';
import Users from '../modules/users/containers/UsersContainer';
import TrainingVideosContainer from '../modules/video/containers/TrainingVideosContainer';
import VideoContainer from '../modules/video/containers/VideoContainer';
import WelcomeInspector from '../modules/welcomeInspector/containers/WelcomeInspectorContainer';
import { useAppDispatch } from '../store';
import { setContext } from './baseSlice';
import NoMatch from './components/NoMatch';
import Test from './components/Test';
import { getApplicationReady, getFeaturesSelector } from './selectors';
import { Context } from './types';
import AppInstallPage from '../modules/appInstallPage/components/AppInstallPage';
import GetAndroidAppPage from '../modules/appInstallPage/components/GetAndroidAppPage';
import { addToUrlParams, getLocationEntry, isAndroidOS, isMobileDevice } from './utils';

export const getRedirectPath = (features, date?: string) => {
    let redirectTo = '/';
    const sessionId = sessionStorage.getItem('session_id');
    const temporaryUserMode = sessionStorage.getItem(TEMPORARY_USER_MODE);
    if (!!sessionId && !!temporaryUserMode) {
        redirectTo = '/direct/login/' + sessionId;
    } else if (checkHasFeature(features, 'u_inspections')) {
        redirectTo = 'inspections?status=planned';
        if (date) {
            redirectTo += '&date=' + date;
        }
        if (checkHasFeature(features, 'u_planning_view')) {
            redirectTo = '/inspections/week';
        }
    } else {
        if (checkHasFeature(features, 'u_audits')) {
            redirectTo = '/audits?status=planned';
            if (date) {
                redirectTo += '&date=' + date;
            }
        } else {
            if (checkHasFeature(features, 'u_manage_booking_info')) {
                redirectTo = '/bookings';
            }
        }
    }
    return redirectTo;
};

const Routes = (): React.ReactElement => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const isAuthenticated = useSelector(getAuthenticated);
    const appReady = useSelector(getApplicationReady);
    const features = useSelector(getFeaturesSelector);
    const defaultDate: string = useSelector(getListViewDefaultDateSelector);
    const bookingDefaultDate: string = useSelector(getBookingDefaultDateSelector);
    const firstLogin = useSelector(isFirstLogin);
    const temporaryUserMode = !!sessionStorage.getItem(TEMPORARY_USER_MODE);

    const redirectTo = getRedirectPath(features);
    useEffect(() => {
        let locationName = '';
        if (location.pathname) {
            const tmp = location.pathname.split('/');
            if (tmp.length > 0) {
                locationName = tmp[1];
            }
        }
        dispatch(setContext({ context: locationName as Context }));
    }, [location])
    return <Fragment>
        <Switch>
            {/* Booking is a little special, as it can contain a login token in its url. In that case we don't know if we are authenticated or not, so both cases are treated here and not below */}
            <Route path='/booking/:id' render={(props: RouteComponentProps): React.ReactElement => {
                let token;
                if (qs.parse(location.search).token) {
                    token = qs.parse(location.search).token.toString();
                    return <SupplierBooking token={token} orderId={props.match.params['id']} {...props} />;
                }
                if (!isAuthenticated) {
                    dispatch(setRedirectUrl(getFullLocation(props.location)));
                    return <Redirect to='/login' />;
                }
                return <SupplierBooking orderId={props.match.params['id']} />;
            }} />
            <Route path='/video/:id' render={(props: RouteComponentProps): React.ReactElement => {
                return <VideoContainer videoId={props.match.params['id']} />;
            }} />
            <Route path='/direct/login/:session_id' render={(props: RouteComponentProps) => {
                return <AuditorWelcomePage sessionId={props.match.params['session_id']} />
            }} />
            <Route path='/audits/completed' render={(props: RouteComponentProps) => {
                return <AuditorCompletedPage />
            }} />
            <Route path='/audits/expired' render={(props: RouteComponentProps) => {
                return <AuditLinkExpiredPage />
            }} />

            <Route path='/applink/audits' render={(props: RouteComponentProps) => {
                if(isAndroidOS()) {
                    const sessionId = props.match.params['session_id'];
                    const auditId = props.match.params['audit_id'];
                    const checkpointId = props.match.params['checkpoint_id'];
                    const params = sessionId ? `session_id=${sessionId}&checkpoint_id=${checkpointId}` : `audit_id=${auditId}&checkpoint_id=${checkpointId}`;

                    window.location.href = `android-app://com.qarma.app/http/app.qarmainspect.com/q/app/applink/audits?${params}`;
                }

                setTimeout(() => window.location.href = window.location.origin + "/q/app/get_app", 1500);
            }} />

            <Route path='/get_app' render={(): React.ReactElement => {
                return <AppInstallPage />;
            }} />
            <Route path='/get_android_app' render={(): React.ReactElement => {
                return <GetAndroidAppPage />;
            }} />
            {isAuthenticated && appReady &&
                <Switch>
                    <Route path='/audits/perform_audit/:audit_id' render={(props: RouteComponentProps) => {
                        return <PerformAuditPage auditId={props.match.params['audit_id']} />
                    }} />
                    <Route path='/login' render={(): React.ReactElement => {
                        if (firstLogin && !temporaryUserMode) {
                            return (<Redirect to='/training_videos' push={true} />);
                        }
                        return (<Redirect to='/' push={true} />);
                    }} />
                    <Route exact path='/' render={(): React.ReactElement => {
                        return (<Redirect to={redirectTo} push={true} />);
                    }} />
                    <Route path='/ca' render={(): React.ReactElement => {
                        return <CorrectiveActionsContainer />
                    }} />
                    <Route path='/inspections/ca' render={(): React.ReactElement => {
                        if (checkHasFeature(features, 'u_corrective_actions_inspection')) {
                            return <CorrectiveActionsInspectionsList />;
                        }
                        return <Redirect to={redirectTo} push={true} />;
                    }} />
                    <Route path='/reset_pw' render={(): React.ReactElement => {
                        if (!firstLogin) {
                            return (<Login type='reset' />);
                        } else {
                            return <Redirect to='/training_videos' push={true} />
                        }
                    }} />
                    <Route exact path='/forgot_pw' render={(): React.ReactElement => {
                        return (<Redirect to={redirectTo} push={true} />);
                    }} />
                    <Route path='/inspections/create_inspection' render={(): React.ReactElement => {
                        return <EditInspection orderId='new' />;
                    }} />
                    <Route path='/inspections/edit_inspection/:id' render={(props: RouteComponentProps): React.ReactElement => {
                        return <EditInspection orderId={props.match.params['id']} />;
                    }} />
                    <Route path='/inspections/reports/:id' render={(props: RouteComponentProps): React.ReactElement => {
                        return <Report2 inspectionId={props.match.params['id'].split('?')[0]} />
                    }} />
                    <Route path='/inspections/week' render={(): React.ReactElement => {
                        return <WeekView2 />;
                    }} />
                    <Route path='/inspections' render={(): React.ReactElement => {
                        const filterParams = getDefaultFilterParams(defaultDate, location);
                        if (filterParams) {
                            return <Redirect
                                to={{
                                    pathname: '/inspections',
                                    search: '?' + filterParams,
                                    push: false,
                                }}
                            />
                        }
                        return <InspectionsDashboard />
                    }} />
                    <Route path='/dashboard' render={(): React.ReactElement => {
                        if (checkHasFeature(features, 'u_dashboard')) {
                            return <Dashboard />
                        }
                        else if (features.length == 0) {
                            //Need to wait for features to be loaded before redirecting
                            return null
                        }
                        else {
                            <Redirect to='/inspections' />
                        }
                    }
                    } />
                    <Route path='/data_export' component={DataExportContainer} />
                    <Route path='/statistics_beta'><Redirect to="/data_export" /></Route>
                    <Route path='/statistics'><Redirect to="/data_export" /></Route>
                    <Route path='/inspection/edit_checklist/:inspectionId' render={(props: RouteComponentProps): React.ReactElement => {
                        return <EditChecklistContainer {...props} inspectionId={props.match.params['id']} isTemplate={false} isStandalone={true} />;
                    }} />
                    <Route path='/checklists/edit_checklist/:id' render={(props: RouteComponentProps): React.ReactElement => {
                        return <EditChecklistContainer checklistId={props.match.params['id']} {...props} isTemplate={true} />;
                    }} />
                    <Route path='/checklists' component={ChecklistsContainer} />
                    <Route path='/profile' component={Profile} />
                    <Route path='/users' component={Users} />
                    <Route path='/groups' component={Groups} />
                    <Route path='/suppliers/:supplierId/:productionUnitId' render={(props: RouteComponentProps): React.ReactElement => {
                        // Redirecting supplier context production units 
                        return <Redirect to={'/production_units/' + props.match.params['productionUnitId']} />
                    }} />
                    <Route path='/audit/edit_pu/:supplierId/:productionUnitId/:auditId' render={(props: RouteComponentProps): React.ReactElement => {
                        // Redirecting supplier/audit context production units 
                        return <Redirect to={'/production_units/' + props.match.params['productionUnitId']} />
                    }} />
                    <Route exact path='/suppliers/:id' render={(props: RouteComponentProps): React.ReactElement => {
                        return <DetailedSupplier supplierId={props.match.params['id']} />
                    }} />
                    <Route exact path='/suppliers' render={(props: RouteComponentProps): React.ReactElement => {
                        // The t_supplier_management flag gives access to the latest supplier list. 
                        if (checkHasFeature(features, 't_supplier_management')) {
                            return <SuppliersContainer2 />;
                        }
                        // TODO: Old list access for customers without the t_supplier_management flag. Should be removed once all customers have migrated.
                        return <SuppliersContainer  {...props} action='list' />;
                    }} />

                    {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                    <Route exact path='/production_units' render={(): React.ReactElement => {
                        return <ProductionUnitsContainer />;
                    }} />
                    <Route exact path='/production_units/:id' render={(): React.ReactElement => {
                        return <ProductionUnitsContainer />;
                    }} />

                    <Route path='/bookings' render={(location: RouteComponentProps): React.ReactElement => {
                        if (checkHasFeature(features, 'u_show_booking_info')) {
                            if (bookingDefaultDate && qs.parse(location.location.search).date === undefined) {
                                const newUrl = addToUrlParams([{ key: 'date', value: bookingDefaultDate }], location.location.search);
                                return <Redirect
                                    to={{
                                        pathname: '/bookings',
                                        search: '?' + newUrl,
                                        push: false,
                                    }}
                                />
                            }
                            return <Bookings />
                        }
                        return <Redirect to={redirectTo} push={false} />
                    }} />
                    <Route path='/audits/report/:auditId' render={(props: RouteComponentProps): React.ReactElement => {
                        if (checkHasFeature(features, 'u_audits')) {
                            return <AuditReport auditId={props.match.params['auditId']} />
                        }
                        return <NoMatch location={props} />;
                    }} />

                    <Route path='/training_videos' render={(): React.ReactElement => {
                        return <TrainingVideosContainer />;
                    }} />
                    
                    <Route path='/audits/edit_audit/:auditId' render={(props: RouteComponentProps): React.ReactElement => {
                        return <AuditEditPage auditId={props.match.params['auditId']} />;
                    }} />
                    <Route path='/audits' render={(): React.ReactElement => {
                        if (checkHasFeature(features, 'u_audits')) {
                            return <AuditContainer />
                        }
                        return <Redirect to={redirectTo} push={false} />
                    }} />
                    <Route path='/welcome_inspector' component={WelcomeInspector} />

                    <Route path='/roles' render={(): React.ReactElement => {
                        if (checkHasFeature(features, 'u_manage_user_roles')) {
                            return <RoleList />
                        }
                        else {
                            return <Redirect to={redirectTo} push={true} />
                        }
                    }} />
                    <Route path='/compliance' render={(): React.ReactElement => {
                        if (checkHasFeature(features, 't_product_compliance') && checkHasFeature(features, 'u_enable_compliance')) {
                            return <ComplianceContainer />
                        }
                        return <Redirect to={redirectTo} push={true} />
                    }} />
                    <Route path='/role/edit/:id' render={(props: RouteComponentProps): React.ReactElement => {
                        if (checkHasFeature(features, 'u_manage_user_roles')) {
                            return (<RoleEditor
                                roleId={props.match.params['id']} />);
                        }
                        else {
                            return <Redirect to={redirectTo} push={true} />
                        }
                    }} />

                    <Route path='/export_data_results' component={DataExportFilesDownload} />
                    <Route exact path='/logout' component={Logout} />
                    {!document.location.hostname.includes('app.qarmainspect.com') && <Route path='/test' component={Test} />}
                    {!document.location.hostname.includes('app.qarmainspect.com') && <Route path='/developer/frontend-elements' component={FrontendElements} />}

                    <Route render={(location: RouteComponentProps): React.ReactElement => {
                        return <NoMatch location={location} />;
                    }} />
                </Switch>
            }
            {!isAuthenticated &&
                <Switch>
                    <Route exact path='/' render={(): React.ReactElement => {
                        return (<Login type='login' />)
                    }} />
                    <Route path='/forgot_pw' render={(): React.ReactElement => {
                        return (<Login type='reset' />);
                    }} />
                    <Route path='/reset_pw' render={(): React.ReactElement => {
                        return (<Login type='reset' />);
                    }} />
                    <Route path='/welcome_inspector' component={WelcomeInspector} />
                    <Route path='/login' render={(): React.ReactElement => {
                        return (<Login type='login' />);
                    }} />
                    <Route render={(props: RouteComponentProps): React.ReactElement => {
                        dispatch(setRedirectUrl(getFullLocation(props.location)));
                        return <Redirect to='/login' />;
                    }} />
                </Switch>}

        </Switch>
    </Fragment>;
};

export const getDefaultFilterParams = (defaultDate, location) => {
    if (defaultDate && (getLocationEntry(location, 'date').length === 0 && getLocationEntry(location, 'search').length === 0)) {
        return addToUrlParams([{ key: 'date', value: defaultDate }], location.search);
    }
    else {
        return ''
    }
}

export const getFullLocation = (location: Location): string => {
    let fl = location.pathname;
    if (location.search) {
        fl += location.search;
    }
    if (location.hash) {
        return fl + location.hash;
    }
    return fl;
};

export const isWBARoute = (pathname: string): boolean => {
    return pathname.includes('/direct/login/')
        || pathname.includes('/audits/completed')
        || pathname.includes('/audits/error')
        || pathname.includes('/audits/expired')
}

export default Routes;
