import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../../base/types';
import { Category, ShallowOrder, ShallowRequirement } from '../../../compliance_api/models';

const getComplianceProducts = (state: AppState) => state.app.compliance.products || [];
export const getComplianceProductsSelector = createSelector(
    [getComplianceProducts],
    (products) => products,
);
const getComplianceProductsTodo = (state: AppState) => state.app.compliance.products.filter((p) => (p.primary_deadline !== null)) || [];
export const getComplianceProductsSelectorTodo = createSelector(
    [getComplianceProductsTodo],
    (products) => products,
);

const getComplianceProduct = (state: AppState) => state.app.compliance.product;
export const getComplianceProductSelector = createSelector(
    [getComplianceProduct],
    (product) => product,
);

const isFetching = (state: AppState): boolean => state.app.compliance.isFetching;
export const isFetchingSelector = createSelector(
    [isFetching],
    (fetching) => fetching,
);

const getComplianceRequirements = (state: AppState): ShallowRequirement[] => state.app.compliance.requirements || [];
export const getComplianceRequirementsSelector = createSelector(
    [getComplianceRequirements],
    (requirements) => requirements,
);

const getCompliancePreviewRequirements = (state: AppState) => state.app.compliance.previewRequirements || [];
export const getCompliancePreviewRequirementsSelector = createSelector(
    [getCompliancePreviewRequirements],
    (previewRequirements) => previewRequirements,
);

const getComplianceRequirement = (state: AppState) => state.app.compliance.requirement;
export const getComplianceRequirementSelector = createSelector(
    [getComplianceRequirement],
    (requirement) => requirement,
);

const getCurrentComplianceProduct = (state: AppState) => state.app.compliance.product;
export const getCurrentComplianceProductSelector = createSelector(
    [getCurrentComplianceProduct],
    (product) => product,
);

export const getComplianceCategoriesSelector = createSelector(
    [(state: AppState): Category[] => state.app.compliance.categories],
    (categories) => categories,
);

export const getComplianceOrderListSelector = createSelector(
    [(state: AppState): ShallowOrder[] => {
        return state.app.compliance.orders || [];
    }],
    (orders) => orders,
);

const getComplianceOrder = (state: AppState) => state.app.compliance.order;
export const getComplianceOrderSelector = createSelector(
    [getComplianceOrder],
    (order) => order,
);

export const getComplianceOrderListTodoSelector = createSelector(
    [(state: AppState): ShallowOrder[] => state.app.compliance.orders.filter((order) => order.products.filter((p) => p.primary_deadline != null).length > 0)],
    (orders) => orders,
);

const getComplianceSuppliers = (state: AppState) => state.app.compliance.suppliers;
export const getComplianceSuppliersSelector = createSelector(
    [getComplianceSuppliers],
    (suppliers) => suppliers,
);

export const getComplianceSuggestedProductsSelector = createSelector(
    [(state: AppState) => state.app.compliance.suggestedProducts],
    (suggestedProducts) => suggestedProducts,
);
export const getComplianceDetailedDocumentSelector = createSelector(
    [(state: AppState) => state.app.compliance.documentView],
    (documentView) => documentView,
);
export const getComplianceDetailedDocumentIdSelector = createSelector(
    [(state: AppState) => state.app.compliance.currentDocumentViewId],
    (id) => id,
);
export const getComplianceIgnorationIdsSelector = createSelector(
    [(state: AppState) => state.app.compliance.ignorations],
    (ignorations) => ignorations,
);
export const getComplianceIgnorationByIdSelector = createSelector(
    [(state: AppState, ignorationRuleId: string) => {
        const rules = state.app.compliance.ignorations && state.app.compliance.ignorations.filter((response) => response.ignoration_id === ignorationRuleId);        
        return rules?.[0];
    }],
    (ignorations) => ignorations,
);

export const getComplianceDocumentSuggestedProductsSelector = createSelector(
    [(state: AppState) => state.app.compliance.documentSuggestedProducts],
    (documentSuggestedProducts) => documentSuggestedProducts,
);