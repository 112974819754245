import React from 'react';
import DocumentTitle from 'react-document-title';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { setSiteType } from '../../../base/baseSlice';
import { useAppDispatch } from '../../../store';
import { checkHasFeature } from '../../profile/containers/HasFeature';
import { getProfile } from '../../profile/selectors';
import ComplianceCreateOrder from './ComplianceCreateOrder';
import ComplianceCreateRequirementTemplate from './ComplianceCreateRequirementTemplate';
import ComplianceOrderDetail from './ComplianceOrderDetail';
import ComplianceOrderList from './ComplianceOrderList';
import ComplianceProductDetail from './ComplianceProductDetail';
import ComplianceProductsList from './ComplianceProductsList';
import ComplianceRequirementTemplate from './ComplianceRequirementTemplate';
import ComplianceRequirementTemplateList from './ComplianceRequirementTemplateList';


const CorrectiveActionsContainer = () => {
    const dispatch = useAppDispatch();
    const features = useSelector(getProfile).features;
    const redirTo = '/compliance';
    const userCanCreateProducts = checkHasFeature(features, 'u_compliance_create_products');
    const userCanCreateOrders = checkHasFeature(features, 'u_compliance_create_orders');
    const userCanCreateRequirements = checkHasFeature(features, 'u_compliance_create_requirements');

    const intl = useIntl();
    dispatch(setSiteType('compliance'));

    return <>
        <DocumentTitle title={intl.formatMessage({ id: 'compliance.container.title' })} />
        <Switch>
            <Route exact path='/compliance' render={() => {
                return <Redirect to='/compliance/products' />
            }}>
            </Route>
            <Route exact path='/compliance/requirements' render={(): React.ReactElement => {
                return <ComplianceRequirementTemplateList />
            }} />
            <Route exact path='/compliance/requirements/create' render={(): React.ReactElement => {
                if (userCanCreateRequirements) {
                    return <ComplianceCreateRequirementTemplate />
                }
                return <Redirect to={redirTo} />
            }} />
            <Route exact path='/compliance/requirements/:id' render={(props: RouteComponentProps): React.ReactElement => {
                return <ComplianceRequirementTemplate id={props.match.params['id']} />
            }} />
            <Route exact path='/compliance/products' render={(): React.ReactElement => {
                return <ComplianceProductsList />
            }} />
            <Route exact path='/compliance/products/create' render={(): React.ReactElement => {
                if (userCanCreateProducts) {
                    return <ComplianceProductDetail create />
                }
                return <Redirect to={redirTo} />
            }} />
            <Route exact path='/compliance/products/:id' render={(props: RouteComponentProps): React.ReactElement => {
                return <ComplianceProductDetail id={props.match.params['id']} />
            }} />
            <Route exact path='/compliance/orders' render={(): React.ReactElement => {
                return <ComplianceOrderList />
            }} />
            <Route exact path='/compliance/orders/create' render={(): React.ReactElement => {
                if (userCanCreateOrders) {
                    return <ComplianceCreateOrder />
                }
                return <Redirect to={redirTo} />;
            }} />
            <Route exact path='/compliance/orders/:id' render={(props: RouteComponentProps): React.ReactElement => {
                return <ComplianceOrderDetail id={props.match.params['id']} />
            }} />
        </Switch>
    </>
}

export default CorrectiveActionsContainer;