import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { UpdatedByImporter } from '../../../backend_api_2';

type Props = {
    event: UpdatedByImporter
}

const UpdatedByImporterEvent = (props: Props) => {
    return <Fragment>
        <label className='mb-1'><FormattedMessage id={'corrective_actions.events.updated_by_importer'} /></label>
    </Fragment>
}

export default UpdatedByImporterEvent;