import React from 'react';
import { Sizes } from '../../../components/types';
import { twMerge } from '../../../utils';
import Icon from '../icons/Icon';


type Props = {
    className?: string;
    message: string;
};

const InvalidFieldMessage = (props: Props): React.ReactElement => {
    const { className, message } = props;

    return message && <div className={twMerge('mb-1 text-danger gap-1 text-sm flex items-center', className)}>
        <Icon size={Sizes.Small} className='text-danger' name='callout_info' />
        {message}
    </div>
}
export default InvalidFieldMessage;
