import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popup } from 'semantic-ui-react';
import { twMerge } from 'tailwind-merge';
import { BasicUserOptional } from '../../../backend_api/models';
import Icon from '../../../base/components/basic/Icon';
import Label from '../../../base/components/basic/Label';
import { getStringsSeparatedBy, objectHasKeys } from '../../../base/utils';
import { Space } from '../../globals/components/Utils';

type UserLineProps = {
    user: BasicUserOptional;
    className?: string;
    isExternalUser?: boolean
}
export const UserLine = (props: UserLineProps): React.ReactElement => {
    return (<div className={`${props.className || ''} flex flex-row items-center`}>
        {props.isExternalUser &&
            <Icon name='forward_to_inbox' className='text-text-primary self-start text-lg mr-1' />
        }
        {props.user.firstname || '-'}<Space />{props.user.lastname || '-'}
        </div>);
};

type UserInitialsProps = {
    user?: BasicUserOptional;
    showInfo?: boolean;
    disabled?: boolean;
    appendText?: string | React.ReactElement;
    smallText?: boolean;
    className?: string;
};

export const UserInitials = (props: UserInitialsProps): React.ReactElement => {
    const { user, showInfo = false, disabled = false, appendText, smallText = false, className } = props;
    let initials = '';
    let cn = 'user-initials text-primary bg-surface-plain border-default whitespace-pre py-1 ';
    cn += + smallText ? 'text-xs' : 'text-sm';
    let lastnames = [];
    if (user && objectHasKeys(props.user)) {
        if (user && user.firstname) {
            initials += user.firstname.substring(0, 2);
        }
        if (user && user.lastname) {
            lastnames = user.lastname.split(' ');
        }
        if (lastnames.length > 0) {
            initials += lastnames[lastnames.length - 1].substring(0, 1);
        }
        if (initials === '') {
            initials = '--';
        }
        const ret = <span><Label className={twMerge(cn, disabled && 'disabled', className)}><>{initials.toUpperCase()}{appendText && <Fragment>{appendText}</Fragment>}</></Label></span>;
        if (showInfo) {
            return <Popup
                trigger={ret}
                position='top left'>

                <div className='font-bold'>

                    {[user.firstname || '-', ' ', user.lastname || '-']}
                </div>
                {user.email}
                {user.disabled && <div><FormattedMessage id='users.user_is_disabled' /></div>}
            </Popup>;
        }
        return ret;
    }
    return (<Label className={twMerge(cn, 'px-4', disabled && 'disabled', className)}>-</Label>);
};
type UserInitialsTextProps = {
    user: BasicUserOptional;
    appendText?: string | React.ReactElement;
    className?: string;
    displayIfEmpty?: string;
}

export const UserInitialsText = (props: UserInitialsTextProps): React.ReactElement => {
    const { user, appendText, displayIfEmpty } = props;
    let initials = '';
    let lastnames = [];
    if (user && objectHasKeys(user)) {
        if (user && user.firstname) {
            initials += user.firstname.substring(0, 2);
        }
        if (user && user.lastname) {
            lastnames = user.lastname.split(' ');
        }
        if (lastnames.length > 0) {
            initials += lastnames[lastnames.length - 1].substring(0, 1);
        }
        if (initials === '') {
            initials = '--';
        }
        return <div className={props.className}>{initials.toUpperCase()}{appendText && appendText}</div>;
    }
    return displayIfEmpty ? <div>{displayIfEmpty}</div> : null;
};

type UserNameTextProps = {
    user: { firstname?: string, lastname?: string };
    className?: string;
}
export const UserNameText = (props: UserNameTextProps): React.ReactElement => {
    const { user } = props;
    if (user && objectHasKeys(user)) {
        return <span className={twMerge('flex space-x-1', props.className)}><span>{user.firstname || '-'}</span><span>{user.lastname || '-'}</span></span>
    }
    return null;
};

type UserNameTextAndEmailProps = {
    user: { firstname?: string, lastname?: string, email?: string; };
    className?: string;
}

export const UserNameTextAndEmail = (props: UserNameTextAndEmailProps): React.ReactElement => {
    const { user } = props;
    if (user && objectHasKeys(user)) {
        return <div className={props.className}>
            <div>{getUserNameText(user)}</div>
            <div className='text-secondary text-sm'>{user?.email}</div>
        </div>
    }
    return null;
};
export const getUserNameText = (user: { firstname?: string, lastname?: string }): string => {
    if (user && objectHasKeys(user)) {
        return getStringsSeparatedBy([(user.firstname || '-'), (user.lastname || '-')], ' ');
    }
    return null;
};

export default { UserLine, UserInitials, UserInitialsText, UserNameText };
